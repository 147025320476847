import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useEffect } from 'react';

import { disableScroll } from '../helpers/helper';

interface Props {
  children: JSX.Element | JSX.Element[];
  onClose: () => void;
  noPad?: boolean;
}

export default function GenericModal({ children, onClose, noPad }: Props) {
  const closeHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    disableScroll(true);
    return () => {
      disableScroll(false);
    };
  }, []);

  return (
    <div
      className={
        'fixed top-0 left-0 z-[2000] w-[100vw] h-[100vh] bg-white bg-opacity-80 flex items-center ' +
        'justify-center modal-dissolve'
      }
      onClick={closeHandler}
    >
      <div
        className={`border rounded-lg bg-white relative overflow-hidden m-4${noPad ? '' : ' p-8'}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.04) 0px 6px 10px 0px, rgba(0, 0, 0, 0.03) 0px 1px 18px 0px'
        }}
      >
        {children}
        <span className={'absolute top-2 right-2'}>
          <IconButton size={'medium'} onClick={closeHandler}>
            <Close />
          </IconButton>
        </span>
      </div>
    </div>
  );
}
