import React, { type CSSProperties } from 'react';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  style?: Pick<CSSProperties, 'justifyContent'>;
}

export default function CTabButtonWrapper(props: Props) {
  return (
    <div className={'flex gap-y-2 gap-x-1.5 flex-wrap'} style={{ ...props.style }}>
      {props.children}
    </div>
  );
}
