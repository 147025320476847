import React from 'react';
import BigListButton, { type BigListButtonProps } from './BigListButton';
import { Link } from 'react-router-dom';

interface props extends BigListButtonProps {
  to: string;
  order: number;
}

export default function BigListButtonLink(props: props) {
  return (
    <Link to={props.to} className={'font-normal'}>
      <BigListButton {...props}/>
    </Link>
  );
}