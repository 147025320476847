import React from 'react';

export interface IFloatIn {
  children: React.ReactNode;
  className?: string;
  delayInMs?: number;
  direction?: 'bottom-up' | 'top-down' | 'left-right' | 'dissolve';
}

export default function XDFloatIn(props: IFloatIn) {
  const classNames = ['opacity-0'];

  switch (props.direction) {
    case 'top-down':
      classNames.push('modal-dissolve-top-down');
      break;
    case 'left-right':
      classNames.push('dissolve-left-right');
      break;
    case 'bottom-up':
      classNames.push('modal-dissolve');
      break;
    case 'dissolve':
    default:
      classNames.push('true-dissolve');
      break;
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(' ')} style={{ animationDelay: `${props.delayInMs}ms` }}>
      {props.children}
    </div>
  );
}
