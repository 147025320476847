import React, { useState } from 'react';

import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import I18nButton from '../../components/I18nButton';
import XDButton from '../../components/buttons/XDButton';
import XDCheckbox from '../../components/forms/XDCheckbox';
import { updateState } from '../../helpers/helper';

interface Props {
  onClose: () => void;
}

interface State {
  boxIsChecked: boolean;
}

export default function PFactsheetTerms(props: Props) {
  const [state, setState] = useState<State>({ boxIsChecked: false });

  const handleAgree = () => {
    window.localStorage.setItem('factsheet-terms-accepted', new Date().toISOString());
    props.onClose();
  };

  return (
    <>
      <CPageModule style={{ paddingTop: 0 }}>
        <h2 className={'flex gap-4'}>
          <div className={'flex-grow'}>
            <CLocalizedText dictKey={'globalTermsP1H1'} />
          </div>
          <div className={'font-body'}>
            <I18nButton />
          </div>
        </h2>
        <CLocalizedText dictKey={'globalTermsP1Desc'} />
        <h3>
          <b>
            <CLocalizedText dictKey={'globalTermsP2H1'} />
          </b>
        </h3>
        <CLocalizedText dictKey={'globalTermsP2Desc'} />
        <div className={'flex flex-col gap-2'}>
          <h5>
            <CLocalizedText dictKey={'globalTermsP3H1'} />
          </h5>
          <CLocalizedText dictKey={'globalTermsP3Desc'} />
        </div>
        <h3>
          <CLocalizedText dictKey={'globalTermsP4H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP4Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP5H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP5Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP6H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP6Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP7H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP7Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP8H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP8Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP9H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP9Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP10H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP10Desc'} />
        <h3>
          <CLocalizedText dictKey={'globalTermsP11H1'} />
        </h3>
        <CLocalizedText dictKey={'globalTermsP11Desc'} />
        <XDCheckbox
          checked={state.boxIsChecked}
          onChange={() => {
            updateState<State>({ boxIsChecked: !state.boxIsChecked }, state, setState);
          }}
        >
          <CLocalizedText dictKey={'globalTermsP12Checkbox'} />
        </XDCheckbox>
        <div className={'flex gap-4 justify-end'}>
          <XDButton
            disabled={!state.boxIsChecked}
            className={'flex-grow justify-center py-4 bg-teal hover:bg-teal hover:bg-opacity-90 text-white font-bold'}
            onClick={handleAgree}
          >
            <CLocalizedText dictKey={'globalTermsP13IAgree'} />
          </XDButton>
        </div>
      </CPageModule>
      <CPageModule style={{ paddingTop: 0 }} />
    </>
  );
}
