import { TouchApp } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { type Layout } from 'plotly.js';
import React, { type CSSProperties, useEffect, useRef, useState } from 'react';
import Plot, { type PlotParams } from 'react-plotly.js';

import { updateState } from '../helpers/helper';
import { useAppSelector } from '../hooks/hooks';
import CHorizontalScrollContainer from './CHorizontalScrollContainer';
import CLocalizedText from './CLocalizedText';
import CModal from './CModal';
import CPageModule, { type IPageModule } from './CPageModule';
import './CPageModulePlot.css';
import CPlot from './CPlot';
import CStatefulContainer from './CStatefulContainer';

interface Props
  extends PlotParams,
    Pick<CSSProperties, 'aspectRatio' | 'height'>,
    Pick<IPageModule, 'apiRequestState'> {
  aspectRatio?: number;
  showWhen?: boolean;
}

interface State {
  data: PlotParams['data'];
  showModal?: boolean;
}

export default function CPageModulePlot(props: Props) {
  const classNames: string[] = [];
  const aspectRatio = Number.isNaN(props.aspectRatio) ? 2 : props.aspectRatio ?? 2;

  const [state, setState] = useState<State>({ data: [] });
  const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const { isTouchscreen } = useAppSelector((state) => state.dashboard);

  const measurementBox = useRef<HTMLDivElement>(null);
  const measurementBox2 = useRef<HTMLDivElement>(null);
  const propsLayout: Partial<Layout> = { ...props.layout };

  propsLayout.width = undefined;
  propsLayout.height = undefined;

  if (props.className) classNames.push(props.className);

  const openModal = () => {
    if (isTouchscreen) updateState<State>({ showModal: true }, state, setState);
  };

  const plot = (
    <div
      ref={measurementBox}
      className={'overflow-y-clip bg-gray-50 rounded-lg'}
      style={{
        aspectRatio,
        height: props.height ?? (1100 - 8 * remInPx) / aspectRatio
      }}
      onClick={openModal}
    >
      <Plot
        data={state.data}
        layout={{
          ...propsLayout,
          width: measurementBox.current?.getBoundingClientRect().width ?? 0,
          height: measurementBox.current?.getBoundingClientRect().height ?? 0,
          paper_bgcolor: '#ffffff',
          plot_bgcolor: '#ffffff',
          margin: {
            t: 0,
            b: 0,
            l: 0,
            r: Math.max(0, 2000 - (measurementBox.current?.getBoundingClientRect().width ?? 0)) / 15
          }
        }}
        config={{
          displaylogo: false,
          displayModeBar: true,
          modeBarButtonsToRemove: ['toImage', 'resetScale2d', 'lasso2d'],
          staticPlot: isTouchscreen
        }}
      />
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      // We set timeout to allow the plot framework to render, before parsing data. This is a hack to support fade-ins
      updateState<State>({ data: props.data }, state, setState);
    });
  }, [props.data, state.showModal]);

  return (
    <CStatefulContainer
      apiRequestState={props.apiRequestState}
      showContent={props.showWhen}
      errorMessage={
        <CPageModule style={{ paddingTop: 0 }}>
          <Alert severity={'error'}>{props.apiRequestState?.strError}</Alert>{' '}
        </CPageModule>
      }
    >
      <div className={classNames.join(' ')} style={{ position: 'relative' }}>
        <CHorizontalScrollContainer>{plot}</CHorizontalScrollContainer>
        {isTouchscreen && (
          <CPageModule style={{ paddingBottom: 0, paddingTop: 0 }}>
            <div
              className={'text-sm text-center opacity-50 mt-4 flex gap-3 items-center justify-center'}
              onClick={openModal}
            >
              <TouchApp />
              <span>
                <CLocalizedText dictKey={'messageTapToOpenInteractivePlot'} />
              </span>
            </div>
          </CPageModule>
        )}
      </div>
      {isTouchscreen && (
        <CModal
          onClose={() => {
            updateState<State>({ showModal: false }, state, setState);
          }}
          style={{ paddingLeft: 0, paddingRight: 0 }}
          open={!!state.showModal}
        >
          <CStatefulContainer
            apiRequestState={props.apiRequestState}
            errorMessage={
              <CPageModule style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Alert severity={'error'}>{props.apiRequestState?.strError}</Alert>{' '}
              </CPageModule>
            }
          >
            <CHorizontalScrollContainer sidePadding={<div className={'p-2 sm:p-4'} />} extendTouchscreenScrollArea>
              <div
                ref={measurementBox2}
                className={'overflow-y-clip bg-gray-50 rounded-lg'}
                style={{
                  aspectRatio,
                  height: props.height ?? (1100 - 8 * remInPx) / aspectRatio
                }}
              >
                <CPlot
                  data={state.data}
                  layout={{
                    ...propsLayout,
                    width: measurementBox2.current?.getBoundingClientRect().width ?? 0,
                    height: measurementBox2.current?.getBoundingClientRect().height ?? 0,
                    paper_bgcolor: '#ffffff',
                    plot_bgcolor: '#ffffff',
                    margin: {
                      t: 0,
                      b: 0,
                      l: 0,
                      r: Math.max(0, 2000 - (measurementBox2.current?.getBoundingClientRect().width ?? 0)) / 15
                    }
                  }}
                  config={{
                    displaylogo: false,
                    displayModeBar: true,
                    modeBarButtonsToRemove: ['toImage', 'resetScale2d', 'lasso2d']
                  }}
                />
              </div>
            </CHorizontalScrollContainer>
          </CStatefulContainer>
        </CModal>
      )}
    </CStatefulContainer>
  );
}
