import React from 'react';

interface Props {
  label: string,
  primaryValue: string | number | boolean,
  secValue?: string | number | boolean,
  secLabel?: string,
}

export default function GenericStatisticTableTile({label, primaryValue, secValue, secLabel}: Props) {
  return (
    <>
      {
        primaryValue.toString() !== 'NaN' &&
          <div className={'border rounded-lg px-4 py-2 flex items-center gap-x-4 gap-y-2 flex-wrap'}>
              <p className={'text-sm opacity-80 flex-grow uppercase font-bold mb-0'}>{label.replaceAll('_', ' ')}</p>
              <div className={'flex-grow'}>
                  <h6 className={'text-2xl text-teal-1 my-0 text-right break-all'}>{primaryValue === '' ? '-' : primaryValue}</h6>
                {
                  secValue !== undefined && secValue !== primaryValue &&
                    <>
                        <p className={'text-right'}>
                            <span>{secValue}</span>
                          {
                            secLabel &&
                              <span> ({secLabel})</span>
                          }
                        </p>
                    </>
                }
              </div>
          </div>
      }
    </>
  );
}