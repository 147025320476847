import BaseApiService from './baseApiService';
import type { GhostPostEntry, GhostPostList, GhostTags } from '../models/GhostPostModel';

export default class NewsService extends BaseApiService {
  static async getNewsURI(): Promise<{uri: string}> {
    return (await super.get<{uri: string}>('news/uri')).getData();
  }

  static async getAllPosts(page: string, tag?: string): Promise<GhostPostList> {
    const params: { page: string; tag?: string } = { page };
    if (tag) {
      params.tag = tag;
    }
    return (await super.get<GhostPostList>('news/posts', params)).getData();
  }

  static async getPost(slug: string): Promise<GhostPostEntry> {
    return (await super.get<GhostPostEntry>(`news/posts/${slug}`)).getData();
  }

  static async getAllTags(): Promise<GhostTags> {
    return (await super.get<GhostTags>('news/tags')).getData();
  }
}
