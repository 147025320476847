import { ArrowTopRightOnSquareIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import XDResponsiveGridWrapper from '../../components/layout/XDResponsiveGridWrapper';
import { PageRoutes } from '../../enums/enums';
import { updateState } from '../../helpers/helper';
import type { IRequestState } from '../../models/IRequestState';
import NewsService from '../../services/newsService';

interface State {
  newsURI?: string;
  newsURIState: IRequestState;
}

export default function PublishingIndex() {
  const [state, setState] = useState<State>({
    newsURIState: { isLoading: true, isError: false }
  });

  useEffect(() => {
    (async () => {
      try {
        updateState<State>({ newsURIState: { isLoading: true, isError: false } }, state, setState);
        const uri = await NewsService.getNewsURI();
        updateState<State>({ newsURI: uri.uri, newsURIState: { isLoading: false, isError: false } }, state, setState);
      } catch (e) {
        updateState<State>({ newsURIState: { isLoading: false, isError: true } }, state, setState);
      }
    })();
  }, []);

  return (
    <Page>
      <PageModule>
        <PageModuleTitle>Publications</PageModuleTitle>
        {state.newsURIState.isError && <Alert severity={'error'}>Failed to connect to news service</Alert>}
        <XDResponsiveGridWrapper>
          <ActionLinkButton to={PageRoutes.PUBLISHING_FS_OUTLET} label={'Factsheet Links'}>
            <LinkIcon />
          </ActionLinkButton>
          <>
            {state.newsURI && (
              <ActionLinkButton label={'Blogging Console'} href={`${state.newsURI}/ghost`} target={'_blank'}>
                <ArrowTopRightOnSquareIcon />
              </ActionLinkButton>
            )}
          </>
        </XDResponsiveGridWrapper>
      </PageModule>
    </Page>
  );
}
