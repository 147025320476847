import React from 'react';
import {useAppSelector} from '../hooks/hooks';

interface Props {
  children: JSX.Element[];
}

export default function GenericStatisticTableWrapper({children}: Props) {
  const {sidebar} = useAppSelector((state) => state.dashboard);

  return (
    <div className={`grid gap-2 grid-cols-1 ${sidebar.isExpanded ? 'lg:grid-cols-2 xl:grid-cols-2' : 'md:grid-cols-2 lg:grid-cols-2'}`}>
      {children}
    </div>
  );
}