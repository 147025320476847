import React from 'react';

interface pageModuleFormProps {
  children: React.ReactNode;
  desc?: string | React.ReactNode;
  title?: string;
  wider?: boolean;
}

export default function PageModuleForm({children, title, desc, wider}: pageModuleFormProps) {
  const classNames = ['flex flex-col grow gap-2 w-full'];

  if (!wider) {
    classNames.push('flex-shrink-0 sm:max-w-[428px]');
  } else {
    classNames.push('sm:max-w-[758px]');
  }

  return (
    <div className={'flex flex-col gap-4 w-full'}>
      {
        title &&
        <h3>{title}</h3>
      }
      <div className={'flex flex-col-reverse sm:flex-row gap-4'}>
        <div className={classNames.join(' ')}>
          {children}
        </div>
        {
          desc &&
          <div className={'text-sm'}>
            {
              typeof children === 'string' &&
              <p>{desc}</p>
            }
            {
              typeof children === 'object' && desc
            }
          </div>
        }
      </div>
    </div>
  );
}