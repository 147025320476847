import React from 'react';

import CPlot, { type CPlotProps } from './CPlot';

interface Props extends CPlotProps {
  title?: string | JSX.Element;
}

export default function CHalfWidthSunburstPlot(props: Props) {
  return (
    <div className={'flex flex-col gap-4'}>
      {props.title && <h4 className={'text-center'}>{props.title}</h4>}
      <CPlot
        data={props.data}
        layout={{
          ...props.layout,
          width: Math.min(470, 0.9 * window.innerWidth),
          height: Math.min(470, 0.9 * window.innerWidth),
          title: ''
        }}
      />
    </div>
  );
}
