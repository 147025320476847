import axios, { type AxiosRequestConfig } from 'axios';
import { ApiResponse } from './baseApiService';

export default class ApiService {
  protected static async get<T>(djangoApp: string, suffix: string = '', params?: Record<string, any>, config?: AxiosRequestConfig) {
    return new ApiResponse<T>(await axios.get(`/api/${djangoApp}/${suffix}`, {
      ...config, params
    }));
  }

  protected static async put<T>(djangoApp: string, suffix: string = '', data?: Record<string, any>, config?: AxiosRequestConfig) {
    return new ApiResponse<T>(await axios.put(`/api/${djangoApp}/${suffix}`, data, config));
  }

  protected static async post<T>(djangoApp: string, suffix: string = '', data?: Record<string, any>, config?: AxiosRequestConfig) {
    return new ApiResponse<T>(await axios.post(`/api/${djangoApp}/${suffix}`, data, config));
  }

  protected static async delete<T>(djangoApp: string, suffix: string = '', config?: AxiosRequestConfig) {
    return new ApiResponse<T>(await axios.delete(`/api/${djangoApp}/${suffix}`, config));
  }
}