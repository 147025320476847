import { type PaginatedBackendResponse } from '../models/GhostPostModel';
import { type ISortingMeta } from '../models/ISortingMeta';
import { type ITemperatureScore } from '../models/ITemperatureScore';
import { type PlotlyResponse } from '../models/PlotlyModel';
import { type TLocales } from '../models/TLocales';
import { type TPandasTable } from '../models/TPandasTable';
import { type IFactsheetFactorExposurePlots } from '../models/factsheet/IFactsheetFactorExposurePlots';
import { type TEsgPillars } from '../models/factsheet/TEsgPillars';
import {
  type FactsheetAdminDetail,
  type FactsheetAdminSummary,
  type FactsheetLinkCreationForm,
  type FactsheetResponse
} from '../models/publicFactsheetModel';
import ApiService from './apiService';

export default class PublicFactsheetServices extends ApiService {
  /**
   * @deprecated
   * @param factsheetId
   */
  static async getPublicFactsheet(factsheetId: string) {
    return await super.get<FactsheetResponse>('fs', `public/${factsheetId}`);
  }

  static async getFactsheetCounter(id: string, userType: 'public' | 'user') {
    return await super.get('fs', `${userType}/counter/${id}`);
  }

  static async getFactsheetChart(id: string, locale?: TLocales) {
    return await super.get<PlotlyResponse>('fs', `public/overview/chart/${id}/${locale}`);
  }

  static async getFactsheetOverviewTable(id: string, locale?: TLocales) {
    return await super.get<TPandasTable>('fs', `public/overview/table/${id}/${locale}`);
  }

  static async getFactorExposurePlots(factsheetId: string, locale?: TLocales) {
    return await super.get<IFactsheetFactorExposurePlots>('fs', `public/factorExposure/charts/${factsheetId}`, {
      locale
    });
  }

  static async getFactorExposureCfer(
    id: string,
    type: 'universe' | 'swap',
    page: number,
    sorting: ISortingMeta,
    searchText?: string,
    locale?: TLocales
  ) {
    return await super.get<PaginatedBackendResponse<TPandasTable>>('fs', `public/factorExposure/cfer/${id}`, {
      type,
      page,
      ...sorting,
      searchText,
      locale
    });
  }

  static async getFactorExposureTable(id: string, locale?: TLocales) {
    return await super.get<{ universe: TPandasTable; swap: TPandasTable }>('fs', `public/factorExposure/table/${id}`, {
      locale
    });
  }

  static async getSectorExposureUniverseSwapTables(id: string, sorting?: ISortingMeta, locale?: TLocales) {
    return await super.get<Record<'universe' | 'swap', TPandasTable>>('fs', `public/sectorExposure/table/${id}`, {
      locale,
      ...sorting
    });
  }

  static async getSectorExposureCharts(id: string, locale?: TLocales) {
    return await super.get<
      Record<
        'industryMcap' | 'exchangeIndustryMcap' | 'industryAllocationWeight' | 'exchangeIndustryAllocationWeight',
        Record<'universe' | 'swap', PlotlyResponse>
      >
    >('fs', `public/sectorExposure/charts/${id}`, { locale });
  }

  static async getSectorAssignmentTable(
    id: string,
    table: 'universe' | 'swap',
    page: number,
    sorting?: ISortingMeta,
    sector?: string,
    locale?: TLocales,
    search?: string,
    filters?: string[]
  ) {
    return await super.get<PaginatedBackendResponse<TPandasTable>>('fs', `public/sectorExposure/assignment/${id}`, {
      table,
      page,
      sector,
      ...sorting,
      locale,
      search,
      filters: filters?.join(',')
    });
  }

  static async getEsgPerformance(id: string, locale?: TLocales) {
    return await super.get<TEsgPillars>('fs', `public/esg/esgPerformance/${id}`, { locale });
  }

  static async getEsgRisk(id: string, locale?: TLocales) {
    return await super.get<TEsgPillars>('fs', `public/esg/esgRisk/${id}`, { locale });
  }

  static async getExclusionTable(
    id: string,
    page: number,
    sorting: ISortingMeta,
    search?: string,
    filters?: string[],
    locale?: TLocales
  ) {
    return await super.get<PaginatedBackendResponse<TPandasTable>>('fs', `public/esg/exclusionTable/${id}`, {
      page,
      search,
      ...sorting,
      locale,
      filters: filters?.join(',')
    });
  }

  static async getTemperatureScore(id: string, locale?: TLocales) {
    return await super.get<ITemperatureScore>('fs', `public/esg/temperatureScore/${id}`, { locale });
  }

  static async getDeltaDistribution(id: string, locale?: TLocales) {
    return await super.get<PlotlyResponse>('fs', `public/delta/distribution/${id}`, { locale });
  }

  static async getDeltaChangeMatrix(id: string, locale?: TLocales) {
    return await super.get<PlotlyResponse>('fs', `public/delta/changeMatrix/${id}`, { locale });
  }

  static async getDeltaPlots(id: string, locale?: TLocales) {
    return await super.get<{ industries: PlotlyResponse; factors: PlotlyResponse }>('fs', `public/delta/plots/${id}`, {
      locale
    });
  }

  static async getDeltaLeverage(id: string, locale?: TLocales) {
    return await super.get<PlotlyResponse>('fs', `public/delta/leverage/${id}`, { locale });
  }

  static async getPerformancePlot(id: string, startDate?: string, endDate?: string, locale?: TLocales) {
    return await super.get<PlotlyResponse>('fs', `public/performance/plot/${id}`, { startDate, endDate, locale });
  }

  static async getPerformanceTable(id: string, startDate?: string, endDate?: string, locale?: TLocales) {
    return await super.get<TPandasTable>('fs', `public/performance/table/${id}`, { startDate, endDate, locale });
  }

  // TODO: Move methods below to "fact sheet link services"

  static async getFactsheetAdminList() {
    return await super.get<{ factsheets: FactsheetAdminSummary[] }>('fs', 'admin/list');
  }

  static async getFactsheetAdminDetail(factsheetId: string) {
    return await super.get<FactsheetAdminDetail>('fs', `admin/id/${factsheetId}`);
  }

  static async getLastViewedFactsheet() {
    return await super.get<{ id: string | undefined }>('fs', 'last');
  }

  static async postFactsheetLink(form: Partial<FactsheetLinkCreationForm>) {
    return await super.post<{ id: string }>('fs', 'admin', form);
  }

  static async putFSLink(linkId: string, form: Partial<FactsheetAdminDetail>) {
    return await super.put<FactsheetAdminDetail>('fs', `admin/id/${linkId}`, form);
  }

  static async deleteFSLink(linkId: string) {
    return await super.delete<boolean>('fs', `admin/id/${linkId}`);
  }
}
