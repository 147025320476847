import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CSortDropdown from '../../components/CSortDropdown';
import CStatefulContainer from '../../components/CStatefulContainer';
import CTab from '../../components/CTab';
import CTabItem from '../../components/CTabItem';
import GenericDateRangeSelector from '../../components/GenericDateRangeSelector';
import Page from '../../components/layout/Page';
import { updateStateAttribute, updateStateResponseStatefulAttribute, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IResponseStateful } from '../../models/IResponseStateful';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import { type TPandasTable } from '../../models/TPandasTable';
import { type MSingleStockSummarizedInfo } from '../../models/singleStock/details/MSingleStockSummarizedInfo';
import { type MSingleStockMcapShrout } from '../../models/singleStock/mcapShrout/MSingleStockMcapShrout';
import SvSingleStockServices from '../../services/SvSingleStockServices';
import BasicInfoModule from './singleStockReport/BasicInfoModule';
import PmSingleStockEvents from './singleStockReport/PmSingleStockEvents';

interface State {
  info: IResponseStateful<MSingleStockSummarizedInfo>;
  mcap: IResponseStateful<MSingleStockMcapShrout>;
  performanceDaily: IResponseStateful<{ table: TPandasTable; chart: PlotlyResponse }> & {
    initStartDate: number;
    initEndDate: number;
    minDate: number;
    maxDate: number;
  };
  performanceMonthly: IResponseStateful<TPandasTable> & { initYear: string };
  performanceYearly: IResponseStateful<{ table: TPandasTable; chart: PlotlyResponse }> & {
    yearIdDict?: Record<string, string>;
    initYearId?: string;
    filteredTable?: TPandasTable;
  };
}

export default function PSingleStockEntry() {
  const { identifier } = useParams();
  const { locale } = useAppSelector((state) => state.dashboard);

  const [state, setState, getState] = useStateExtended<State>({
    info: { state: { isLoading: true, isError: false } },
    mcap: { state: { isLoading: true, isError: false } },
    performanceDaily: {
      state: { isLoading: true, isError: false },
      initStartDate: new Date('2000-01-01').getTime(),
      initEndDate: new Date().getTime(),
      minDate: new Date('2000-01-01').getTime(),
      maxDate: new Date().getTime()
    },
    performanceMonthly: {
      state: { isLoading: true, isError: false },
      initYear: new Date().getFullYear().toString()
    },
    performanceYearly: {
      state: { isLoading: true, isError: false }
    }
  });

  const monthlyPerformanceYearOptions: Record<string, string> = {}; // TODO: change into a dynamic option setting

  Array(new Date().getFullYear() - 2000 + 1)
    .fill(null)
    .map((val, ix) => {
      monthlyPerformanceYearOptions[(new Date().getFullYear() - ix).toString()] = (
        new Date().getFullYear() - ix
      ).toString();
      return 0;
    });

  const doFilterPerformanceYearly = async (yearId: string) => {
    const filteredTable: TPandasTable = {};
    const table = (await getState()).performanceYearly.data?.table;
    filteredTable[Object.keys(table ?? {})[0]] = Object.values(table ?? {})[0];
    const indexOfYearId = Object.keys(table ?? {}).indexOf(yearId);
    filteredTable[Object.keys(table ?? {})[indexOfYearId]] = Object.values(table ?? {})[indexOfYearId];
    filteredTable[Object.keys(table ?? {})[indexOfYearId + 1]] = Object.values(table ?? {})[indexOfYearId + 1];
    updateStateAttribute<State>(
      { performanceYearly: { ...(await getState()).performanceYearly, filteredTable, initYearId: yearId } },
      setState
    );
  };

  useEffect(() => {
    (async () => {
      await updateStateResponseStatefulAttribute<typeof state.info.data, State>(
        { info: state.info },
        setState,
        async () => await SvSingleStockServices.getSingleStockReportInfo(identifier)
      );
      await updateStateResponseStatefulAttribute<typeof state.mcap.data, State>(
        { mcap: state.mcap },
        setState,
        async () => await SvSingleStockServices.getSingleStockReportMarketCap(identifier)
      );
      await updateStateResponseStatefulAttribute<typeof state.performanceDaily.data, State>(
        { performanceDaily: state.performanceDaily },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportPerformanceDaily({
            companyId: identifier ?? '',
            locale: locale ?? 'de-DE'
          })
      );
      await updateStateResponseStatefulAttribute<typeof state.performanceMonthly.data, State>(
        { performanceMonthly: state.performanceMonthly },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportPerformanceMonthly({
            companyId: identifier ?? '',
            locale: locale ?? 'de-DE'
          })
      );
      await updateStateResponseStatefulAttribute<typeof state.performanceMonthly.data, State>(
        { performanceYearly: state.performanceYearly },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportPerformanceYearly({
            companyId: identifier ?? '',
            locale: locale ?? 'de-DE'
          })
      );
      const initializePerformanceYearly = async () => {
        const yearIdList = Object.keys((await getState()).performanceYearly.data?.table ?? {}).filter(
          (e, ix) => ix % 2 === 1
        );
        const yearIdDict: Record<string, string> = {};
        yearIdList.map((e) => {
          yearIdDict[e] = e.slice(0, 4);
          return undefined;
        });
        const defaultYear = yearIdList[yearIdList.length - 1];
        updateStateAttribute<State>(
          {
            performanceYearly: {
              ...(await getState()).performanceYearly,
              yearIdDict,
              initYearId: defaultYear
            }
          },
          setState
        );
        await doFilterPerformanceYearly(defaultYear);
      };
      await initializePerformanceYearly();
    })();
  }, []);

  return (
    <Page title={state.info.data?.basicinformation.companyname ?? ''}>
      <CPageModule apiRequestState={state.info.state} showContent={!!state.info.data}>
        <div className={'flex flex-col'}>
          <p className={'font-bold'}>{state.info.data?.companyidentifier.tickersymbol}</p>
          <h1 className={'text-teal'}>{state.info.data?.basicinformation.companyname}</h1>
        </div>
        <p>{state.info.data?.businessdescription.busdesc}</p>
      </CPageModule>
      {!!state.info.data && (
        <>
          <CPageModule showContent={!!state.info.data}>
            <BasicInfoModule summarizedInfo={state.info.data} />
          </CPageModule>
          <CPageModule>
            <h3>
              <CLocalizedText dictKey={'singleStockP3H1'} />
            </h3>
            <p>
              <CLocalizedText dictKey={'singleStockP3Desc'} />
            </p>
            <CStatefulContainer apiRequestState={state.info.state} showContent={!!state.info.data}>
              <PmSingleStockEvents summarizedInfo={state.info.data} />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP4H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP4Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer submodule apiRequestState={state.mcap.state} showContent={!!state.mcap.data}>
              <CPageModulePlot data={state.mcap.data?.mcapshrout.data} layout={state.mcap.data?.mcapshrout.layout} />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <CLocalizedText dictKey={'singleStockP5H1'} />
              <p>
                <CLocalizedText dictKey={'singleStockP5Desc'} />
              </p>
            </CPageModule>
            <CTab submodule>
              <CTabItem label={'daily'} text={<CLocalizedText dictKey={'globalDaily'} />}>
                <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <CPageModule submodule>
                    <p>
                      <CLocalizedText dictKey={'singleStockP5T1Desc'} />
                    </p>
                  </CPageModule>
                  <CStatefulContainer
                    submodule
                    apiRequestState={state.performanceDaily.state}
                    showContent={!!state.performanceDaily.data}
                  >
                    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <CPageModule submodule>
                        <div className={'flex justify-end'}>
                          <GenericDateRangeSelector
                            onChange={async (startDate, endDate) => {
                              const startDateString = dayjs(startDate).format('YYYY-MM-DD');
                              const endDateString = dayjs(endDate).format('YYYY-MM-DD');
                              await updateStateResponseStatefulAttribute<typeof state.performanceDaily.data, State>(
                                { performanceDaily: (await getState()).performanceDaily },
                                setState,
                                async () =>
                                  await SvSingleStockServices.getSingleStockReportPerformanceDaily({
                                    companyId: identifier ?? '',
                                    startDate: startDateString,
                                    endDate: endDateString,
                                    locale: locale ?? 'de-DE'
                                  })
                              );
                              updateStateAttribute<State>(
                                {
                                  performanceDaily: {
                                    ...(await getState()).performanceDaily,
                                    initStartDate: startDate,
                                    initEndDate: endDate
                                  }
                                },
                                setState
                              );
                            }}
                            initStartDate={state.performanceDaily.initStartDate}
                            initEndDate={state.performanceDaily.initEndDate}
                            minDate={state.performanceDaily.minDate}
                            maxDate={state.performanceDaily.maxDate}
                            minDateSpan={10}
                          />
                        </div>
                      </CPageModule>
                      <CHorizontalScrollContainer>
                        <CPandasTable data={state.performanceDaily.data?.table} />
                      </CHorizontalScrollContainer>
                      <CPageModulePlot
                        data={state.performanceDaily.data?.chart.data}
                        layout={state.performanceDaily.data?.chart.layout}
                      />
                    </CPageModule>
                  </CStatefulContainer>
                </CPageModule>
              </CTabItem>
              <CTabItem label={'monthly'} text={<CLocalizedText dictKey={'globalMonthly'} />}>
                <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <CPageModule submodule>
                    <p>
                      <CLocalizedText dictKey={'singleStockP5T2Desc'} />
                    </p>
                  </CPageModule>
                  <CStatefulContainer
                    submodule
                    apiRequestState={state.performanceMonthly.state}
                    showContent={!!state.performanceMonthly.data}
                  >
                    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <CPageModule submodule>
                        <div className={'flex justify-end'}>
                          <CSortDropdown
                            key={'monthly'}
                            reverseDictOrder
                            labelDesc={(<CLocalizedText dictKey={'globalYearColon'} />) as unknown as string}
                            onChange={async (year) => {
                              await updateStateResponseStatefulAttribute<typeof state.performanceMonthly.data, State>(
                                { performanceMonthly: state.performanceMonthly },
                                setState,
                                async () =>
                                  await SvSingleStockServices.getSingleStockReportPerformanceMonthly({
                                    companyId: identifier ?? '',
                                    locale: locale ?? 'de-DE',
                                    year
                                  })
                              );
                              updateStateAttribute<State>(
                                {
                                  performanceMonthly: {
                                    ...(await getState()).performanceMonthly,
                                    initYear: year
                                  }
                                },
                                setState
                              );
                            }}
                            options={monthlyPerformanceYearOptions}
                            defaultKey={state.performanceMonthly.initYear}
                          />
                        </div>
                      </CPageModule>
                      <CHorizontalScrollContainer>
                        <CPandasTable data={state.performanceMonthly.data} />
                      </CHorizontalScrollContainer>
                    </CPageModule>
                  </CStatefulContainer>
                </CPageModule>
              </CTabItem>
              <CTabItem label={'yearly'} text={<CLocalizedText dictKey={'globalYearly'} />}>
                <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <CPageModule submodule>
                    <p>
                      <CLocalizedText dictKey={'singleStockP5T3Desc'} />
                    </p>
                  </CPageModule>
                  <CStatefulContainer
                    submodule
                    apiRequestState={state.performanceYearly.state}
                    showContent={!!state.performanceYearly.data}
                  >
                    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <CPageModule submodule>
                        <div className={'flex justify-end'}>
                          <CSortDropdown
                            key={'yearly'}
                            labelDesc={(<CLocalizedText dictKey={'globalYearColon'} />) as unknown as string}
                            onChange={async (yearId) => {
                              await doFilterPerformanceYearly(yearId);
                            }}
                            reverseDictOrder
                            options={state.performanceYearly.yearIdDict ?? {}}
                            defaultKey={state.performanceYearly.initYearId}
                          />
                        </div>
                      </CPageModule>
                      <CHorizontalScrollContainer>
                        <CPandasTable data={state.performanceYearly.filteredTable} />
                      </CHorizontalScrollContainer>
                      <CPageModulePlot
                        data={state.performanceYearly.data?.chart.data}
                        layout={state.performanceYearly.data?.chart.layout}
                      />
                    </CPageModule>
                  </CStatefulContainer>
                </CPageModule>
              </CTabItem>
            </CTab>
          </CPageModule>
        </>
      )}
    </Page>
  );
}
