import { ArrowDropDown, Check, Close, Factory, Merge, Paid, PieChart, Search, Storefront } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import CStatefulContainer from '../../components/CStatefulContainer';
import CompanyInfoDetail from '../../components/CompanyInfoDetail';
import CustomTextField from '../../components/CustomTextField';
import XDEmptyResult from '../../components/alerts/XDEmptyResult';
import XDButton from '../../components/buttons/XDButton';
import XDEntryCard from '../../components/buttons/XDEntryCard';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import XDPaginator from '../../components/layout/XDPaginator';
import XDResponsiveGridWrapper from '../../components/layout/XDResponsiveGridWrapper';
import XDDropdown from '../../components/selection/XDDropdown';
import XDDropdownItem from '../../components/selection/XDDropdownItem';
import { type SortingLogic } from '../../enums/sortingLogic';
import { updateState } from '../../helpers/helper';
import { type PaginationMeta } from '../../models/GhostPostModel';
import { type IRequestState } from '../../models/IRequestState';
import { type MSingleStockCardSummary } from '../../models/singleStock/MSingleStockCardSummary';
import SvSingleStockServices from '../../services/SvSingleStockServices';

interface State {
  requestState: IRequestState;
  requestQuery?: Partial<{
    page: number;
    search: string;
    sortBy: SortingLogic;
  }>;
  pagination?: PaginationMeta;
  singleStockList?: MSingleStockCardSummary[];
}

/**
 * Index page for single stock section.
 * @constructor
 */
export default function SingleStockIndex() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<State>({ requestState: { isError: false, isLoading: false } });

  const sortByLabels: Record<SortingLogic, string> = {
    companyNameAsc: 'Company name: A to Z',
    companyNameDesc: 'Company name: Z to A',
    marketCapAsc: 'Market capitalization: low to high',
    marketCapDesc: 'Market capitalization: high to low'
  };

  const handleFetchList = async (page: number, search?: string, sortBy?: SortingLogic) => {
    updateState<State>({ requestState: { ...state.requestState, isLoading: true } }, state, setState);
    const res = await SvSingleStockServices.listSingleStocks(page, search, sortBy);
    updateState<State>(
      {
        requestState: {
          ...state.requestState,
          isLoading: false,
          isError: res.hasError(),
          strError: res.getErrorString()
        }
      },
      state,
      setState
    );

    if (!res.hasError()) {
      updateState<State>(
        {
          singleStockList: res.getData().results,
          pagination: res.getData().meta.pagination,
          requestQuery: { page, search, sortBy }
        },
        state,
        setState
      );
    }
  };

  const handlePageChange = async (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  const handleSort = async (sortBy: SortingLogic) => {
    searchParams.set('sortBy', sortBy);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const handleSubmitSearch = async () => {
    searchParams.set('search', state.requestQuery?.search ?? '');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const handleSearchBoxChange = async (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateState<State>(
      { requestQuery: { ...state.requestQuery, search: ev.target.value ?? undefined } },
      state,
      setState
    );
  };

  useEffect(() => {
    (async () => {
      const page = parseInt(searchParams.get('page') ?? '1');
      const search = searchParams.get('search') ?? '';
      const sortBy = (searchParams.get('sortBy') as SortingLogic) ?? 'marketCapDesc';
      await handleFetchList(page, search, sortBy);
    })();
  }, [searchParams]);

  return (
    <Page title={'Single Stock'}>
      <PageModule>
        <PageModuleTitle>Single Stock</PageModuleTitle>
        <div className={'flex gap-x-4 gap-y-2 flex-wrap items-center'}>
          <div className={'w-full'}>
            <CustomTextField
              fullWidth
              label={'Search'}
              value={state.requestQuery?.search}
              onChange={handleSearchBoxChange}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await handleSubmitSearch();
                }
              }}
              placeholder={'Company name, ticker, ISIN, or CUSIP'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'} sx={{ marginRight: '-0.25rem' }}>
                    <XDButton
                      onClick={handleSubmitSearch}
                      className={'text-2xl'}
                      disabled={state.requestQuery?.search === searchParams.get('search')}
                    >
                      <Search />
                    </XDButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className={'flex-grow'}></div>
          <div className={'flex flex-wrap items-center -mr-2'}>
            <p className={'text-sm -mt-0.5 flex-grow text-left'}>Sort by:</p>
            <XDDropdown
              topShift={'3rem'}
              closeOnAnyClick
              button={
                <XDButton propagateClick className={'text-sm'}>
                  <b>{sortByLabels[state.requestQuery?.sortBy ?? 'marketCapDesc']}</b>
                  <ArrowDropDown />
                </XDButton>
              }
            >
              <XDDropdownItem
                onClick={async () => {
                  await handleSort('companyNameAsc');
                }}
                isActive={state.requestQuery?.sortBy === 'companyNameAsc'}
              >
                {sortByLabels.companyNameAsc}
              </XDDropdownItem>
              <XDDropdownItem
                onClick={async () => {
                  await handleSort('companyNameDesc');
                }}
                isActive={state.requestQuery?.sortBy === 'companyNameDesc'}
              >
                {sortByLabels.companyNameDesc}
              </XDDropdownItem>
              <XDDropdownItem
                onClick={async () => {
                  await handleSort('marketCapAsc');
                }}
                isActive={state.requestQuery?.sortBy === 'marketCapAsc'}
              >
                {sortByLabels.marketCapAsc}
              </XDDropdownItem>
              <XDDropdownItem
                onClick={async () => {
                  await handleSort('marketCapDesc');
                }}
                isActive={state.requestQuery?.sortBy === 'marketCapDesc'}
              >
                {sortByLabels.marketCapDesc}
              </XDDropdownItem>
            </XDDropdown>
          </div>
        </div>
        <CStatefulContainer apiRequestState={state.requestState} showContent={!!state.singleStockList}>
          {state.singleStockList?.length === 0 && <XDEmptyResult />}
          <XDResponsiveGridWrapper widerBox>
            {state.singleStockList?.map((e, ix) => (
              <IndexCompanyCard key={`${e.companyid}-${e.gvkey}-${e.tradingitemid}`} order={ix} attr={e} />
            ))}
          </XDResponsiveGridWrapper>
        </CStatefulContainer>
        <XDPaginator
          pagination={state.pagination}
          retrievePageCallback={async (page) => {
            await handlePageChange(page);
          }}
          apiReqState={state.requestState}
        />
      </PageModule>
    </Page>
  );
}

interface props {
  attr: MSingleStockCardSummary;
  order?: number;
}

function IndexCompanyCard(props: props) {
  let tradingItemIcon: JSX.Element;

  switch (props.attr.tradingitemstatusname) {
    case 'Active':
      tradingItemIcon = <Check />;
      break;
    case 'Merged':
      tradingItemIcon = <Merge />;
      break;
    default:
      tradingItemIcon = <Close />;
      break;
  }

  return (
    <Link to={props.attr.companyid.toString()}>
      <XDEntryCard order={props.order}>
        <div className={'flex flex-col gap-0'}>
          <p className={'text-sm font-bold text-gray-600'}>{props.attr.tickersymbol}</p>
          <h3>{props.attr.companyname}</h3>
        </div>
        <div className={'flex flex-col gap-2 gap-x-4 text-gray-600 text-sm'}>
          <CompanyInfoDetail desc={props.attr.tradingitemstatusname} icon={tradingItemIcon} title={'Trading status'} />
          {props.attr.formattedmarketcap && (
            <CompanyInfoDetail desc={props.attr.formattedmarketcap} icon={<Paid />} title={'Market capitalization'} />
          )}
          <CompanyInfoDetail desc={props.attr.exchangename} icon={<Storefront />} title={'Exchange'} />
          <CompanyInfoDetail desc={props.attr.gsector} icon={<PieChart />} title={'Sector'} />
          <CompanyInfoDetail desc={props.attr.gind} icon={<Factory />} title={'Industry'} />
        </div>
      </XDEntryCard>
    </Link>
  );
}
