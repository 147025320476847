import React, { useEffect, useRef, useState } from 'react';

import { updateState } from '../../helpers/helper';

interface props {
  button: JSX.Element;
  children: React.ReactNode;
  topShift?: string; // TO DELETE
  closeOnAnyClick?: true;
  onClose?: () => void;
}

interface State {
  showDropdown: boolean;
}

export default function XDDropdown(props: props) {
  const [state, setState] = useState<State>({ showDropdown: false });

  const handleToggleDropdown = () => {
    updateState<State>({ showDropdown: !state.showDropdown }, state, setState);
  };

  const handleHide = () => {
    if (state.showDropdown) {
      updateState<State>({ showDropdown: false }, state, setState);
      if (props.onClose) props.onClose();
    }
  };

  return (
    <div className={'relative z-10 overflow-visible'}>
      {state.showDropdown && (
        <XDDropdownWindow onHide={handleHide} closeOnAnyClick={props.closeOnAnyClick}>
          {props.children}
        </XDDropdownWindow>
      )}
      <div onClick={handleToggleDropdown}>{props.button}</div>
    </div>
  );
}

interface windowProps {
  children?: React.ReactNode;
  onHide: () => void;
  closeOnAnyClick?: boolean;
}

function XDDropdownWindow(props: windowProps) {
  const ref = useRef<HTMLDivElement>(null);

  const handleHide = (ev: MouseEvent) => {
    const targetNode = ev.target as unknown as any;
    if (
      ((props.closeOnAnyClick && ref.current?.contains(targetNode)) as boolean) ||
      !ref.current?.parentNode?.contains(targetNode)
    ) {
      props.onHide();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleHide);
    window.addEventListener('scroll', props.onHide);
    return () => {
      window.removeEventListener('click', handleHide);
      window.removeEventListener('scroll', props.onHide);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{ top: '3rem', maxHeight: '50vh', width: 'fit-content' }}
      className={
        'absolute right-0 bg-white p-2 flex flex-col gap-0 border shadow-lg rounded-lg overflow-y-auto modal-dissolve-top-down'
      }
    >
      {props.children}
    </div>
  );
}
