import React from 'react';

interface props {
  label: string | JSX.Element;
  children?: string | JSX.Element | Array<string | undefined>  | number | undefined | null;
}

export default function XDTextFieldDisplay(props: props) {
  return (
    <div className={'flex flex-col gap-0'}>
      <p className={'opacity-80 font-bold text-sm uppercase'}>{props.label}</p>
      {
        props.children !== undefined && props.children !== null &&
        <h4>{props.children}</h4>
      }
    </div>
  );
}