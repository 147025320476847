import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import UserManagementService from '../../services/userManagementService';
import type UserAccountModel from '../../models/UserAccountModel';
import { type Role } from '../../models/UserAccountModel';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import TwoFaQrCodeModal from './TwoFaQrCodeModal';
import { updateUserObject } from '../../store/userSlice';
import CustomTextField from '../../components/CustomTextField';
import CustomSelectDropdown from '../../components/general/CustomSelectDropdown';
import PaddedButton from '../../components/general/PaddedButton';
import { QrCode, Save } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { addBreadcrumbTextReplacement, updateSnackbar } from '../../store/appSlice';
import { PageRoutes } from '../../enums/enums';
import PageModule from '../../components/layout/PageModule';
import Page from '../../components/layout/Page';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import PageModuleForm from '../../components/layout/PageModuleForm';

export default function EditUserDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userMail = useAppSelector((state) => state.user.email);

  const [id, setId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [role, setRole] = useState<Role>('user');
  const [twoFaUri, setTwoFaUri] = useState<string>();
  const [twoFaRequired, setTwoFaEnabled] = useState(false);
  const [displayQrCode, setDisplayQrCode] = useState(false);

  const updateUserData = (account: UserAccountModel) => {
    setId(account.id);
    setEmail(account.email);
    setFirstName(account.first_name);
    setLastName(account.last_name);
    setRole(account.role);
    setTwoFaEnabled(account.twoFaRequired);
    setTwoFaUri(account.twoFaUri);
  };

  const saveChanges = async () => {
    const res = await UserManagementService.updateAccount(id, firstName, lastName, email, role, twoFaRequired);
    if (res.id) {
      updateUserData(res);
      dispatch(updateUserObject({ firstName: res.first_name, lastName: res.last_name, email: res.email }));
      navigate('..');
    } else {
      dispatch(updateSnackbar({ severity: 'error', message: 'Changes could not be saved' }));
    }
    if (res.twoFaUri !== undefined) {
      setDisplayQrCode(true);
    }
  };

  const loadUser = async () => {
    if (!userMail) return;
    const user = await UserManagementService.getAccount(userMail);
    updateUserData(user);
  };

  useEffect(() => {
    loadUser().then();
  }, [userMail]);

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([PageRoutes.USERS_EDIT, 'Edit']));
  }, [dispatch]);

  return (<Page>
    <PageModule>
      <PageModuleTitle>Edit user details</PageModuleTitle>
      <PageModuleForm title={'Account details'}>
        <CustomTextField label='UUID' value={id} disabled />
        <CustomTextField
          label='Email'
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }} disabled
        />
        <CustomSelectDropdown label={'Role'} className={'capitalize'} value={role}
                              onChange={(e) => {
                                setRole(e.target.value as Role);
                              }} growOnMobile>
          {['user', 'admin'].map((e) => <MenuItem key={e} value={e} className={'capitalize'}>{e}</MenuItem>)}
        </CustomSelectDropdown>
        <FormControlLabel
          value='start'
          control={<Checkbox
            checked={twoFaRequired}
            onChange={(e) => {
              setTwoFaEnabled(e.target.checked);
            }}
          />}
          label='Enable two-factor authentication'
          labelPlacement='end'
        />
      </PageModuleForm>
      {twoFaUri && <PageModuleForm title={'Two-Factor Authentication (2FA)'}>
        <PaddedButton label={'Show QR-Code'} icon={<QrCode />} onClick={() => {
          setDisplayQrCode(true);
        }} />
      </PageModuleForm>}
      <PageModuleForm title={'Personal details'}>
        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-4'}>
          <CustomTextField
            label='First name'
            placeholder={'Type in first name'}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <CustomTextField
            label='Last name'
            placeholder={'Type in last name'}
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </div>
      </PageModuleForm>
      <PageModuleForm>
        <PaddedButton className={'my-6'} label={'Save'} onClick={saveChanges} icon={<Save />} growOnMobile />
      </PageModuleForm>
    </PageModule>
    <TwoFaQrCodeModal
      open={displayQrCode}
      uri={twoFaUri}
      setDisplayQrCode={setDisplayQrCode}
    />
  </Page>);
}