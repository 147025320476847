import React from 'react';
import { useKeycloak } from '@react-keycloak/web';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { keycloak   } = useKeycloak();

  if (keycloak.authenticated) {
    return children;
  } else {
    keycloak.login().then();
    return (<></>);
  }
}
