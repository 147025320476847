import { FilterList, OpenInNew } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CLocalizedText from '../../../components/CLocalizedText';
import { type IPageModule } from '../../../components/CPageModule';
import TagButton from '../../../components/TagButton';
import XDEmptyResult from '../../../components/alerts/XDEmptyResult';
import XDButton from '../../../components/buttons/XDButton';
import XDCheckbox from '../../../components/forms/XDCheckbox';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import XDDropdown from '../../../components/selection/XDDropdown';
import HDate from '../../../helpers/HDate';
import { updateState } from '../../../helpers/helper';
import { type MSingleStockSummarizedInfo } from '../../../models/singleStock/details/MSingleStockSummarizedInfo';
import { type SingleStockCurrentHistoricalInformation } from '../../../models/singleStock/details/SingleStockCurrentHistoricalInformation';
import { type SingleStockKeyEvent } from '../../../models/singleStock/details/SingleStockKeyEvent';
import { type SingleStockLastDividendPayment } from '../../../models/singleStock/details/SingleStockLastDividendPayment';
import { type SingleStockLastStockSplit } from '../../../models/singleStock/details/SingleStockLastStockSplit';

interface Props extends Pick<IPageModule, 'divRef'> {
  summarizedInfo?: MSingleStockSummarizedInfo;
}

interface State {
  includeHistorical: boolean;
  includeKeyEvents: boolean;
  includeDividendPayments: boolean;
  includeStockSplits: boolean;
  eventArray?: Array<
    Partial<
      | SingleStockCurrentHistoricalInformation
      | SingleStockLastStockSplit
      | SingleStockLastDividendPayment
      | SingleStockKeyEvent
    >
  >;
}

type EventTypes = 'historical' | 'keyEvents' | 'dividendPayments' | 'stockSplits';

export default function PmSingleStockEvents(props: Props) {
  const [params, setParams] = useSearchParams();
  const [state, setState] = useState<State>({
    includeHistorical: false,
    includeDividendPayments: false,
    includeKeyEvents: false,
    includeStockSplits: false
  });

  const handleCheckboxChange = (eventType: EventTypes) => {
    switch (eventType) {
      case 'historical':
        updateState<State>({ includeHistorical: !state.includeHistorical }, state, setState);
        break;
      case 'keyEvents':
        updateState<State>({ includeKeyEvents: !state.includeKeyEvents }, state, setState);
        break;
      case 'stockSplits':
        updateState<State>({ includeStockSplits: !state.includeStockSplits }, state, setState);
        break;
      case 'dividendPayments':
        updateState<State>({ includeDividendPayments: !state.includeDividendPayments }, state, setState);
        break;
    }
  };

  const filterButton = (
    <div className={'-mr-2'}>
      <XDDropdown
        topShift={'3rem'}
        button={
          <XDButton className={'text-sm font-bold'} propagateClick>
            <span className={'flex gap-1'}>
              <CLocalizedText dictKey={'globalFilter'} /> (
              {
                [
                  state.includeHistorical,
                  state.includeKeyEvents,
                  state.includeStockSplits,
                  state.includeDividendPayments
                ].filter((e) => e).length
              }
              )
            </span>
            <FilterList />
          </XDButton>
        }
      >
        <div className={'flex flex-col gap-1'}>
          {(props.summarizedInfo?.currenthistoricalinformation.length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeHistorical}
              onChange={() => {
                handleCheckboxChange('historical');
              }}
            >
              <TagButton className={'bg-teal-ppt-2 bg-opacity-10 text-teal-ppt-2 whitespace-nowrap'}>
                <CLocalizedText dictKey={'singleStockEventHistoricalEvent'} />
              </TagButton>
            </XDCheckbox>
          )}
          {(props.summarizedInfo?.keyevents.length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeKeyEvents}
              onChange={() => {
                handleCheckboxChange('keyEvents');
              }}
            >
              <TagButton className={'bg-teal-ppt-4 bg-opacity-10 text-teal-ppt-4 whitespace-nowrap'}>
                <CLocalizedText dictKey={'singleStockEventKeyEvent'} />
              </TagButton>
            </XDCheckbox>
          )}
          {(props.summarizedInfo?.lastdividendpayments.length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeDividendPayments}
              onChange={() => {
                handleCheckboxChange('dividendPayments');
              }}
            >
              <TagButton className={'bg-teal-ppt-6 bg-opacity-10 text-teal-ppt-6 whitespace-nowrap'}>
                <CLocalizedText dictKey={'singleStockEventDividendPayment'} />
              </TagButton>
            </XDCheckbox>
          )}
          {(props.summarizedInfo?.laststocksplits.length ?? 0) > 0 && (
            <XDCheckbox
              style={{ minHeight: 0, padding: '0.25rem' }}
              checked={state.includeStockSplits}
              onChange={() => {
                handleCheckboxChange('stockSplits');
              }}
            >
              <TagButton className={'bg-teal-ppt-9 bg-opacity-10 text-teal-ppt-9 whitespace-nowrap'}>
                <CLocalizedText dictKey={'singleStockEventStockSplit'} />
              </TagButton>
            </XDCheckbox>
          )}
        </div>
      </XDDropdown>
    </div>
  );

  const recomputeEventArray = () => {
    const eventArray: State['eventArray'] = [];

    if (state.includeHistorical && props.summarizedInfo?.currenthistoricalinformation) {
      eventArray.push(...props.summarizedInfo?.currenthistoricalinformation);
    }

    if (state.includeKeyEvents && props.summarizedInfo?.keyevents) {
      eventArray.push(...props.summarizedInfo?.keyevents);
    }

    if (state.includeDividendPayments && props.summarizedInfo?.lastdividendpayments) {
      eventArray.push(...props.summarizedInfo?.lastdividendpayments);
    }

    if (state.includeStockSplits && props.summarizedInfo?.laststocksplits) {
      eventArray.push(...props.summarizedInfo?.laststocksplits);
    }

    updateState<State>(
      {
        eventArray: eventArray.sort((a, b) => {
          return (a.commontimestamp ?? 0) > (b.commontimestamp ?? 0) ? -1 : 1;
        })
      },
      state,
      setState
    );
  };

  useEffect(() => {
    const eventFilter = params.get('eventFilter');
    if (!eventFilter) {
      updateState<State>(
        {
          includeKeyEvents: (props.summarizedInfo?.keyevents.length ?? 0) > 0,
          includeStockSplits: (props.summarizedInfo?.laststocksplits.length ?? 0) > 0,
          includeDividendPayments: (props.summarizedInfo?.lastdividendpayments.length ?? 0) > 0,
          includeHistorical: (props.summarizedInfo?.currenthistoricalinformation.length ?? 0) > 0
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          includeKeyEvents: eventFilter.includes('keyEvents' as EventTypes),
          includeStockSplits: eventFilter.includes('stockSplits' as EventTypes),
          includeDividendPayments: eventFilter.includes('dividendPayments' as EventTypes),
          includeHistorical: eventFilter.includes('historical' as EventTypes)
        },
        state,
        setState
      );
    }
  }, [props.summarizedInfo]);

  useEffect(() => {
    const computeFilterList = () => {
      const li: EventTypes[] = [];
      if (state.includeStockSplits) li.push('stockSplits');
      if (state.includeHistorical) li.push('historical');
      if (state.includeKeyEvents) li.push('keyEvents');
      if (state.includeDividendPayments) li.push('dividendPayments');
      return li;
    };
    if (computeFilterList().length > 0) {
      params.set('eventFilter', computeFilterList().join(','));
    } else {
      params.delete('eventFilter');
    }
    setParams(params, { replace: true });

    recomputeEventArray();
  }, [
    state.includeDividendPayments,
    state.includeHistorical,
    state.includeKeyEvents,
    state.includeStockSplits,
    props.summarizedInfo
  ]);

  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'flex gap-4 justify-end flex-wrap items-center'}>
        {
          <>
            <p className={'flex-grow'}>
              {state.eventArray?.length} <CLocalizedText dictKey={'entriesFound'} />
            </p>
            {filterButton}
          </>
        }
      </div>
      <div className={'rounded-lg overflow-hidden border'}>
        <div className={'p-4 bg-gray-50 overflow-y-auto max-h-[50vh]'}>
          <XDResponsiveGridWrapper type={'list'}>
            <>
              {(state.eventArray?.length ?? 0) === 0 && <XDEmptyResult />}
              {state.eventArray?.map((e, ix) => {
                switch (e.eventtype) {
                  case 'historical':
                    return (
                      <HistoricalEventCard
                        key={e.uuid}
                        order={ix}
                        event={e as SingleStockCurrentHistoricalInformation}
                      />
                    );
                  case 'keyEvents':
                    return <KeyEventCard key={e.uuid} order={ix} event={e as SingleStockKeyEvent} />;
                  case 'dividendPayments':
                    return (
                      <DividendPaymentEventCard key={e.uuid} order={ix} event={e as SingleStockLastDividendPayment} />
                    );
                  case 'stockSplits':
                    return <StockSplitEventCard key={e.uuid} order={ix} event={e as SingleStockLastStockSplit} />;
                  default:
                    return <></>;
                }
              })}
            </>
          </XDResponsiveGridWrapper>
        </div>
      </div>
    </div>
  );
}

function EventCard(props: {
  children?: JSX.Element;
  eventType: EventTypes;
  commonTimestamp: number;
  className?: string;
  order: number;
}) {
  const classNames = ['p-4 rounded-md flex flex-col gap-1 bg-white'];

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className={'uppercase font-bold text-sm flex gap-2 items-center'}>
        <p className={'opacity-80'}>{HDate.getShortDate(new Date(props.commonTimestamp * 1000))}</p>
        {props.eventType === 'historical' && (
          <TagButton marginTopInRem={-0.1} className={'bg-teal-ppt-2 bg-opacity-10 text-teal-ppt-2'} color={'none'}>
            <CLocalizedText dictKey={'singleStockEventHistoricalEvent'} />
          </TagButton>
        )}
        {props.eventType === 'keyEvents' && (
          <TagButton marginTopInRem={-0.1} className={'bg-teal-ppt-4 bg-opacity-10 text-teal-ppt-4'} color={'none'}>
            <CLocalizedText dictKey={'singleStockEventKeyEvent'} />
          </TagButton>
        )}
        {props.eventType === 'dividendPayments' && (
          <TagButton marginTopInRem={-0.1} className={'bg-teal-ppt-6 bg-opacity-10 text-teal-ppt-6'} color={'none'}>
            <CLocalizedText dictKey={'singleStockEventDividendPayment'} />
          </TagButton>
        )}
        {props.eventType === 'stockSplits' && (
          <TagButton marginTopInRem={-0.1} className={'bg-teal-ppt-9 bg-opacity-10 text-teal-ppt-9'} color={'none'}>
            <CLocalizedText dictKey={'singleStockEventStockSplit'} />
          </TagButton>
        )}
      </div>
      {props.children}
    </div>
  );
}

function HistoricalEventCard(props: { event: SingleStockCurrentHistoricalInformation; order: number }) {
  return (
    <EventCard eventType={'historical'} commonTimestamp={props.event.commontimestamp} order={props.order}>
      <>
        <div className={'flex flex-col gap-0'}>
          {!props.event.gsector.includes('Not disclosed') && (
            <p>
              <b>Sector:</b> {props.event.gsector}
            </p>
          )}
          {!props.event.ggroup.includes('Not disclosed') && (
            <p>
              <b>Group:</b> {props.event.ggroup}
            </p>
          )}
          {!props.event.gind.includes('Not disclosed') && (
            <p>
              <b>Industry:</b> {props.event.gind}
            </p>
          )}
          {!props.event.gsubind.includes('Not disclosed') && (
            <p>
              <b>Sub-industry:</b> {props.event.gsubind}
            </p>
          )}
        </div>
      </>
    </EventCard>
  );
}

function KeyEventCard(props: { event: SingleStockKeyEvent; order: number }) {
  const desc: string[] = [];

  if (props.event.document_name) {
    desc.push(props.event.document_name);
  }

  if (props.event.form_type) {
    desc.push(`Form ${props.event.form_type}`);
  }

  if (props.event.description) {
    desc.push(props.event.description);
  }

  return (
    <EventCard eventType={'keyEvents'} commonTimestamp={props.event.commontimestamp} order={props.order}>
      <>
        <h4>{props.event.event_group}</h4>
        <p>
          {desc.join(' • ')}{' '}
          {props.event.link && (
            <span>
              {' '}
              &bull;{' '}
              <a href={props.event.link} target={'_blank'} rel="noreferrer">
                <CLocalizedText dictKey={'globalLink'} /> <OpenInNew />
              </a>{' '}
            </span>
          )}
        </p>
      </>
    </EventCard>
  );
}

function DividendPaymentEventCard(props: { event: SingleStockLastDividendPayment; order: number }) {
  const desc: string[] = [];

  if (props.event.divamount) {
    if (props.event.currencyname) {
      desc.push(`${props.event.divamount} ${props.event.currencyname}`);
    } else {
      desc.push(props.event.divamount.toString());
    }
  }

  if (props.event.recorddate) {
    desc.push(`Recorded on ${HDate.getShortDate(new Date(props.event.recorddate))}`);
  }

  if (props.event.paydate) {
    desc.push(`Paid out on ${HDate.getShortDate(new Date(props.event.paydate))}`);
  }

  return (
    <EventCard eventType={'dividendPayments'} commonTimestamp={props.event.commontimestamp} order={props.order}>
      <>
        <h4>{props.event.supplementaltypename}</h4>
        <p>{desc.join(' • ')}</p>
      </>
    </EventCard>
  );
}

function StockSplitEventCard(props: { event: SingleStockLastStockSplit; order: number }) {
  return (
    <EventCard eventType={'stockSplits'} commonTimestamp={props.event.commontimestamp} order={props.order}>
      <>
        <h4>Stock split by a factor of {props.event.split_factor}</h4>
        {props.event.announced_date && <p>Announced on {HDate.getShortDate(new Date(props.event.announced_date))}</p>}
      </>
    </EventCard>
  );
}
