import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { ViewsEnumDeprecate } from '../../enums/viewsEnumDeprecate';
import HGeneric from '../../helpers/HGeneric';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { toggleSidebar, updateActiveView, updateSidebarDim } from '../../store/appSlice';
import CLocalizedText from '../CLocalizedText';
import SidebarButton from './SidebarButton';
import SidebarContents from './SidebarContents';

export default function Sidebar() {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { headerDimensions, dashboardOutletDimensions } = useAppSelector((state) => state.dashboard);
  const sidebar = useAppSelector((state) => state.dashboard.sidebar);

  const minimizeSidebarIcon = sidebar.isExpanded ? <ChevronDoubleLeftIcon /> : <ChevronDoubleRightIcon />;

  const updateSidebarWidth = (isExpanded: boolean) => {
    const multiplier = isExpanded ? 14 : 4;
    dispatch(
      updateSidebarDim({ w: document.documentElement.clientWidth >= 768 ? multiplier * HGeneric.getRemInPx() : 0 })
    );
  };

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
    updateSidebarWidth(!sidebar.isExpanded);
  };

  useEffect(() => {
    const enumString = location.pathname.split('/')[1] as unknown as ViewsEnumDeprecate;
    dispatch(updateActiveView(ViewsEnumDeprecate[enumString]));
  }, [location.pathname, dispatch]);

  useEffect(() => {
    updateSidebarWidth(sidebar.isExpanded);
  }, [headerDimensions.w]);

  return (
    <>
      <div
        ref={sidebarRef}
        className={'bg-teal-2 text-white whitespace-nowrap select-none cursor-default fixed hidden md:block'}
        style={{
          minWidth: sidebar.isExpanded ? '14rem' : '4rem',
          width: sidebar.isExpanded ? '14rem' : '4rem',
          height: dashboardOutletDimensions.h,
          top: headerDimensions.h,
          zIndex: 1
        }}
      >
        <div
          className={'flex flex-col p-2 py-3 overflow-y-auto overflow-x-hidden text-sm'}
          style={{ height: `calc(${dashboardOutletDimensions.h}px - 4rem)` }}
        >
          <div className={'flex flex-col gap-2'}>
            <SidebarContents sidebarIsExpanded={sidebar.isExpanded} />
          </div>
        </div>
        <div
          className={'fixed bottom left-0 bottom-0 bg-teal-2'}
          style={{ width: sidebar.isExpanded ? '14rem' : '4rem' }}
        >
          <div className={'p-2'}>
            <SidebarButton
              label={<CLocalizedText dictKey={'globalSidebarMinimize'} />}
              onClick={handleToggleSidebar}
              isSelected={false}
              icon={minimizeSidebarIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
}
