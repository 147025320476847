import { type GenericResponse } from './GenericsModel';
import { type PlotlyResponse } from './PlotlyModel';

interface BacktestInfo {
  backtest_id: string;
}

interface BacktestListModel {
  results: BacktestInfo[];
}

interface BenchmarkList {
  benchmarks: string[];
}

type TwoLevelJson = Record<string, Record<string, string | number | boolean>>;
type TwoLevelNumberJson = Record<string, Record<string, number>>;
type PerformanceTable = Record<
  'stock' | 'benchmark' | string,
  Record<'Start date' | 'End date' | string, string | number | boolean>
>;

interface BacktestPerformanceTableResponse extends GenericResponse {
  performanceTable: PerformanceTable;
}

interface BacktestReportModel {
  performanceTable?: TwoLevelJson;
  hygieneFactors?: TwoLevelJson;
  backtestInputTable?: TwoLevelJson;
  portfolioSortsTable?: TwoLevelJson;
  backtestStatsTable?: TwoLevelJson;
  cumulativePerformance?: TwoLevelNumberJson;
  portfolioValues?: TwoLevelNumberJson;
  underwater?: TwoLevelNumberJson;
  turnover?: TwoLevelNumberJson;
  annualReturn?: TwoLevelNumberJson;
  cashQuote?: TwoLevelNumberJson;
  leverage?: TwoLevelNumberJson;
  marketCapitalization?: TwoLevelNumberJson;
  portfolioWeightDistribution?: TwoLevelNumberJson;
  portfolioCompanies?: TwoLevelNumberJson;
  factorExposure?: TwoLevelNumberJson;
  sectorExposure?: TwoLevelNumberJson;
  deltaDistribution?: TwoLevelNumberJson;
  gammaDistribution?: TwoLevelNumberJson;
  vegaDistribution?: TwoLevelNumberJson;
}

interface BacktestReportNewModel {
  hygieneFactors?: TwoLevelJson;
  backtestInput?: TwoLevelJson;
  portfolioSorts?: TwoLevelJson;
  backtestStats?: TwoLevelJson;
  performanceTable?: PerformanceTable;
  cumulativePerformance?: PlotlyResponse;
  portfolioValues?: PlotlyResponse;
  underwater?: PlotlyResponse;
  turnover?: PlotlyResponse;
  returnPerAnnum?: PlotlyResponse;
  cashQuote?: PlotlyResponse;
  leverage?: PlotlyResponse;
  marketCapitalization?: PlotlyResponse;
  portfolioWeightDistribution?: PlotlyResponse;
  numberOfPortfolioCompanies?: PlotlyResponse;
  factorExposure?: PlotlyResponse;
  sectorExposure?: PlotlyResponse;
  gamma?: PlotlyResponse;
  delta?: PlotlyResponse;
  vega?: PlotlyResponse;
  returnDistribution?: PlotlyResponse;
}

enum BacktestStrategyEnum {
  NEXDOS = 'neXDos'
}

enum BacktestBenchmarkEnum {
  DEFAULT_BENCHMARK = 'S&P 500 Value'
}

interface BacktestNodeWithMeta {
  name: string;
  backtestCount?: number;
}

interface BacktestTreeResponse {
  value?: string;
  level?: string;
  items: BacktestNodeWithMeta[];
  itemsLevel: string;
}

export type {
  BacktestInfo,
  BacktestListModel,
  BacktestReportModel,
  BacktestReportNewModel,
  TwoLevelJson,
  BenchmarkList,
  PerformanceTable,
  BacktestPerformanceTableResponse,
  BacktestTreeResponse
};

export { BacktestStrategyEnum, BacktestBenchmarkEnum };
