import { DatePicker, type DatePickerProps } from '@mui/x-date-pickers';
import React from 'react';

interface CustomDatePickerProps extends DatePickerProps<any> {
  helperText?: string;
  isError?: boolean;
}

/***
 * MUI X v6 DatePicker wrapper to enforce the 'filled' variant.
 * @param props one or more properties of DatePickerProps
 * @constructor
 */
export default function CustomDatePicker(props: Partial<CustomDatePickerProps>) {
  const prCopy = { ...props };
  delete prCopy.helperText;
  delete prCopy.isError;
  const pr = { label: 'Label', ...prCopy };
  return (
    <DatePicker
      format={'D MMM YYYY'}
      slotProps={{
        textField: {
          variant: 'filled',
          helperText: props.helperText,
          fullWidth: true,
          error: props.isError,
          sx: {
            '.Mui-disabled': { color: 'rgba(0, 0, 0, 0.8)' },
            '.Mui-disabled.Mui-error': { color: '#d32f2f' },
            '.Mui-disabled input': { WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)' }
          },
          disabled: false
        }
      }}
      {...pr}
    />
  );
}
