import React from 'react';

interface statProps {
  lvl1Key: string;
  lvl2Dict: Record<string, string | number | boolean> | undefined;
}

export default function BacktestStatisticsModule({lvl1Key, lvl2Dict}: statProps) {
  return (<div className={'flex flex-col gap-2'}>
    <p className={'font-semibold uppercase text-teal-1'}>
      {lvl1Key.replaceAll('_', ' ')}
    </p>
    {Object.keys(lvl2Dict ?? {}).map((lvl2Key) => <div key={lvl2Key} className={'flex gap-x-4 gap-y-0 border-b items-end flex-wrap'}>
      <span className={'flex-grow flex-shrink-0 capitalize'}>{lvl2Key.replaceAll('_', ' ')
          .replaceAll('sql', 'SQL')
          .replaceAll('esg', 'ESG')
          .replaceAll('pct', 'PCT')
      }</span>
      <span className={'flex-grow text-right'}><strong>{lvl2Dict?.[lvl2Key].toString().replaceAll('_', ' ')}</strong></span>
    </div>)}
  </div>);
}