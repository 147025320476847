import React from 'react';

export interface ITabItem {
  label: string;
  text?: JSX.Element | string;
  children?: React.ReactNode;
}

export default function CTabItem(props: ITabItem) {
  return <>{props.children}</>;
}
