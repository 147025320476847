import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CHalfWidthSunburstPlot from '../../components/CHalfWidthSunburstPlot';
import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePaginatedTable from '../../components/CPageModulePaginatedTable';
import CPandasTable from '../../components/CPandasTable';
import CSectionNavigator from '../../components/CSectionNavigator';
import CStatefulContainer from '../../components/CStatefulContainer';
import CTab from '../../components/CTab';
import CTabItem from '../../components/CTabItem';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type PaginatedBackendResponse } from '../../models/GhostPostModel';
import { type IRequestState } from '../../models/IRequestState';
import { type ISortingMeta } from '../../models/ISortingMeta';
import { type ITableOrPlotState } from '../../models/ITableOrPlotState';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

type TSortOptions = 'marketCapAsc' | 'marketCapDesc' | 'allocAsc' | 'allocDesc';
type TSectorOptions =
  | 'communicationServices'
  | 'consumerDiscretionary'
  | 'consumerStaples'
  | 'energy'
  | 'financials'
  | 'healthCare'
  | 'industrials'
  | 'informationTechnology'
  | 'materials'
  | 'realEstate'
  | 'utilities'
  | undefined;

interface State {
  chartTab: {
    industry: 'exchangeIndustry' | 'industry';
    valuesColumn: 'mcap' | 'allocationWeight';
  };
  sectorAssignment: {
    tableType: 'universe' | 'swap';
    sector: TSectorOptions;
    sortBy: TSortOptions;
  };
  state?: Partial<{
    universeSwapTables: IRequestState;
    charts: IRequestState;
    sectorAssignment: IRequestState;
  }>;
  response?: Partial<{
    universeSwapTable: Record<'universe' | 'swap', Record<string, Record<string, any>>>;
    charts: Record<
      'industryMcap' | 'exchangeIndustryMcap' | 'industryAllocationWeight' | 'exchangeIndustryAllocationWeight',
      Record<'universe' | 'swap', PlotlyResponse>
    >;
    sectorAssignment: PaginatedBackendResponse<Record<string, Record<string, any>>>;
  }>;
  params: {
    // allocationUniverse: ISortingMeta;
    // allocationSwap: ISortingMeta;
    assignmentUniverse: ISortingMeta;
    assignmentSwap: ISortingMeta;
  };
  sectorAssignmentUniverse: ITableOrPlotState<PaginatedBackendResponse<Record<string, Record<string, any>>>>;
  sectorAssignmentSwap: ITableOrPlotState<PaginatedBackendResponse<Record<string, Record<string, any>>>>;
}

export default function PSectorExposure() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const { factsheetId } = useParams();
  const [state, setState, getState] = useStateExtended<State>({
    chartTab: {
      industry: 'industry',
      valuesColumn: 'allocationWeight'
    },
    sectorAssignment: {
      tableType: 'swap',
      sector: undefined,
      sortBy: 'allocDesc'
    },
    state: {
      sectorAssignment: { isError: false, isLoading: true },
      charts: { isError: false, isLoading: true },
      universeSwapTables: { isError: false, isLoading: true }
    },
    params: {
      assignmentUniverse: {
        ascending: 'descending',
        sortCol: 5
      },
      assignmentSwap: {
        ascending: 'descending',
        sortCol: 5
      }
    },
    sectorAssignmentUniverse: {
      response: undefined,
      state: {
        isError: false,
        isLoading: true
      },
      params: {
        sorting: {
          ascending: 'descending',
          sortCol: 5
        }
      }
    },
    sectorAssignmentSwap: {
      response: undefined,
      state: {
        isError: false,
        isLoading: true
      },
      params: {
        sorting: {
          ascending: 'descending',
          sortCol: 5
        }
      }
    }
  });

  const sectionNavigator = (
    <CSectionNavigator
      previous={{
        url: `../${PageRoutes.PUBLIC_FACTSHEET_FACTOR_EXPOSURE}`,
        label: <CLocalizedText dictKey={'globalFactsheetSectionFacex'} />
      }}
      next={{
        url: `../${PageRoutes.PUBLIC_FACTSHEET_DELTA}`,
        label: <CLocalizedText dictKey={'globalFactsheetSectionDelta'} />
      }}
    />
  );

  const fetchTable = async (sorting?: ISortingMeta) => {
    updateState<State>(
      {
        state: {
          ...(await getState()).state,
          universeSwapTables: {
            isLoading: true,
            isError: (await getState()).state?.universeSwapTables?.isError ?? false
          }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getSectorExposureUniverseSwapTables(factsheetId ?? '', sorting, locale);
    updateState<State>(
      {
        state: {
          ...(await getState()).state,
          universeSwapTables: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        { response: { ...(await getState()).response, universeSwapTable: res.getData() } },
        state,
        setState
      );
    }
  };

  const fetchCharts = async () => {
    updateState<State>(
      {
        state: {
          ...(await getState()).state,
          charts: { isLoading: true, isError: (await getState()).state?.charts?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getSectorExposureCharts(factsheetId ?? '', locale);
    updateState<State>(
      {
        state: {
          ...(await getState()).state,
          charts: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>({ response: { ...(await getState()).response, charts: res.getData() } }, state, setState);
    }
  };

  const fetchSectorAssignmentUniverse = async (
    page: number,
    sorting?: ISortingMeta,
    search?: string,
    filters?: string[]
  ) => {
    const lastState = (await getState()).sectorAssignmentUniverse;
    updateState<State>(
      {
        sectorAssignmentUniverse: { ...lastState, state: { ...lastState.state, isLoading: true } }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getSectorAssignmentTable(
      factsheetId ?? '',
      'universe',
      page,
      sorting,
      '',
      locale,
      search,
      filters
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          sectorAssignmentUniverse: {
            ...lastState,
            state: { isLoading: false, isError: false },
            response: res.getData(),
            params: { sorting, search }
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          sectorAssignmentUniverse: {
            ...lastState,
            state: { isLoading: false, isError: true, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  const fetchSectorAssignmentSwap = async (
    page: number,
    sorting?: ISortingMeta,
    search?: string,
    filters?: string[]
  ) => {
    const lastState = (await getState()).sectorAssignmentSwap;
    updateState<State>(
      {
        sectorAssignmentSwap: { ...lastState, state: { ...lastState.state, isLoading: true } }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getSectorAssignmentTable(
      factsheetId ?? '',
      'swap',
      page,
      sorting,
      '',
      locale,
      search,
      filters
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          sectorAssignmentSwap: {
            ...lastState,
            state: { isLoading: false, isError: false },
            response: res.getData(),
            params: { sorting, search }
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          sectorAssignmentSwap: {
            ...lastState,
            state: { isLoading: false, isError: true, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  const handleTabSwitch = (selectedTab?: string) => {
    switch (selectedTab) {
      case 'industry':
      case 'exchangeIndustry':
        updateState<State>({ chartTab: { ...state.chartTab, industry: selectedTab } }, state, setState);
        break;
      case 'mcap':
      case 'allocationWeight':
        updateState<State>({ chartTab: { ...state.chartTab, valuesColumn: selectedTab } }, state, setState);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTable();
      await fetchCharts();
      const sectorAssignmentUniverse = (await getState()).sectorAssignmentUniverse;
      await fetchSectorAssignmentUniverse(
        sectorAssignmentUniverse.response?.meta.pagination.page ?? 1,
        sectorAssignmentUniverse.params.sorting,
        sectorAssignmentUniverse.params.search,
        sectorAssignmentUniverse.response?.meta.filter.applied?.split(',')
      );
      const sectorAssignmentSwap = (await getState()).sectorAssignmentSwap;
      await fetchSectorAssignmentSwap(
        sectorAssignmentSwap.response?.meta.pagination.page ?? 1,
        sectorAssignmentSwap.params.sorting,
        sectorAssignmentSwap.params.search,
        sectorAssignmentSwap.response?.meta.filter.applied?.split(',')
      );
    })();
  }, [locale]);

  return (
    <Page dictKey={'globalFactsheetSectionSecex'}>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'fssecexP1H1'} />
        </h2>
        <CLocalizedText dictKey={'fssecexP1Desc'} />
      </CPageModule>
      <CPageModule>
        <h3>
          <CLocalizedText dictKey={'fssecexP3H1'} />
        </h3>
        <CLocalizedText dictKey={'fssecexP3Desc'} />
        <CStatefulContainer showContent={!!state.response?.charts} apiRequestState={state.state?.charts}>
          <div className={'flex gap-8 flex-wrap'}>
            <CTab onChange={handleTabSwitch} centerTabButtons>
              <CTabItem label={'industry'} text={<CLocalizedText dictKey={'globalIndustry'} />}>
                <CHalfWidthSunburstPlot
                  title={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}
                  data={state.response?.charts?.industryAllocationWeight.universe.data}
                  layout={state.response?.charts?.industryAllocationWeight.universe.layout}
                />
              </CTabItem>
              <CTabItem label={'exchangeIndustry'} text={<CLocalizedText dictKey={'globalExchangeAndIndustry'} />}>
                <CHalfWidthSunburstPlot
                  title={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}
                  data={state.response?.charts?.exchangeIndustryAllocationWeight.universe.data}
                  layout={state.response?.charts?.exchangeIndustryAllocationWeight.universe.layout}
                />
              </CTabItem>
            </CTab>
            <CTab onChange={handleTabSwitch} centerTabButtons>
              <CTabItem label={'allocationWeight'} text={<CLocalizedText dictKey={'globalAllocationWeight'} />}>
                {state.chartTab.industry === 'industry' && (
                  <CHalfWidthSunburstPlot
                    title={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}
                    data={state.response?.charts?.industryAllocationWeight.swap.data}
                    layout={state.response?.charts?.industryAllocationWeight.swap.layout}
                  />
                )}
                {state.chartTab.industry === 'exchangeIndustry' && (
                  <CHalfWidthSunburstPlot
                    title={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}
                    data={state.response?.charts?.exchangeIndustryAllocationWeight.swap.data}
                    layout={state.response?.charts?.exchangeIndustryAllocationWeight.swap.layout}
                  />
                )}
              </CTabItem>
              <CTabItem label={'mcap'} text={<CLocalizedText dictKey={'globalMarketCapitalization'} />}>
                {state.chartTab.industry === 'industry' && (
                  <CHalfWidthSunburstPlot
                    title={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}
                    data={state.response?.charts?.industryMcap.swap.data}
                    layout={state.response?.charts?.industryMcap.swap.layout}
                  />
                )}
                {state.chartTab.industry === 'exchangeIndustry' && (
                  <CHalfWidthSunburstPlot
                    title={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}
                    data={state.response?.charts?.exchangeIndustryMcap.swap.data}
                    layout={state.response?.charts?.exchangeIndustryMcap.swap.layout}
                  />
                )}
              </CTabItem>
            </CTab>
          </div>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fssecexP2H1'} />
          </h3>
          <CLocalizedText dictKey={'fssecexP2Desc'} />
        </CPageModule>
        <CStatefulContainer
          submodule
          showContent={!!state.response?.universeSwapTable}
          apiRequestState={state.state?.universeSwapTables}
        >
          <CTab submodule>
            <CTabItem label={'universe'} text={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}>
              <CHorizontalScrollContainer>
                <CPandasTable data={state.response?.universeSwapTable?.universe} />
              </CHorizontalScrollContainer>
            </CTabItem>
            <CTabItem label={'swap'} text={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}>
              <CHorizontalScrollContainer>
                <CPandasTable data={state.response?.universeSwapTable?.swap} />
              </CHorizontalScrollContainer>
            </CTabItem>
          </CTab>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fssecexP4H1'} />
          </h3>
          <CLocalizedText dictKey={'fssecexP4Desc'} />
        </CPageModule>
        <CStatefulContainer
          submodule
          showContent={!!state.sectorAssignmentUniverse.response}
          apiRequestState={state.sectorAssignmentUniverse.state}
        >
          <CTab submodule>
            <CTabItem label={'universe'} text={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}>
              <CPageModulePaginatedTable
                state={state.sectorAssignmentUniverse.state}
                data={state.sectorAssignmentUniverse.response}
                searchBarName={'universe'}
                colSpanArr={[3, 1, 2, 2, 1, 1]}
                params={state.sectorAssignmentUniverse.params}
                onChange={async (page, sorting, searchText, filters) => {
                  await fetchSectorAssignmentUniverse(page, sorting, searchText, filters);
                }}
              />
            </CTabItem>
            <CTabItem label={'swap'} text={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}>
              <CPageModulePaginatedTable
                state={state.sectorAssignmentSwap.state}
                data={state.sectorAssignmentSwap.response}
                searchBarName={'swap'}
                colSpanArr={[3, 1, 2, 2, 1, 1]}
                params={state.sectorAssignmentSwap.params}
                onChange={async (page, sorting, searchText, filters) => {
                  await fetchSectorAssignmentSwap(page, sorting, searchText, filters);
                }}
              />
            </CTabItem>
          </CTab>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}
