import { type AlertColor } from '@mui/material';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { type ActionsEnumDeprecate } from '../enums/actionsEnumDeprecate';
import { type ViewsEnumDeprecate } from '../enums/viewsEnumDeprecate';
import type BreadcrumbModel from '../models/BreadcrumbModel';
import { type TLocales } from '../models/TLocales';

interface AppSliceState {
  appName: string;
  locale?: TLocales;
  localeDict?: Record<string, string>;
  activeView: {
    type?: ViewsEnumDeprecate;
  };
  activeAction: {
    type?: ActionsEnumDeprecate;
  };
  breadcrumbs: BreadcrumbModel[];
  breadcrumbReplacementTextDict: Record<string, string>;
  breadcrumbReplacementTextDictTimestamp: number;
  dashboardOutletDimensions: {
    h: number;
    w: number;
    left: number;
  };
  headerDimensions: { h: number; w: number };
  sidebar: {
    isExpanded: boolean;
    dim: {
      w: number;
    };
  };
  snackbar: {
    isVisible: boolean;
    message?: string;
    autoHideDuration: number;
    severity: AlertColor;
  };
  title: string;
  processLoading: number;
  isTouchscreen: boolean;
}

const initialState: AppSliceState = {
  appName: 'neXDos',
  locale: undefined,
  localeDict: undefined,
  activeView: {
    type: undefined
  },
  activeAction: {
    type: undefined
  },
  breadcrumbs: [],
  breadcrumbReplacementTextDict: {},
  breadcrumbReplacementTextDictTimestamp: 0,
  dashboardOutletDimensions: {
    h: 0,
    w: 0,
    left: 0
  },
  headerDimensions: { h: 0, w: 0 },
  sidebar: {
    isExpanded: window.localStorage.getItem('sidebar-toggle')
      ? window.localStorage.getItem('sidebar-toggle') === 'true'
      : true,
    dim: {
      w: 0
    }
  },
  snackbar: {
    isVisible: false,
    message: undefined,
    autoHideDuration: 7000,
    severity: 'info'
  },
  title: '',
  processLoading: 0,
  isTouchscreen: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateActiveView: (state, action) => {
      state.activeView.type = action.payload as ViewsEnumDeprecate | undefined;
    },
    updateActiveAction: (state, action) => {
      state.activeAction.type = action.payload as ActionsEnumDeprecate | undefined;
    },
    updateDashboardDim: (state, action: PayloadAction<Partial<{ w: number; h: number; left: number }>>) => {
      if (action.payload.h) state.dashboardOutletDimensions.h = action.payload.h;
      if (action.payload.w) state.dashboardOutletDimensions.w = action.payload.w;
      if (action.payload.left) state.dashboardOutletDimensions.left = action.payload.left;
    },
    updateHeaderDim: (state, action: PayloadAction<Partial<{ h: number; w: number }>>) => {
      if (action.payload.h) state.headerDimensions.h = action.payload.h;
      if (action.payload.w) state.headerDimensions.w = action.payload.w;
    },
    updateSidebarDim: (state, action: PayloadAction<Partial<{ w: number }>>) => {
      if (action.payload.w !== undefined) state.sidebar.dim.w = action.payload.w;
    },
    toggleSidebar: (state) => {
      state.sidebar.isExpanded = !state.sidebar.isExpanded;
      window.localStorage.setItem('sidebar-toggle', state.sidebar.isExpanded.toString());
    },
    updatePageTitle: (state, action) => {
      // TO DEPRECATE SINCE FUTURE PAGE RENAME WILL OCCUR VIA PageModule
      if (action.payload) {
        state.title = (action.payload as string) + ' - ' + state.appName;
      } else {
        state.title = state.appName;
      }
      // document.title = state.title;
    },
    addBreadcrumbTextReplacement: (state, action) => {
      const pair = action.payload as [string, string];
      state.breadcrumbReplacementTextDict[pair[0]] = pair[1].replace(/(<([^>]+)>)/gi, '');
      state.breadcrumbReplacementTextDictTimestamp = new Date().getTime();
    },
    purgeBreadcrumbTextReplacementDictForOutlets: () => {
      // Might be redundant
      // state.breadcrumbReplacementTextDict = {};
    },
    updateProcessLoading: (state, action) => {
      state.processLoading += action.payload ? 1 : -1;
    },
    updateSnackbar: (
      state,
      action: PayloadAction<{
        message?: string;
        autoHideDuration?: number;
        severity?: 'error' | 'info' | 'success' | 'warning';
        isVisible?: boolean;
      }>
    ) => {
      state.snackbar = { ...state.snackbar, ...action.payload };
      state.snackbar.isVisible = !!action.payload.message;
    },
    updateIsTouchscreen: (state, action: PayloadAction<{ isTouchscreen: boolean }>) => {
      state.isTouchscreen = action.payload.isTouchscreen;
    },
    updateLocale: (state, action: PayloadAction<{ locale: TLocales }>) => {
      state.locale = action.payload.locale;
      window.localStorage.setItem('locale', action.payload.locale);
      document.documentElement.setAttribute('lang', action.payload.locale);
    },
    updateLocaleDict: (state, action: PayloadAction<{ localeDict: Record<string, string> }>) => {
      state.localeDict = action.payload.localeDict;
    }
  }
});

export const {
  updateActiveView,
  updateActiveAction,
  updateDashboardDim,
  toggleSidebar,
  updatePageTitle,
  addBreadcrumbTextReplacement,
  purgeBreadcrumbTextReplacementDictForOutlets,
  updateProcessLoading,
  updateSnackbar,
  updateIsTouchscreen,
  updateHeaderDim,
  updateSidebarDim,
  updateLocale,
  updateLocaleDict
} = appSlice.actions;

export default appSlice.reducer;
