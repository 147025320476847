import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CSectionNavigator from '../../components/CSectionNavigator';
import CStatefulContainer from '../../components/CStatefulContainer';
import GenericDateRangeSelector from '../../components/GenericDateRangeSelector';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type ITableOrPlotState } from '../../models/ITableOrPlotState';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import { type TPandasTable } from '../../models/TPandasTable';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  plot: ITableOrPlotState<PlotlyResponse>;
  table: ITableOrPlotState<TPandasTable>;
}

export default function PPerformance() {
  const defaultStartDate = new Date('2024-05-22').getTime();
  const defaultEndDate = new Date().getTime();

  const { factsheetId } = useParams();
  const { locale } = useAppSelector((state) => state.dashboard);

  const [state, setState, getState] = useStateExtended<State>({
    plot: {
      state: {
        isLoading: true,
        isError: false
      },
      params: {},
      response: undefined
    },
    table: {
      state: {
        isLoading: true,
        isError: false
      },
      params: {},
      response: undefined
    }
  });

  const sectionNavigator = (
    <CSectionNavigator
      previous={{ label: <CLocalizedText dictKey={'globalSectionFactsheetOverview'} />, url: '..' }}
      next={{
        label: <CLocalizedText dictKey={'globalFactsheetSectionFacex'} />,
        url: `../${PageRoutes.PUBLIC_FACTSHEET_FACTOR_EXPOSURE}`
      }}
    />
  );

  const fetchPlot = async (startDate: string, endDate: string) => {
    const latestState = (await getState()).plot;
    updateState<State>(
      {
        plot: {
          ...latestState,
          state: { ...latestState.state, isLoading: true }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getPerformancePlot(factsheetId ?? '', startDate, endDate, locale);
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          plot: {
            ...latestState,
            state: { isLoading: false, isError: false },
            response: res.getData()
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          plot: {
            ...latestState,
            state: { isLoading: false, isError: true, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  const fetchTable = async (startDate: string, endDate: string) => {
    const latestState = (await getState()).table;
    updateState<State>(
      {
        table: {
          ...latestState,
          state: { ...latestState.state, isLoading: true }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getPerformanceTable(factsheetId ?? '', startDate, endDate, locale);
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          table: {
            ...latestState,
            state: { isLoading: false, isError: false },
            response: res.getData()
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          table: {
            ...latestState,
            state: { isLoading: false, isError: true, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  const handleDateRangeChange = async (startDate: number, endDate: number) => {
    const startDateString = dayjs(startDate).format('YYYY-MM-DD');
    const endDateString = dayjs(endDate).format('YYYY-MM-DD');
    await fetchPlot(startDateString, endDateString);
    await fetchTable(startDateString, endDateString);
  };

  useEffect(() => {
    (async () => {
      await handleDateRangeChange(defaultStartDate, defaultEndDate);
    })();
  }, [locale]);

  return (
    <Page dictKey={'fsovP2Performance'}>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h2>
            <CLocalizedText dictKey={'fsperfP1H1'} />
          </h2>
          <p>
            <CLocalizedText dictKey={'fsperfP1Desc1'} />
          </p>
        </CPageModule>
        <CPageModule submodule>
          <div className={'flex justify-end'}>
            <GenericDateRangeSelector
              onChange={handleDateRangeChange}
              initStartDate={defaultStartDate}
              initEndDate={defaultEndDate}
              minDate={defaultStartDate}
              maxDate={defaultEndDate}
              minDateSpan={10}
            />
          </div>
        </CPageModule>
        <CStatefulContainer submodule showContent={!!state.plot.response} apiRequestState={state.plot.state}>
          <CPageModulePlot data={state.plot.response?.data} layout={state.plot.response?.layout} />
        </CStatefulContainer>
        <CPageModule submodule>
          <p>
            <CLocalizedText dictKey={'fsperfP1Desc2'} />
          </p>
        </CPageModule>
        <CStatefulContainer submodule showContent={!!state.table.response} apiRequestState={state.table.state}>
          <CHorizontalScrollContainer>
            <CPandasTable data={state.table.response} colSpanArr={[1, 1, 1, 1, 1, 1]} />
          </CHorizontalScrollContainer>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}
