import { EyeSlashIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Add, Person, Visibility } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import BigListButtonLink from '../../../components/BigListButtonLink';
import CStatefulContainer from '../../../components/CStatefulContainer';
import CompanyInfoDetail from '../../../components/CompanyInfoDetail';
import XDButton from '../../../components/buttons/XDButton';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import { PageRoutes } from '../../../enums/enums';
import { updateState, useStateExtended } from '../../../helpers/helper';
import { type IRequestState } from '../../../models/IRequestState';
import { type FactsheetAdminSummary } from '../../../models/publicFactsheetModel';
import PublicFactsheetServices from '../../../services/publicFactsheetServices';

interface State {
  reqState: IRequestState;
  factsheetList: FactsheetAdminSummary[];
}

export default function AllFactsheetLinksIndex() {
  const [state, setState] = useStateExtended<State>({
    reqState: { isLoading: true, isError: false },
    factsheetList: []
  });

  useEffect(() => {
    (async () => {
      updateState<State>({ reqState: { isError: false, isLoading: true } }, state, setState);
      const res = await PublicFactsheetServices.getFactsheetAdminList();
      updateState<State>(
        { reqState: { isError: res.hasError(), isLoading: false, strError: res.getErrorString() } },
        state,
        setState
      );
      if (!res.hasError()) {
        updateState<State>({ factsheetList: res.getData().factsheets }, state, setState);
      }
    })();
  }, []);

  return (
    <Page title={'Links'}>
      <PageModule>
        <PageModuleTitle
          toolbar={
            <RouterLink
              to={`/${PageRoutes.PUBLISHING_OUTLET}/${PageRoutes.PUBLISHING_FS_OUTLET}/${PageRoutes.PUBLISHING_FS_NEW_LINK}`}
            >
              <XDButton>
                <Add />
                <span>New Link</span>
              </XDButton>
            </RouterLink>
          }
        >
          All Factsheet Links
        </PageModuleTitle>
        <CStatefulContainer apiRequestState={state.reqState}>
          <XDResponsiveGridWrapper type={'doubleList'}>
            {state.factsheetList.map((e, ix) => (
              <FactsheetSummaryButton key={e.id} order={ix} factsheetInfo={e} />
            ))}
          </XDResponsiveGridWrapper>
        </CStatefulContainer>
      </PageModule>
    </Page>
  );
}

function FactsheetSummaryButton(props: { factsheetInfo: FactsheetAdminSummary; order: number }) {
  const formattedName = [props.factsheetInfo.name];
  if (props.factsheetInfo.name !== props.factsheetInfo.id) {
    formattedName.push(`(${props.factsheetInfo.id})`);
  }
  return (
    <BigListButtonLink
      order={props.order}
      to={`${props.factsheetInfo.id}`}
      icon={props.factsheetInfo.enabled ? <LinkIcon /> : <EyeSlashIcon />}
      title={formattedName.join(' ')}
    >
      <div className={'flex gap-x-4 gap-y-1 mt-2 flex-wrap'}>
        <CompanyInfoDetail icon={<Person />} desc={props.factsheetInfo.created_by} />
        <CompanyInfoDetail icon={<Visibility />} desc={(props.factsheetInfo.global_view_count ?? 0).toString()} />
      </div>
    </BigListButtonLink>
  );
}
