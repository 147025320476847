import { Refresh } from '@mui/icons-material';
import { Button, CircularProgress, type SxProps } from '@mui/material';
import React from 'react';

import { type IRequestState } from '../../models/IRequestState';

interface BorderedButtonProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  trailingIcon?: boolean;
  state?: IRequestState;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  growOnMobile?: boolean;
  sxColor?: string;
  sxColor80Pct?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'text' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  sx?: SxProps;
}

/**
 * @deprecated
 * @param children
 * @param icon
 * @param className
 * @param onClick
 * @param trailingIcon
 * @param state
 * @param growOnMobile
 * @param sxColor
 * @param sxColor80Pct
 * @param disabled
 * @param color
 * @param variant
 * @param sx
 * @constructor
 */
export default function ButtonXd({
  children,
  icon,
  className,
  onClick,
  trailingIcon,
  state,
  growOnMobile,
  sxColor,
  sxColor80Pct,
  disabled,
  color,
  variant,
  sx
}: BorderedButtonProps) {
  const classNames = ['flex-none w-full'];

  if (growOnMobile) {
    classNames.push('md:w-auto');
  }

  if (className) {
    classNames.push(className);
  }

  let receivedState = { ...state };

  if (!receivedState) {
    receivedState = { isLoading: false, isError: false };
  }

  if (receivedState.isLoading) {
    classNames.push('pointer-events-none');
  }

  const iconSelection = (
    <>
      {receivedState.isLoading && (
        <div className={'mt-1 leading-3 px-0.5'}>
          <CircularProgress size={'1rem'} color={'inherit'} className={'leading-3'} />
        </div>
      )}
      {!receivedState.isLoading && !receivedState.isError && icon}
      {!receivedState.isLoading && receivedState.isError && <Refresh />}
    </>
  );

  const iconElement = <span className={'text-xl -mt-1'}>{iconSelection}</span>;

  return (
    <Button
      sx={{
        paddingX: children ? '1.5rem' : '1.2rem',
        paddingY: '0.75rem',
        minWidth: '0',
        color: sxColor ?? 'auto',
        borderColor: sxColor80Pct ?? 'auto',
        ':hover': {
          borderColor: sxColor ?? 'auto'
        },
        ...sx
      }}
      disabled={disabled}
      disableRipple
      onClick={(ev) => {
        onClick?.(ev);
      }}
      color={color}
      variant={variant ?? 'outlined'}
      className={classNames.join(' ')}
    >
      <div className={'flex gap-0 items-center'}>
        {!trailingIcon && iconElement}
        {children && <span className={'py-[0.2rem] px-2 font-bold'}>{children}</span>}
        {trailingIcon && iconElement}
      </div>
    </Button>
  );
}
