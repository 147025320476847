import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { PageRoutes } from '../../enums/enums';
import { useAppDispatch } from '../../hooks/hooks';
import { addBreadcrumbTextReplacement, purgeBreadcrumbTextReplacementDictForOutlets } from '../../store/appSlice';

export default function BacktestOutlet() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([PageRoutes.BACKTEST_OUTLET, 'Backtests']));
    return () => {
      dispatch(purgeBreadcrumbTextReplacementDictForOutlets());
    };
  }, []);

  return <Outlet />;
}
