import React from 'react';
import BacktestStatisticsModule from '../backtest/BacktestStatModule';

interface statProps {
  heading: string;
  dict: Record<string, string | number | boolean | ''> | undefined;
}

export default function RowStatsItem({ heading, dict }: statProps) {
  return (
      <BacktestStatisticsModule lvl1Key={heading} lvl2Dict={dict}/>
    // <div className={'flex flex-col gap-2'}>
    //   {
    //     heading &&
    //     <p className={'font-semibold uppercase text-teal-1'}>
    //       {heading.replaceAll('_', ' ')}
    //     </p>
    //   }
    //   {Object.keys(dict ?? {}).map((key) => (
    //     <div key={key} className={'flex gap-x-4 gap-y-0 border-b items-end flex-wrap'}>
    //       <span className={'flex-grow flex-shrink-0 capitalize'}>
    //         {key.replaceAll('_', ' ').replaceAll('sql', 'SQL')}
    //       </span>
    //       <span className={'text-right flex-grow'}>
    //         <strong>
    //           {dict?.[key].toString().replaceAll('_', ' ')}
    //         </strong>
    //       </span>
    //     </div>
    //   ))}
    // </div>
  );
}