import React, { useState } from 'react';
import LoadWheel from './general/LoadWheel';

export default function LoadAndRefresh() {
  const [showText, setShowText] = useState<boolean>(false);

  setTimeout(() => {
    setShowText(true);
  }, 0);

  setTimeout(() => {
    window.location.reload();
  }, 3000);


  return (
    <div className={'w-[100vw] h-[100vh] flex flex-col justify-center items-center fixed top-0 left-0'}>
      {
        showText && <>
          <LoadWheel/>
          <p>Validating your session</p>
          <div className={'h-[10%]'}/>
        </>
      }
    </div>
  );
}