import dayjs, { type Dayjs } from 'dayjs';
import React, { useState } from 'react';

import { updateState } from '../helpers/helper';
import CLocalizedText from './CLocalizedText';
import CustomDatePicker from './CustomDatePicker';

interface GenericDateRangeSelectorProps {
  minDate?: number;
  maxDate?: number;
  initStartDate: number | undefined;
  initEndDate: number | undefined;
  onChangeDeprecate?: (obj: Partial<Record<'start' | 'end', number>>) => void;
  onChange?: (startUnix: number, endUnix: number) => void;
  onValidationErrorDeprecate?: (hasErrors: boolean) => void;
  limitWidth?: boolean;
  allowSameDate?: true;
  minDateSpan?: number;
}

interface State {
  errorMessage?: string;
  startDate: Dayjs | undefined;
  endDate: Dayjs | undefined;
}

/***
 *
 * @param minDate min date as UNIX timestamp in milliseconds
 * @param maxDate max date as UNIX timestamp in milliseconds
 * @param start UNIX timestamp in milliseconds
 * @param end UNIX timestamp in milliseconds
 * @param onDateRangeChange callback when date range changes
 * @param onValidationError callback when a validation error is found/resolved
 * @param limitWidth boolean to limit the width
 * @constructor
 */
export default function GenericDateRangeSelector({
  minDate,
  maxDate,
  initStartDate,
  initEndDate,
  onChangeDeprecate,
  onChange,
  onValidationErrorDeprecate,
  limitWidth,
  allowSameDate,
  minDateSpan
}: GenericDateRangeSelectorProps) {
  const classNames = ['w-full'];

  if (limitWidth === undefined || limitWidth) {
    classNames.push('sm:w-44');
  }

  const [state, setState] = useState<State>({ startDate: dayjs(initStartDate), endDate: dayjs(initEndDate) });
  const { errorMessage } = state;

  const validateDate = (startDate: number | undefined, endDate: number | undefined) => {
    let errorMessage: string | undefined;

    if (minDateSpan && dayjs(endDate).diff(startDate, 'day') <= minDateSpan - 1) {
      errorMessage = (<CLocalizedText dictKey={'errorDateSpanLessThan10'} />) as unknown as string;
    }

    if (dayjs(endDate).isBefore(startDate, 'day')) {
      errorMessage = (<CLocalizedText dictKey={'errorEndDateBeforeStart'} />) as unknown as string;
    }

    if (errorMessage) {
      updateState<State>({ errorMessage }, state, setState);
      return false;
    } else {
      updateState<State>({ errorMessage: '' }, state, setState);
      return true;
    }
  };

  const minDateDayjs = minDate ? dayjs(minDate) : undefined;
  const maxDateDayjs = maxDate ? dayjs(maxDate) : undefined;

  const handleChange = (isStartDate: boolean, newValue: Dayjs) => {
    let startDate = state.startDate;
    let endDate = state.endDate;
    if (isStartDate) {
      startDate = newValue;
    } else {
      endDate = newValue;
    }
    if (!startDate || !endDate) return;
    updateState<State>({ startDate, endDate }, state, setState);
    const startUnix = startDate.unix() * 1000;
    const endUnix = endDate.unix() * 1000;
    if (!validateDate(startUnix, endUnix)) return;
    if (onChange && startDate && endDate) onChange(startUnix, endUnix);
  };

  return (
    <div className={'flex gap-4 gap-y-2 flex-wrap flex-grow sm:flex-grow-0'}>
      <div className={'w-full sm:w-[250px]'}>
        <CustomDatePicker
          label={(<CLocalizedText dictKey={'fieldStartDate'} />) as unknown as string}
          value={state.startDate}
          minDate={minDateDayjs}
          maxDate={maxDateDayjs}
          className={classNames.join(' ')}
          onChange={(e) => {
            if (onChangeDeprecate) onChangeDeprecate({ start: dayjs(e).unix() * 1000 });
            handleChange(true, e);
          }}
        />
      </div>
      <div className={'w-full sm:w-[250px]'}>
        <CustomDatePicker
          label={(<CLocalizedText dictKey={'fieldEndDate'} />) as unknown as string}
          value={state.endDate}
          minDate={minDateDayjs}
          maxDate={maxDateDayjs}
          isError={!!errorMessage}
          helperText={errorMessage}
          className={classNames.join(' ')}
          onChange={(e) => {
            if (onChangeDeprecate) onChangeDeprecate({ end: dayjs(e).unix() * 1000 });
            handleChange(false, e);
          }}
        />
      </div>
    </div>
  );
}
