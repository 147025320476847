import React, { useEffect } from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {
  addBreadcrumbTextReplacement,
} from '../../../store/appSlice';
import {Outlet, useNavigate} from 'react-router-dom';
import {PageRoutes} from '../../../enums/enums';

export default function UserHubOutlet() {
  const {email, lastName, firstName} = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate('..');
      return;
    }
    dispatch(addBreadcrumbTextReplacement([PageRoutes.USERS_USER_HUB_OUTLET, `${firstName} ${lastName}`]));
  }, [email, lastName, firstName]);

  useEffect(() => {
    if (!email) {
      navigate('..');
    }
  }, [dispatch]);

  // NOTE: FIGURE OUT A WAY TO dispatch(updateUserObject()) once it is no longer needed

  return <Outlet />;
}
