import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '../../hooks/hooks';
import I18nButton from '../I18nButton';

interface mobileMenuProps {
  isMenuExpanded: boolean;
  onClose: () => void;
  mobileNavItems?: JSX.Element;
}

interface State {
  menuContentScrollBehavior?: 'auto' | 'hidden';
}

export default function MobileMenu({ isMenuExpanded, onClose, mobileNavItems }: mobileMenuProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { dashboard } = useAppSelector((state) => state);

  const [state, setState] = useState<State>({});
  const { menuContentScrollBehavior } = state;
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };

  useEffect(() => {
    if (isMenuExpanded) {
      setTimeout(() => {
        updateState({ menuContentScrollBehavior: 'auto' });
      }, 300);
    }
  }, [isMenuExpanded]);

  const handleClose = (ev: MouseEvent) => {
    const targetNode = ev.target as unknown as any;
    if ((ref.current?.contains(targetNode) as boolean) || !ref.current?.parentNode?.contains(targetNode)) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => {
      window.removeEventListener('click', handleClose);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={'lg:hidden bg-teal-2 fixed flex ease-in-out duration-300 text-white shadow-lg w-full overflow-hidden'}
      style={{
        height: isMenuExpanded ? `${dashboard.dashboardOutletDimensions.h}px` : 0,
        left: 0,
        top: `${window.innerHeight - dashboard.dashboardOutletDimensions.h}px`,
        opacity: isMenuExpanded ? 1 : 0,
        transitionProperty: isMenuExpanded ? 'height' : 'none'
      }}
    >
      <div
        className={'flex flex-col w-full pb-8 px-2 text-2xl overflow-x-hidden'}
        style={{
          overflowY: !isMenuExpanded ? 'hidden' : menuContentScrollBehavior,
          height: isMenuExpanded ? `${dashboard.dashboardOutletDimensions.h}px` : 0
        }}
      >
        <div className={'text-white py-2'}>
          <I18nButton />
        </div>
        {mobileNavItems}
      </div>
    </div>
  );
}
