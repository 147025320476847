import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  type AccordionProps,
  AccordionSummary,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export interface CustomAccordionProps extends Partial<AccordionProps> {
  header: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
}

export default function CustomAccordion(props: CustomAccordionProps) {
  const [isExpanded, setIsExpanded] = useState(props.expanded);

  return (
    <Accordion
      disableGutters
      elevation={0}
      className={props.className ?? '' + ' border'}
      expanded={isExpanded}
      sx={{
        ...props.sx,
        '&.MuiButtonBase-root': {
          borderWidth: 0,
        },
        '&.MuiAccordion-root': {
          boxShadow: 'none',
          borderRadius: '0.5rem',
        },
        '&.MuiAccordion-root::before': {
          opacity: 0,
        },
      }}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='panel1bh-content'
        id='panel1bh-header'
      >
        {props.header}
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}
