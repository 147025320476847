import axios, { type AxiosError, type AxiosResponse } from 'axios';

import type ErrorResponse from '../models/ErrorResponse';

export class ApiResponse<T> {
  /**
   *
   */
  constructor(public response: AxiosResponse<T> | ErrorResponse) {}

  public getStatus() {
    return (this.response as unknown as AxiosError).response?.status ?? 0;
  }

  public wasSuccessful() {
    return this.getStatus() < 300;
  }

  public hasError() {
    return this.getStatus() >= 300;
  }

  public getErrorDeprecate() {
    return (this.response as AxiosResponse<ErrorResponse>).data;
  }

  public getErrorString() {
    const status = this.getStatus();
    if (status >= 300) {
      const errorString =
        ((this.response as unknown as AxiosError).response?.data as { error: string }).error ??
        (this.response as unknown as AxiosError).response?.data ??
        'A server error occurred';
      if (typeof errorString === 'object') {
        return '';
      }
      if (errorString.includes('html>')) return 'Failed to load';
      switch (status) {
        case 401:
          return 'You are not not logged in';
        case 403:
          return 'You are not authorized to access this resource';
        case 504:
          return 'The server is down at the moment, please try again later';
        default:
          return errorString;
      }
    } else {
      return undefined;
    }
  }

  public getErrorObj() {
    if (this.getStatus() >= 300) {
      const errorString =
        ((this.response as unknown as AxiosError).response?.data as { error: string }).error ??
        (this.response as unknown as AxiosError).response?.data ??
        'An unknown error occurred';
      if (typeof errorString === 'object') {
        return errorString;
      } else {
        return {};
      }
    }
  }

  public getData() {
    return (this.response as AxiosResponse<T>).data;
  }
}

/**
 * @deprecated This class is deprecated. Use `APIService` class instead.
 */
export default abstract class BaseApiService {
  private static readonly baseUri = '/api';

  protected static async get<T extends object>(uri: string, params: Record<string, any> = {}, options = {}) {
    let paramString = '';
    if (Object.keys(params).length > 0) {
      paramString += `?${new URLSearchParams(params).toString()}`;
    }
    return new ApiResponse<T>(await axios.get<T>(`${this.baseUri}/${uri}${paramString}`, options));
  }

  protected static async put<T>(uri: string, param: any) {
    return new ApiResponse<T>(await axios.put<T>(`${this.baseUri}/${uri}`, param));
  }

  protected static async post<T>(uri: string, param: any) {
    return new ApiResponse<T>(await axios.post<T>(`${this.baseUri}/${uri}`, param));
  }

  protected static async delete<T>(uri: string) {
    return new ApiResponse<T>(await axios.delete<T>(`${this.baseUri}/${uri}`));
  }
}
