import React from 'react';
import XDEntryCard from './buttons/XDEntryCard';

export interface BigListButtonProps {
  icon?: JSX.Element;
  title?: string | JSX.Element;
  children?: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  inlineChildren?: boolean;
  order: number;
}

export default function BigListButton(props: BigListButtonProps) {
  // const buttonClass = ['flex flex-col gap-1 p-4 rounded-xl border relative text-gray-700 items-start overflow-hidden'];
  //
  // if (props.disabled) {
  //   buttonClass.push('opacity-50 cursor-not-allowed');
  // } else {
  //   buttonClass.push('text-teal-2 hover:text-teal-1 hover:bg-teal-1 hover:bg-opacity-5 cursor-pointer');
  // }

  const clickHandler = () => {
    if (props.onClick != null && !props.disabled) {
      props.onClick();
    }
  };

  const childrenElement = () => {
    return (<div className={'text-gray-700'}>{props.children}</div>);
  };

  return (
    <XDEntryCard onClick={clickHandler} order={props.order}>
      <div className={'flex gap-4 items-start'}>
        {
          props.icon &&
          <div className={'mt-1 h-6 aspect-square overflow-hidden'}>
            {props.icon}
          </div>
        }
        <div className={'flex flex-col gap-0'}>
            {
              props.title &&
              <h3>{props.title}</h3>
            }
            {
              !props.inlineChildren && childrenElement()
            }
        </div>
      </div>
      {props.inlineChildren && childrenElement()}
    </XDEntryCard>
  );
}