import { ArrowDropDown } from '@mui/icons-material';
import React, { useState } from 'react';

import { updateState } from '../helpers/helper';
import CLocalizedText from './CLocalizedText';
import XDButton from './buttons/XDButton';
import XDDropdown from './selection/XDDropdown';
import XDDropdownItem from './selection/XDDropdownItem';

interface ISortDropdown {
  onChange: (selectedKey: string) => void;
  options: Record<string, string | JSX.Element>;
  defaultKey: string | undefined;
  labelDesc?: string;
  reverseDictOrder?: boolean;
}

interface State {
  activeIndex: number;
}

export default function CSortDropdown(props: ISortDropdown) {
  const [state, setState] = useState<State>({
    activeIndex:
      Object.keys(props.options)
        .sort((a, b) => {
          if (props.reverseDictOrder) {
            return a > b ? -1 : 0;
          } else {
            return 0;
          }
        })
        .indexOf(props.defaultKey ?? '') ?? 0
  });

  const handleChange = (selectedIndex: number) => {
    updateState<State>({ activeIndex: selectedIndex }, state, setState);
    props.onChange(
      Object.keys(props.options).sort((a, b) => {
        if (props.reverseDictOrder) {
          return a > b ? -1 : 0;
        } else {
          return 0;
        }
      })[selectedIndex]
    );
  };

  return (
    <div className={'flex items-center -mr-2 flex-nowrap'}>
      <p className={'text-sm -mt-0.5 whitespace-nowrap'}>
        {props.labelDesc ?? <CLocalizedText dictKey={'globalSortBy'} />}
      </p>
      <XDDropdown
        topShift={'3rem'}
        closeOnAnyClick
        button={
          <XDButton propagateClick className={'text-sm whitespace-nowrap'}>
            <b>
              {
                Object.values(props.options).sort((a, b) => {
                  if (props.reverseDictOrder) {
                    return a > b ? -1 : 0;
                  } else {
                    return 0;
                  }
                })[state.activeIndex]
              }
            </b>
            <ArrowDropDown />
          </XDButton>
        }
      >
        {Object.keys(props.options)
          .sort((a, b) => {
            if (props.reverseDictOrder) {
              return a > b ? -1 : 0;
            } else {
              return 0;
            }
          })
          .map((e, ix) => (
            <XDDropdownItem
              key={e}
              onClick={() => {
                handleChange(ix);
              }}
              isActive={ix === state.activeIndex}
            >
              {props.options[e]}
            </XDDropdownItem>
          ))}
      </XDDropdown>
    </div>
  );
}
