import React from 'react';
import LoadWheel from './LoadWheel';

interface appBlockScreenProps {
  isVisible: boolean;
}

export default function GlobalLoaderMask({ isVisible }: appBlockScreenProps) {
  return (
    <div
      className={
        'fixed top-0 left-0 h-full w-full z-[1500] bg-white flex justify-center items-center ' +
        `${
          isVisible
            ? 'bg-opacity-80 transition-bg-opacity duration-1000 '
            : 'bg-opacity-0 pointer-events-none cursor-wait'
        }`
      }
    >
      {isVisible && <LoadWheel />}
    </div>
  );
}
