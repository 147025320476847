import axios from 'axios';

import { type TLocales } from '../models/TLocales';
import ApiService from './apiService';

export default class I18nService extends ApiService {
  static async getLocaleDict(locale: TLocales) {
    const res = await axios.get(`/${locale}.json`);
    return res.data;
  }
}
