import React from 'react';

interface pageModuleTitleProps {
  children: string | React.ReactNode;
  level?: 'h1' | 'h2' | 'h3' | 'h4';
  desc?: string;
  subtitle?: string;
  highlight?: boolean;
  standardCase?: boolean;
  flipOrder?: boolean;
  toolbar?: JSX.Element;
}

/**
 * @deprecated
 * @param children
 * @param subtitle
 * @param desc
 * @param level
 * @param highlight
 * @param standardCase
 * @param toolbar
 * @param flipOrder
 * @constructor
 */
export default function PageModuleTitle({
  children,
  subtitle,
  desc,
  level,
  highlight,
  standardCase,
  toolbar,
  flipOrder
}: pageModuleTitleProps) {
  const titleClassNames: string[] = [];
  const subtitleElement = <p className={`${standardCase ? 'text-md ' : 'text-md font-[600] uppercase'}`}>{subtitle}</p>;

  if (highlight) {
    titleClassNames.push('text-teal-1');
  }

  let element: React.ReactNode;

  if (typeof children === 'string') {
    if (subtitle) {
      titleClassNames.push('text-teal-1');
    }

    switch (level) {
      case 'h2':
        element = <h2 className={titleClassNames.join(' ')}>{children}</h2>;
        break;
      case 'h3':
        element = <h3 className={titleClassNames.join(' ')}>{children}</h3>;
        break;
      case 'h4':
        element = <h4 className={titleClassNames.join(' ')}>{children}</h4>;
        break;
      case 'h1':
      default:
        element = <h1 className={titleClassNames.join(' ')}>{children}</h1>;
        break;
    }
  } else {
    element = children;
  }

  return (
    <div className={'flex gap-x-4 gap-y-7 flex-wrap items-center'}>
      {children && (
        <div className={'flex flex-col flex-grow'}>
          {subtitle && !flipOrder && subtitleElement}
          {children && children !== '' && element}
          {subtitle && flipOrder && subtitleElement}
          {desc && <p>{desc}</p>}
        </div>
      )}
      {toolbar && <div className={'-mt-1'}>{toolbar}</div>}
    </div>
  );
}
