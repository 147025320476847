import { Close } from '@mui/icons-material';
import { Modal, type ModalProps } from '@mui/material';
import React, { type CSSProperties } from 'react';

import HGeneric from '../helpers/HGeneric';
import XDFloatIn from './animate/XDFloatIn';
import XDButton from './buttons/XDButton';

interface bareModalProps extends Pick<ModalProps, 'open'> {
  onClose: () => void;
  children?: React.ReactNode;
  style?: CSSProperties;
}

export default function CModal(props: bareModalProps) {
  return (
    <Modal
      open={props.open}
      onClick={props.onClose}
      style={{ outline: 0 }}
      disableAutoFocus
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }
      }}
    >
      <div
        className={'w-full h-full flex flex-col justify-center items-center p-4 bg-black bg-opacity-20 backdrop-blur'}
      >
        <XDFloatIn>
          <div className={'overflow-hidden rounded-xl'}>
            <div
              className={
                'bg-white shadow-lg max-h-[calc(100vh-8rem)] lg:max-h-[calc(100vh-10rem)] overflow-y-auto relative'
              }
              style={{
                maxWidth: window.innerWidth - 2 * HGeneric.getRemInPx()
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={'sticky top-0 z-50'}>
                <div className={'p-4 sm:p-8 pb-4'}>
                  <div className={'flex gap-2 flex-nowrap items-center h-full justify-end'}>
                    <XDButton
                      className={'bg-gray-100 text-2xl bg-opacity-20 backdrop-blur'}
                      style={{ borderRadius: '100%' }}
                      onClick={props.onClose}
                    >
                      <Close />
                    </XDButton>
                  </div>
                </div>
              </div>
              <div className={'p-4 sm:p-8 pt-0 sm:pt-0'} style={{ ...props.style }}>
                {props.children}
              </div>
            </div>
          </div>
        </XDFloatIn>
      </div>
    </Modal>
  );
}
