import React from 'react';

import XDButton from '../buttons/XDButton';

interface props {
  children: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
  className?: string;
}

export default function XDDropdownItem(props: props) {
  const classNames = [
    'hover:text-teal hover:bg-teal hover:bg-opacity-10 whitespace-nowrap text-left text-sm flex gap-2'
  ];

  if (!props.isActive) {
    classNames.push('font-normal');
  } else {
    classNames.push('font-bold text-teal-ppt-1 hover:text-teal');
  }

  if (props.className) classNames.push(props.className);

  return (
    <XDButton propagateClick onClick={props.onClick} className={classNames.join(' ')}>
      {props.children}
    </XDButton>
  );
}
