import React from 'react';
import { Link } from 'react-router-dom';

import CLocalizedText from '../CLocalizedText';

export default function PageFooter() {
  return (
    <div className={'flex-grow flex flex-col justify-end'}>
      <div className={'p-4 text-xs w-full'}>
        <div className={'flex flex-wrap gap-4 gap-y-2 justify-center md:justify-end'}>
          <Link to={'https://www.nexdos.de/impressum/'}>
            <CLocalizedText dictKey={'globalFooterImpressum'} />
          </Link>
          <Link to={'https://www.nexdos.de/privacy-policy/'}>
            <CLocalizedText dictKey={'globalFooterDatenschutzerklaerung'} />
          </Link>
          <span>&copy; {new Date().getFullYear()} neXDos GmbH</span>
        </div>
      </div>
    </div>
  );
}
