import React, { useEffect, useState } from 'react';
import { BrokenImage } from '@mui/icons-material';

interface ImageProps {
  src?: string;
  alt: string;
  className?: string;
  icon?: JSX.Element;
  borderOnFail?: boolean;
  hideOnFail?: boolean;
}

export default function ImageWrapper({
  src,
  alt,
  className,
  borderOnFail,
  hideOnFail,
  icon,
}: ImageProps) {
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);

  useEffect(() => {
    setFailedToLoad(false);
  }, [src]);

  return (
    <>
      {!failedToLoad && src ? (
        <img
          src={src}
          alt={alt}
          className={'object-cover ' + className}
          onError={() => {
            setFailedToLoad(true);
          }}
        />
      ) : (
        <>
          {!hideOnFail && (
            <div
              title={alt}
              className={
                `flex items-center justify-center ${
                  borderOnFail ? 'border' : ''
                } ` + className
              }
            >
              <div className={'opacity-50'}>
                {icon != null ? icon : <BrokenImage />}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
