import React from 'react';
import { Switch } from '@mui/material';

interface props {
  children: string | JSX.Element;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function XDSwitch(props: props) {
  return (
    <label className={'flex gap-2 items-center cursor-pointer'}>
      <Switch checked={props.checked} onChange={props.onChange}/>
      <p>{props.children}</p>
    </label>
  );
}