import { type ButtonProps } from '@mui/material';
import React from 'react';

interface props
  extends Pick<ButtonProps, 'onMouseEnter' | 'onMouseLeave' | 'onClick' | 'style' | 'disabled' | 'className'> {
  children: React.ReactNode;
  propagateClick?: boolean;
}

export default function XDButton(props: props) {
  let classNames = ['rounded-md p-2 flex items-center gap-1 uppercase'];

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (props.propagateClick === false) ev.stopPropagation();
    if (props.disabled) return;
    props.onClick?.(ev);
  };

  if (props.className) {
    classNames.push(props.className);
  }

  if (!props.className?.includes('hover:')) {
    classNames.push('hover:bg-teal hover:text-teal hover:bg-opacity-10');
  }

  if (props.disabled) {
    classNames = classNames
      .join(' ')
      .split(' ')
      .filter((e) => !e.includes('hover:'));
  }

  return (
    <button
      className={classNames.join(' ')}
      style={{
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        opacity: props.disabled ? 0.5 : 1,
        minHeight: '2.5rem',
        hyphens: 'none',
        ...props.style
      }}
      onClick={handleClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </button>
  );
}
