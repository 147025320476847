import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import CModal from '../../components/CModal';
import NotFoundModule from '../../components/PageGates/NotFoundModule';
import { updateState } from '../../helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import PublicFactsheetServices from '../../services/publicFactsheetServices';
import { addBreadcrumbTextReplacement, purgeBreadcrumbTextReplacementDictForOutlets } from '../../store/appSlice';
import PFactsheetTerms from './PFactsheetTerms';

interface State {
  linkIsValid?: boolean;
  termsShown: boolean;
}

export default function PFactsheetOutlet() {
  const { localeDict } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const { factsheetId } = useParams();
  const keycloak = useKeycloak();
  const [state, setState] = useState<State>({
    termsShown:
      // !localStorage.getItem('factsheet-terms-accepted')
      true
  });

  const closeModal = (redirect: boolean) => {
    if (redirect) {
      window.location.href = keycloak.keycloak.authenticated ? '..' : 'https://www.nexdos.de';
      return;
    }
    updateState<State>({ termsShown: false }, state, setState);
  };

  useEffect(() => {
    (async () => {
      const res = await PublicFactsheetServices.getFactsheetCounter(
        factsheetId ?? '',
        keycloak.keycloak.authenticated ? 'user' : 'public'
      );
      updateState<State>({ linkIsValid: !res.hasError() }, state, setState);

      // if (factsheetId) localStorage.setItem('factsheet-id', factsheetId);
    })();

    dispatch(
      addBreadcrumbTextReplacement([factsheetId, localeDict?.globalSectionFactsheet ?? '-- globalSectionFactsheet --'])
    );
    return () => {
      dispatch(purgeBreadcrumbTextReplacementDictForOutlets());
    };
  }, []);

  return (
    <>
      {state.linkIsValid && (
        <>
          <CModal
            onClose={() => {
              closeModal(true);
            }}
            open={state.termsShown}
          >
            <PFactsheetTerms
              onClose={() => {
                closeModal(false);
              }}
            />
          </CModal>
          <Outlet />
        </>
      )}
      {state.linkIsValid === false && <NotFoundModule />}
    </>
  );
}
