import React from 'react';
import type { GhostPostModel } from '../../models/GhostPostModel';
import { Link, useNavigate } from 'react-router-dom';
import ImageWrapper from '../ImageWrapper';
import { PageRoutes } from '../../enums/enums';

interface GhostPostGalleryButtonProps {
  post: GhostPostModel;
  pathPrefix: string;
  onClick?: () => void;
  displayRules?: string;
}

export default function GhostPostGalleryLinkButton({
  post,
  onClick,
  pathPrefix,
  displayRules,
}: GhostPostGalleryButtonProps) {
  const navigate = useNavigate();

  return (
    <Link
      to={`${pathPrefix}${pathPrefix ? '/' : ''}${post.slug}`}
      className={
        'rounded-2xl relative overflow-hidden text-teal-1 ' +
        `relative text-left bg-teal-2 bg-opacity-0 hover:bg-opacity-5 mb-4 ${
          displayRules ?? 'flex'
        }`
      }
      onClick={onClick}
    >
      <div className={'flex-start w-full'}>
        <div className={'m-2 mb-0'}>
          <ImageWrapper
            src={post.feature_image}
            alt={post.feature_image_caption}
            className={'w-full h-full rounded-xl text-2xl aspect-[5/3]'}
            borderOnFail
          />
        </div>
        <div className={'p-4 font-normal'}>
          {post.tags && post.tags.length > 0 && (
            <div className={'text-xs uppercase'}>
              {post.tags.map((e, ix) => {
                return (
                  <span
                    key={e.slug}
                    className={'like-anchor'}
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(
                        `/${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.NEWS_OUTLET}?tag=${e.slug}`
                      );
                    }}
                  >
                    <span>{e.name}</span>
                    {ix !== (post.tags?.length ?? 0) - 1 && <span>, </span>}
                  </span>
                );
              })}
            </div>
          )}
          <h3 className={'text-2xl break-words mt-1 mb-0 text-teal-1'}>
            {post.title}
          </h3>
          {/* <p className={'text-gray-800 mt-2'}>{post.excerpt}</p> */}
          <div className={'text-gray-800 text-sm mt-3'}>
            <p className={'m-0'}>
              By <b>{post.authors.map((x) => x.name).join(', ')}</b>
            </p>
            <p className={'m-0'}>
              {new Date(post.created_at).toLocaleString(undefined, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}{' '}
              {!!post.reading_time && (
                <>
                  &#8729; {post.reading_time} min
                  {post.reading_time ?? 0 > 1 ? 's' : ''} read
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
