import React from 'react';
import Plot, { type PlotParams } from 'react-plotly.js';

export interface CPlotProps extends PlotParams {}

export default function CPlot(props: CPlotProps) {
  return (
    <Plot
      data={props.data}
      layout={{
        ...props.layout,
        paper_bgcolor: '#ffffff',
        plot_bgcolor: '#ffffff',
        margin: {
          t: 0,
          b: 0,
          l: 0,
          r: 0
        }
      }}
      config={{
        ...props.config,
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: ['toImage', 'resetScale2d', 'lasso2d']
      }}
    />
  );
}
