import { UserCircleIcon } from '@heroicons/react/24/outline';
import { Login, Logout } from '@mui/icons-material';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

import CLocalizedText from '../CLocalizedText';
import I18nButton from '../I18nButton';
import XDButton from '../buttons/XDButton';
import XDDropdown from '../selection/XDDropdown';
import XDDropdownItem from '../selection/XDDropdownItem';

export default function LogoutElement() {
  const { keycloak } = useKeycloak();

  return (
    <div className={'flex items-center gap-2'}>
      <div className={'hidden md:block'}>
        <I18nButton />
      </div>
      <XDDropdown
        button={
          <>
            {keycloak.authenticated && (
              <XDButton className={'bg-gray-100 aspect-square font-bold'} style={{ borderRadius: '100%' }}>
                {[keycloak.tokenParsed?.given_name[0], keycloak.tokenParsed?.family_name[0]].filter((e) => e).join('')}
              </XDButton>
            )}
            {!keycloak.authenticated && (
              <XDButton
                className={'bg-gray-100 aspect-square font-bold'}
                style={{ borderRadius: '100%', color: 'rgba(0, 0, 0, 0.6)' }}
              >
                <UserCircleIcon style={{ height: '1.5rem' }} />
              </XDButton>
            )}
          </>
        }
      >
        {keycloak.authenticated && (
          <>
            <XDButton
              className={
                'normal-case hover:bg-opacity-0 hover:text-inherit whitespace-nowrap flex-col items-stretch gap-y-0 text-left'
              }
              style={{ paddingTop: 0, cursor: 'default' }}
            >
              <h6 className={'text-xl'}>
                {[keycloak.tokenParsed?.given_name, keycloak.tokenParsed?.family_name].filter((e) => e).join(' ')}
              </h6>
              <p className={'text-sm'}>{keycloak.tokenParsed?.email}</p>
            </XDButton>
            <XDDropdownItem
              onClick={() => {
                keycloak.logout().then();
              }}
            >
              <Logout />
              <CLocalizedText dictKey={'globalLogOut'} />
            </XDDropdownItem>
          </>
        )}
        {!keycloak.authenticated && (
          <XDDropdownItem
            onClick={() => {
              keycloak.login().then();
            }}
          >
            <Login />
            <CLocalizedText dictKey={'globalLogIn'} />
          </XDDropdownItem>
        )}
      </XDDropdown>
    </div>
  );
}
