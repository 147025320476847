import { ArrowForwardIos } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { updateState } from '../../helpers/helper';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { updatePageTitle } from '../../store/appSlice';

interface BreadcrumbsProps {
  isContentOverflowing: boolean;
}

interface State {
  mergedPath: Array<[string, string]>;
  pathArray: string[];
  prettyPathArray: string[];
}

/**
 * @deprecated
 * @param isContentOverflowing
 * @constructor
 */
export default function AutoBreadcrumbs({ isContentOverflowing }: BreadcrumbsProps) {
  const { sidebar } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathName: string = location.pathname;
  const [state, setState] = useState<State>({
    mergedPath: [],
    pathArray: [],
    prettyPathArray: []
  });
  const upsertState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const replacementTextDict = useAppSelector((state) => state.dashboard.breadcrumbReplacementTextDict);
  const replacementTextDictTS = useAppSelector((state) => state.dashboard.breadcrumbReplacementTextDictTimestamp);
  const dashboardH = useAppSelector((state) => state.dashboard.dashboardOutletDimensions.h);

  const scrollToEnd = () => {
    // This is a very simplistic way, with a timeout, which definitely could be improved in the future
    const element = document.querySelector('#breadcrumbs');
    if (element) {
      setTimeout(() => {
        element.scrollLeft = element.scrollWidth;
      }, 100);
    }
  };

  const computeMergedPath = (pathArr: string[], prettyPathArr: string[]) => {
    const mergedArr = [];
    for (let counter = 0; counter < pathArr.length; counter++) {
      const item: [string, string] = [prettyPathArr[counter], pathArr.slice(0, counter + 1).join('/')];
      if (item[0] !== '') {
        mergedArr.push(item);
      }
    }
    updateState<State>({ mergedPath: mergedArr }, state, setState);
  };

  const computePrettyPath = useCallback(
    (pathArr: string[]) => {
      const tempArr: string[] = [];
      for (const path of pathArr) {
        if (path in replacementTextDict) {
          tempArr.push(replacementTextDict[path]);
        } else {
          tempArr.push('');
        }
      }
      upsertState({ prettyPathArray: tempArr });
      computeMergedPath(pathArr, tempArr);
      scrollToEnd();
      return tempArr[tempArr.length - 1];
    },
    [replacementTextDict]
  );

  useEffect(() => {
    const tempArr = pathName.split('/').slice(1);
    upsertState({ pathArray: tempArr });
    const pageTitle = computePrettyPath(tempArr);
    if (pageTitle || tempArr.length === 1) {
      dispatch(updatePageTitle(pageTitle));
    }
    scrollToEnd();
  }, [pathName, replacementTextDictTS, dispatch, computePrettyPath, sidebar.isExpanded]);

  return (
    <div
      id={'breadcrumbs'}
      style={{ top: `${window.innerHeight - dashboardH}px` }}
      className={
        'text-teal-1 px-4 py-3 bg-white overflow-x-auto right-0 ' +
        `${isContentOverflowing ? 'border-b' : ''} ` +
        'font-bold uppercase text-sm z-10 min-h-[calc(2.5rem+1px)]'
      }
    >
      <div className={'items-top gap-1 flex pr-4 w-fit'}>
        {state.mergedPath.map((path, ix) => {
          return (
            <span key={ix}>
              <span className={'flex gap-1 items-top'}>
                {ix > 0 && (
                  <ArrowForwardIos
                    className={'dissolve-left-right opacity-0'}
                    style={{ animationDelay: `${ix * 25}ms` }}
                  />
                )}
                <Link
                  to={path[1]}
                  style={{ animationDelay: `${ix * 50}ms` }}
                  // dangerouslySetInnerHTML={{ __html: path[0] }}
                  className={`opacity-0 dissolve-left-right inline mt-[-0.2rem] whitespace-nowrap flex-nowrap ${ix + 1 === state.mergedPath.length ? 'text-teal-ppt-1' : ''}`}
                >
                  {path[0]}
                </Link>
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
