import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import {
  addBreadcrumbTextReplacement,
} from '../../store/appSlice';
import { Outlet } from 'react-router-dom';
import {PageRoutes} from '../../enums/enums';

export default function OverviewOutlet() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      addBreadcrumbTextReplacement([
        PageRoutes.USERS_OVERVIEW_OUTLET,
        'Accounts',
      ])
    );
  }, [dispatch]);

  return <Outlet />;
}
