import React from 'react';

import Header from '../components/dashboardHeader/Header';
import LogoutElement from '../components/dashboardHeader/LogoutElement';
import Sidebar from '../components/dashboardSidebar/Sidebar';
import SidebarContents from '../components/dashboardSidebar/SidebarContents';
import { useAppSelector } from '../hooks/hooks';
import DashboardOutlet from './DashboardOutlet';

export default function Dashboard() {
  const { headerDimensions } = useAppSelector((state) => state.dashboard);
  return (
    <>
      <Header mobileNavItems={<SidebarContents sidebarIsExpanded={true} />} headerFloatRightItems={<LogoutElement />} />
      <div className={'flex flex-col'} style={{ marginTop: headerDimensions.h }}>
        <Sidebar />
        <DashboardOutlet />
      </div>
    </>
  );
}
