import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { addBreadcrumbTextReplacement } from '../../store/appSlice';
import PageFooter from './PageFooter';

interface pageProps {
  children: React.ReactNode;
  title?: string;
  dictKey?: string;
}

export default function Page(props: pageProps) {
  const { localeDict } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const title = props.title ?? localeDict?.[props.dictKey ?? ''] ?? `-- ${props.dictKey} --`;

  useEffect(() => {
    if (props.title ?? props.dictKey) {
      dispatch(addBreadcrumbTextReplacement([location.pathname.split('/').pop(), title]));
    }
  }, [props.title]);

  // return (<div className={'xd-page flex flex-col w-full min-h-[calc(100vh-3.5rem-1px)]'}>
  return (
    <div className={'xd-page flex flex-col w-full min-h-[calc(100vh-6rem-2px)] overflow-hidden'}>
      {/* div className={'h-11'}/> */}
      {props.children}
      <PageFooter />
    </div>
  );
}
