import React from 'react';
import {type KeycloakTokenParsed} from 'keycloak-js';

const splitCamelCase = (s: string) => {
  return s.split(/([A-Z][a-z]+)/).filter(function (e) {
    return e;
  });
};

const debounce = (fn: (v: any) => any, delay: number) => {
  let timeout: number | NodeJS.Timeout = -1;
  return (...args: any[]) => {
    if (timeout !== -1) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(fn, delay, ...args);
  };
};

const colorCycler = (int: number) => {
  const colors = [
    '#229399',
    '#ff8945',
    '#a1803c',
    '#94b0b2',
    '#a63b00',
    '#00c9dc',
    '#229399',
    '#00825c',
    '#324b4c',
    '#002d34',
  ];
  return colors[int % colors.length];
};

const replaceTechnicalTerms = (str: string | undefined) => {
  if (!str) return undefined;
  const dict: Record<string, string> = {
    market_capitalization: 'Market Capitalization',
    mcap: 'Market Capitalization',
    shares_outstanding: 'Shares Outstanding',
    dividend_yield: 'Dividend Yield',
    dividend_amount_per_share: 'Dividend Amount per share',
    pct_turnover: 'Percentage Turnover',
    cash_quote: 'Cash Quote',
    prc: 'Price',
    ebitda: 'EBITDA',
    annualized_returns: 'Annualized Returns',
    annualized_volatility: 'Annualized Volatility',
    last_share_price: 'Last Share Price',
    annualized_correlation: 'Annualized Correlation',
    Unknown: '',
    allocation: 'Allocation',
    structure: 'Structure',
    backtest: 'Backtest',
    style: 'Style',
    weight: 'Weight',
    nexdos: 'neXDos',
    sp: 'S&P',
    equity: 'Equity',
    levered: 'Levered',
    delta: 'Delta',
    delta_overlay: 'Delta Overlay',
    plain: 'Plain',
    market_cap: 'Market Capitalization',
    sector_factor: 'Sector Factor',
    directory: 'Directory',
  };
  return dict[str] ?? str;
};

function abbreviateNumber(n: number | string) {
  if (typeof n === 'string') return n;
  // Adapted from https://stackoverflow.com/questions/10599933/...
  // ...convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
  if (n < 1e3) return n.toFixed(2);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
}

function scrollToTop() {
  window.scrollTo({ top: 0 });
}

function addWbrTagsAfterUnderscore(text: string) {
  const arr = text.split('_');
  return (
    <>
      {arr.map((text, id) => {
        if (id === arr.length - 1) {
          return <>{text}</>;
        } else {
          return <>{text}_<wbr/></>;
        }
      })}
    </>
  );
}

function compareUnorderedList(a: string[], b: string[]) {
  if (a.length !== b.length) return false;
  return a.sort().toString() === b.sort().toString();
}

function userHasRole(tokenParsed: KeycloakTokenParsed | undefined, role: 'nexdos-app-admin') {
  return tokenParsed?.realm_access?.roles.find((e) => e === role);
}

export {
  splitCamelCase,
  debounce,
  colorCycler,
  replaceTechnicalTerms,
  abbreviateNumber,
  scrollToTop,
  compareUnorderedList,
  addWbrTagsAfterUnderscore,
  userHasRole,
};
