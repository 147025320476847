import React from 'react';

import { useAppSelector } from '../hooks/hooks';
import './CLocalizedText.css';

interface Props {
  dictKey: string;
  inline?: boolean;
}

/**
 * To return as string, use <CLocalizedText .../> as unknonwn as string.
 * @param props
 * @constructor
 */
export default function CLocalizedText(props: Props) {
  const { localeDict } = useAppSelector((state) => state.dashboard);
  const classNames = ['localized-text'];
  if (props.inline) {
    classNames.push('inline');
  }

  return (
    <span
      className={classNames.join(' ')}
      dangerouslySetInnerHTML={{ __html: localeDict?.[props.dictKey] ?? `-- ${props.dictKey} --` }}
    />
  );
}
