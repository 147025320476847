import React from 'react';
import CustomAccordion from './components/CustomAccordion';
import GenericPlot, { type GenericPlotProps } from './components/GenericPlot';

interface reportPlotAccordionProps extends GenericPlotProps {}

export default function ReportPlotAccordion(props: reportPlotAccordionProps) {
  return (
    <CustomAccordion
      header={<h2>{props.modalTitle}</h2>}
      expanded={!!props.data && ((props.data[0]?.x?.length ?? 0) > 0)}
    >
      <GenericPlot {...props} excludeHeader />
    </CustomAccordion>
  );
}
