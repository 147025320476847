import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';

import XDButton from './buttons/XDButton';

interface INavigationItem {
  url: string;
  label: string | JSX.Element;
}

interface Props {
  previous?: INavigationItem;
  next?: INavigationItem;
}

export default function CSectionNavigator(props: Props) {
  return (
    <div className={'flex gap-8 -my-2'}>
      {props.previous && (
        <Link to={props.previous.url}>
          <XDButton className={'-ml-2'}>
            <div className={'flex gap-2 items-center normal-case text-left'}>
              <ArrowBack />
              <div>
                <p className={'text-sm uppercase'}>{props.previous.label}</p>
              </div>
            </div>
          </XDButton>
        </Link>
      )}
      <div className={'flex-grow'} />
      {props.next && (
        <Link to={props.next.url}>
          <XDButton className={'-ml-2'}>
            <div className={'flex gap-2 items-center normal-case text-left'}>
              <div>
                <p className={'text-sm uppercase'}>{props.next.label}</p>
              </div>
              <ArrowForward />
            </div>
          </XDButton>
        </Link>
      )}
    </div>
  );
}
