import React, { useEffect, useState } from 'react';
import { debounce } from '../utils/utils';

interface props {
  children: JSX.Element | Array<JSX.Element | undefined | ''>;
  description?: string | JSX.Element;
  minWidth?: number;
}

export default function PageModuleFormRow(props: props) {
  const minWidth = props.minWidth ?? 500;
  const [windowW, setWindowW] = useState<number>(window.innerWidth);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowW(window.innerWidth);
    };
    window.addEventListener('resize', debounce(updateWindowWidth, 0));
    return () => {
      window.removeEventListener('resize', debounce(updateWindowWidth, 0));
    };
  }, []);

  return (
    <div className={'w-full flex flex-col-reverse lg:flex-row lg:gap-x-4 gap-y-1'} style={{maxWidth: windowW > 640 ? `${2 * minWidth}px` : 'auto'}}>
      <div style={{ minWidth: windowW > 640 ? `${minWidth}px` : 'auto', width: windowW > 640 ? `${minWidth}px` : 'auto' }}>
        {props.children}
      </div>
      {
        props.description &&
        <div className={'opacity-60 text-sm md:pb-3 md:pt-2 flex-grow'}>
          <p>
            {props.description}
          </p>
        </div>
      }
    </div>
  );
}