import { Alert, Snackbar } from '@mui/material';
import React from 'react';

import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { updateSnackbar } from '../store/appSlice';

interface appSnackbarProps {
  isVisible: boolean;
}

export default function GlobalSnackbar({ isVisible }: appSnackbarProps) {
  const dispatch = useAppDispatch();
  const { message, autoHideDuration, severity } = useAppSelector((state) => state.dashboard.snackbar);

  const handleClose = () => {
    dispatch(
      updateSnackbar({
        // Resets the snackbar
        message: undefined,
        autoHideDuration: 5000
        // severity: 'info'
      })
    );
  };

  return (
    <>
      {isVisible && (
        <Snackbar
          open={isVisible}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={autoHideDuration}
          sx={{
            margin: '0.5rem',
            '& .MuiAlert-root': {
              boxShadow:
                'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.04) 0px 6px 10px 0px, rgba(0, 0, 0, 0.03) 0px 1px 18px 0px'
            }
          }}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
