import React from 'react';
import { TextField, type TextFieldProps } from '@mui/material';

/***
 * Textfield wrapper to enforce the 'filled' variant.
 * @param props one or more properties of TextFieldProps
 * @constructor
 */
export default function CustomTextField(props: TextFieldProps) {
  const pr = {fullWidth: true, ...props, variant: 'filled', value: props.value ?? ''};
  return (<TextField {...pr} variant={'filled'} />);
}