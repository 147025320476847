import React from 'react';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import PageModuleTitle from '../../../components/layout/PageModuleTitle';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import ActionLinkButton from '../../../components/dashboardContent/ActionLinkButton';
import { PageRoutes } from '../../../enums/enums';
import { LinkIcon, PlusIcon } from '@heroicons/react/24/outline';

export default function FactsheetManagementIndex() {
  return (<Page>
    <PageModule>
      <PageModuleTitle>Factsheet Link Management</PageModuleTitle>
      <XDResponsiveGridWrapper>
        <ActionLinkButton label={'New Link'} to={PageRoutes.PUBLISHING_FS_NEW_LINK}>
          <PlusIcon/>
        </ActionLinkButton>
        <ActionLinkButton label={'All links'} to={PageRoutes.PUBLISHING_FS_OVERVIEW_OUTLET}>
          <LinkIcon/>
        </ActionLinkButton>
      </XDResponsiveGridWrapper>
    </PageModule>
  </Page>);
}