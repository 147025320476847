export default class HGeneric {
  static getRemInPx() {
    return parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  static formatNumber(value: any) {
    if (typeof value !== 'number' ?? isNaN(parseFloat(value))) return value;
    return value.toLocaleString();
  }

  static isNumeric(value: any) {
    try {
      if (typeof value === 'number') return true;
      if (typeof value === 'string' && value.includes('%')) return true;
      if (value.match(/\d\d-\d\d-\d\d\d\d/g)) return true;
      if (value.match(/\d\d\d\d-\d\d-\d\d/g)) return true;
      else return !isNaN(parseFloat(value)) && !isNaN(value);
    } catch (e) {
      return false;
    }
  }
}
