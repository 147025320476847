import React from 'react';

interface props {
  children: JSX.Element | Array<JSX.Element | boolean | undefined>;
  order?: number;
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
}

export default function XDEntryCard(props: props) {
  const buttonClass = [
    'flex flex-col gap-2 p-4 rounded-xl border relative text-gray-700 items-start overflow-hidden h-full font-normal'
  ];

  if (props.disabled) {
    buttonClass.push('opacity-50 cursor-not-allowed');
  } else {
    buttonClass.push('text-teal-1 hover:text-teal hover:bg-teal-1 hover:bg-opacity-5 cursor-pointer');
  }

  return (
    // <XDFloatIn delayInMs={(props.order ?? 0) * 20} className={'h-full'}>
    <div className={buttonClass.join(' ')} onClick={props.onClick}>
      {props.children}
    </div>
    // </XDFloatIn>
  );
}
