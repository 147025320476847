import Keycloak, {type KeycloakConfig} from 'keycloak-js';

const kcConfigString = localStorage.getItem('kc-config');
let kcConfig: KeycloakConfig = {clientId: '', realm: '', url: ''};

try {
  kcConfig = JSON.parse(kcConfigString ?? '{}');
} catch (e) {
  // Do something
}

const keycloakObject = new Keycloak(kcConfig);

export default keycloakObject;
