import type INexdosJWT from '../models/IAuthContext';

/**
 * Class to convert INexdosJWT to IAuthContext
 */
export default class NexdosJWT {
  static toIAuthContext(jwt: INexdosJWT) {
    return {
      id: jwt.id,
      email: jwt.email,
      roles: jwt.roles,
      firstName: jwt.firstName,
      lastName: jwt.lastName,
      isAdmin: jwt.roles.includes('admin')
    };
  }
}
