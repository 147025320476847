import React from 'react';

import { addWbrTagsAfterUnderscore } from '../../utils/utils';

export interface ActionButtonProps {
  label: string | JSX.Element;
  sublabel?: string | JSX.Element;
  onClick?: () => void;
  children?: JSX.Element;
  href?: string;
  disabled?: boolean;
  standardCase?: boolean;
  flipOrder?: boolean;
  type?: 'gallery' | 'list';
}

export default function ActionButton({
  label,
  onClick,
  children,
  href,
  disabled,
  sublabel,
  standardCase,
  flipOrder,
  type
}: ActionButtonProps) {
  const maxLabelLength = 30;
  const labelIsString = typeof label === 'string';

  const hoverActions = !disabled
    ? 'hover:text-teal-1 hover:bg-teal-1 hover:bg-opacity-5 cursor-pointer'
    : 'opacity-50 cursor-not-allowed';

  const formattedLabel = (
    <>
      {labelIsString && !label.indexOf('_') && label}
      {labelIsString &&
        label.indexOf('_') &&
        addWbrTagsAfterUnderscore(type === 'gallery' ? label.slice(0, maxLabelLength) : label)}
      {typeof label !== 'string' && label}
    </>
  );

  const gallerySublabel = <p className={`${standardCase ? 'text-sm ' : 'text-sm font-[600] uppercase'}`}>{sublabel}</p>;

  const clickHandler = () => {
    if (onClick != null && !disabled) {
      onClick();
    } else if (href) {
      window.open(href, '_blank');
    }
  };

  const galleryButton = (
    <div
      className={`border rounded-xl relative text-gray-700 aspect-[3] md:aspect-[5/3] overflow-hidden ${hoverActions}`}
      onClick={clickHandler}
    >
      <div className={'font-normal absolute left-4 overflow-hidden flex flex-col gap-0 mr-14 md:mr-4 mb-0 bottom-4'}>
        {sublabel && !flipOrder && gallerySublabel}
        <div className={'text-2xl text-left'}>
          {formattedLabel}
          {labelIsString && label.length > maxLabelLength && '...'}
        </div>
        {sublabel && flipOrder && gallerySublabel}
      </div>
      <div className={'absolute top-4 right-4 w-8 h-8'}>{children}</div>
    </div>
  );

  const listButton = (
    <div
      title={labelIsString ? label : undefined}
      className={`border rounded-xl relative text-gray-700 p-4 flex gap-4 items-start overflow-hidden ${hoverActions}`}
      onClick={clickHandler}
    >
      <span className={'w-6 h-6 flex-shrink-0'}>{children}</span>
      <div className={'flex flex-col gap-0'}>
        {sublabel && <p className={`${standardCase ? '' : 'uppercase'}`}>{sublabel}</p>}
        <div className={'font-normal text-2xl'}>{formattedLabel}</div>
      </div>
    </div>
  );

  return (
    <>
      {!type && galleryButton}
      {type === 'gallery' && galleryButton}
      {type === 'list' && listButton}
    </>
  );
}
