import BaseApiService from './baseApiService';
import type {
  BacktestListModel,
  BacktestPerformanceTableResponse,
  BacktestReportNewModel,
  BacktestTreeResponse,
  BenchmarkList
} from '../models/BacktestModel';
import { type BacktestTreeItem } from '../models/backtest';

export default class BacktestServices extends BaseApiService {
  static async getList() {
    return (await super.get<BacktestListModel>('backtest/list')).getData();
  }

  static async getReport(backtestId: string, benchmark?: string) {
    const params: Record<string, string> = {};
    params.backtestId = backtestId;
    if (benchmark) params.benchmark = benchmark;
    params.path = location.pathname.split('/').slice(3).join('/');
    return (
      await super.get<BacktestReportNewModel>('backtest/new', params)
    ).getData();
  }

  static async getBenchmarkList() {
    return (await super.get<BenchmarkList>('backtest/benchmarkList')).getData();
  }

  static async getPerformance(
    backtestId: string,
    benchmark: string,
    start: string,
    end: string
  ) {
    return (
      await super.get<BacktestPerformanceTableResponse>(
        'backtest/performance',
        {
          backtestId,
          benchmark,
          start,
          end
        }
      )
    ).getData();
  }

  /**
   * Queries the server for the items on the backtest directory tree, given a hierarchical path
   * @param pathArray
   */
  static async getTreeItems(pathArray: string[]) {
    // Do some basic string formatting
    let pathString = pathArray.join('/');
    if (pathString.length > 0) {
      pathString = '/' + pathString; // Add a backslash if there is a hierarchy in the path
    }
    return (
      await super.get<BacktestTreeResponse>(`backtest/path/levelItems${pathString}`)
    ).getData();
  }

  static async getTree() {
    return (
      await super.get<BacktestTreeItem>('backtest/path/tree')
    ).getData();
  }

  static async getAllBacktestPaths() {
    return (
      await super.get<Record<'data', string[]>>('backtest/path/items')
    ).getData();
  }

  static async getAssignment(userId: string) {
    return (
      await super.get<Record<'data', string[]>>(`backtest/assignment/${userId}`)
    ).getData();
  }

  static async putAssignment(userId: string, selectedNodes: string[]) {
    return (
      await super.put<Record<'data', string[]>>(`backtest/assignment/${userId}`, {data: selectedNodes})
    ).getData();
  }
}
