import React from 'react';

import { type StatefulApiResponseContainerModel } from '../../models/IRequestState';
import CStatefulContainer from '../CStatefulContainer';
import WidthWrapper from './WidthWrapper';
import './pageModule.css';

interface pageModuleProps extends Partial<StatefulApiResponseContainerModel> {
  children: React.ReactNode;
  background?: React.ReactNode;
  className?: string;
  gap?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

/**
 * @deprecated
 * @param props
 * @constructor
 */
export default function PageModule(props: pageModuleProps) {
  const wrapperClassNames = ['w-full'];
  const classNames = ['flex flex-col'];

  if (
    props.className &&
    (props.className.includes('py-') || props.className.includes('pt-') || props.className.includes('pb-'))
  ) {
    wrapperClassNames.push('');
  } else {
    wrapperClassNames.push('py-8');
  }

  switch (props.gap) {
    case 'xs':
      classNames.push('gap-2');
      break;
    case 'xl':
      classNames.push('gap-12');
      break;
    case 'sm':
      classNames.push('gap-4');
      break;
    case 'lg':
      classNames.push('gap-8');
      break;
    case 'md':
    default:
      classNames.push('gap-6');
      break;
  }

  if (props.className) {
    classNames.push(props.className);
  }

  const childrenElement = <div className={classNames.join(' ')}>{props.children}</div>;

  const widthWrapperElement = (
    <WidthWrapper className={wrapperClassNames.join(' ')}>
      {props.apiRequestState && (
        <CStatefulContainer
          {...props}
          apiRequestState={{
            isLoading: false,
            isError: props.apiRequestState?.isError ?? false,
            strError: props.apiRequestState.strError
          }}
        >
          {childrenElement}
        </CStatefulContainer>
      )}
      {!props.apiRequestState && childrenElement}
    </WidthWrapper>
  );

  const elementWithBackground = (
    <div className={'xd-page-module relative'}>
      {props.background && props.background}
      {widthWrapperElement}
    </div>
  );

  const elementWithoutBackground = <div>{widthWrapperElement}</div>;

  const moduleElements = (
    <>
      {!props.background && elementWithoutBackground}
      {props.background && elementWithBackground}
    </>
  );

  return (
    <>
      {props.apiRequestState && (
        <CStatefulContainer
          apiRequestState={{
            isLoading: props.apiRequestState?.isLoading ?? false,
            isError: false,
            strError: props.apiRequestState.strError
          }}
        >
          {moduleElements}
        </CStatefulContainer>
      )}
      {!props.apiRequestState && (props.showContent === undefined || props.showContent) && moduleElements}
    </>
  );
}
