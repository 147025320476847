import React, { useEffect, useState } from 'react';

import { updateState } from '../helpers/helper';
import { type PerformanceTable } from '../models/BacktestModel';
import { type IRequestState } from '../models/IRequestState';
import BacktestServices from '../services/backtestServices';
import PerformanceDatePicker from './PerformanceDatePicker';

interface BacktestPerformanceProps {
  backtestId: string;
  strategy: string;
  benchmark: string;
  perfTable: PerformanceTable;
}

interface State {
  aPerfTable?: PerformanceTable;
  iPerfTable?: PerformanceTable;
  displayMessage?: string;
  reqState: IRequestState;
}

export default function BacktestPerformance({ backtestId, strategy, benchmark, perfTable }: BacktestPerformanceProps) {
  const [state, setState] = useState<State>({
    aPerfTable: perfTable,
    iPerfTable: perfTable,
    reqState: { isLoading: false, isError: false }
  });
  const { displayMessage, aPerfTable, reqState, iPerfTable } = state;

  const refreshHandler = async (start: string, end: string) => {
    updateState<State>({ reqState: { isLoading: true, isError: reqState.isError } }, state, setState);
    try {
      const res = await BacktestServices.getPerformance(backtestId, benchmark, start, end);
      if (res.message) {
        updateState(
          { aPerfTable: undefined, displayMessage: res.message, reqState: { isLoading: false, isError: false } },
          state,
          setState
        );
      } else {
        updateState(
          {
            aPerfTable: res.performanceTable,
            displayMessage: undefined,
            reqState: { isLoading: false, isError: false }
          },
          state,
          setState
        );
      }
    } catch (e) {
      updateState<State>({ reqState: { isLoading: false, isError: true } }, state, setState);
    }
  };

  useEffect(() => {
    updateState({ iPerfTable: perfTable, aPerfTable: perfTable }, state, setState);
  }, [perfTable]);

  return (
    <PerformanceDatePicker
      iPerfTable={iPerfTable ?? {}}
      perfTable={aPerfTable ?? {}}
      strategy={strategy}
      benchmark={benchmark}
      refreshCallback={refreshHandler}
      displayMsg={displayMessage}
      state={reqState}
    />
  );
}
