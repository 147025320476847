import TabContext from '@mui/lab/TabContext';
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import ReportPlotAccordion from '../../ReportPlotAccordion';
import BacktestPerformance from '../../components/BacktestPerformance';
import ResourceUnauthorizedOrDoesNotExist from '../../components/alerts/ResourceUnauthorizedOrDoesNotExist';
import BacktestStatisticsModule from '../../components/backtest/BacktestStatModule';
import RowStatsItem from '../../components/data/RowStatsItem';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import TabPanelXd from '../../components/layout/TabPanelXd';
import TabsXd from '../../components/layout/TabsXd';
import { updateState } from '../../helpers/helper';
import { useAppDispatch } from '../../hooks/hooks';
import { BacktestBenchmarkEnum, type BacktestReportNewModel, BacktestStrategyEnum } from '../../models/BacktestModel';
import type { IRequestState } from '../../models/IRequestState';
import BacktestServices from '../../services/backtestServices';
import { addBreadcrumbTextReplacement } from '../../store/appSlice';
import { addWbrTagsAfterUnderscore, replaceTechnicalTerms } from '../../utils/utils';

interface State {
  strategy: BacktestStrategyEnum;
  benchmark: string;
  backtest?: BacktestReportNewModel;
  benchmarkList: string[];
  reqState: IRequestState;
  benchmarkReqState: IRequestState;
  selectedConfig: string;
}

export default function BacktestEntry() {
  const dispatch = useAppDispatch();
  const { backtestId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<State>({
    strategy: BacktestStrategyEnum.NEXDOS,
    benchmark: searchParams.get('benchmark') ?? BacktestBenchmarkEnum.DEFAULT_BENCHMARK,
    benchmarkList: [BacktestBenchmarkEnum.DEFAULT_BENCHMARK],
    reqState: {
      isLoading: true,
      isError: false
    },
    benchmarkReqState: {
      isLoading: true,
      isError: false
    },
    selectedConfig: 'hygieneFactors'
  });

  const {
    backtest,
    // benchmarkList,
    benchmark,
    strategy,
    reqState,
    benchmarkReqState,
    selectedConfig
  } = state;

  const backtestConfigGroups = [
    { id: 'hygieneFactors', label: 'Hygiene Factors' },
    { id: 'portfolioSorts', label: 'Portfolio Sorts' },
    { id: 'backtestInput', label: 'Backtest Input' },
    { id: 'backtestStats', label: 'Backtest Stats' }
  ];

  const benchmarkDropdownHandler = async (e: SelectChangeEvent) => {
    updateState({ benchmark: e.target.value }, state, setState);
    await fetchBacktestResult(e.target.value);
  };

  const appendPathBreadcrumbs = () => {
    const pathArray = location.pathname?.split('/').slice(3);
    // Add the path name to the breadcrumb
    pathArray.map((path) => {
      dispatch(addBreadcrumbTextReplacement([path, replaceTechnicalTerms(path)]));
      return path;
    });
  };

  const fetchBacktestResult = async (overrideBenchmark?: string) => {
    if (!backtestId) return;

    const targetBenchmark = overrideBenchmark ?? benchmark;

    updateState<State>({ reqState: { isLoading: true, isError: reqState.isError } }, state, setState);

    try {
      const res = await BacktestServices.getReport(backtestId, targetBenchmark);
      updateState<State>({ backtest: res, reqState: { isLoading: false, isError: false } }, state, setState);
      const params = { ...Object.fromEntries([...searchParams]) };
      params.benchmark = targetBenchmark;
      if (targetBenchmark === BacktestBenchmarkEnum.DEFAULT_BENCHMARK) {
        delete params.benchmark;
      }
      setSearchParams(params);
      appendPathBreadcrumbs();
    } catch (e) {
      updateState<State>({ reqState: { isLoading: false, isError: true } }, state, setState);
    }
  };

  useEffect(() => {
    (async () => {
      updateState<State>(
        {
          benchmarkReqState: {
            isLoading: true,
            isError: benchmarkReqState.isError
          }
        },
        state,
        setState
      );
      try {
        const res = await BacktestServices.getBenchmarkList();
        updateState<State>(
          {
            benchmarkList: res.benchmarks,
            benchmarkReqState: { isLoading: false, isError: false }
          },
          state,
          setState
        );
      } catch (e) {
        updateState<State>({ benchmarkReqState: { isLoading: false, isError: true } }, state, setState);
      }
      await fetchBacktestResult();
    })();
  }, []);

  return (
    <Page title={backtest ? backtestId : ''}>
      <PageModule
        apiRequestState={backtest ? { isLoading: false, isError: false } : reqState}
        errorMessage={<ResourceUnauthorizedOrDoesNotExist />}
      >
        {backtest && (
          <div className={'flex flex-col sm:flex-row gap-x-4 gap-y-6 flex-wrap'}>
            <div className={'flex-grow flex-shrink-0'}>
              <PageModuleTitle subtitle={'Backtest'} highlight>
                {addWbrTagsAfterUnderscore(backtestId ?? '') ?? ''}
              </PageModuleTitle>
            </div>
            <FormControl variant={'filled'}>
              <InputLabel id={'demo-simple-select-label'}>Benchmark</InputLabel>
              <Select
                label={'Benchmark'}
                labelId={'demo-simple-select-label'}
                variant={'filled'}
                value={benchmark ?? 'S&P 500 Value'}
                onChange={benchmarkDropdownHandler}
              >
                {state.benchmarkList.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </PageModule>
      {backtest && (
        <PageModule className={'-mt-8'}>
          <TabContext value={selectedConfig}>
            <TabsXd
              value={selectedConfig}
              onChange={(e, value) => {
                updateState<State>({ selectedConfig: value }, state, setState);
              }}
            >
              {backtestConfigGroups.map((e) => (
                <Tab key={e.id} value={e.id} label={e.label}></Tab>
              ))}
            </TabsXd>
            {backtestConfigGroups.map((e) => {
              const lvl1Dict =
                backtest[e.id as 'hygieneFactors' | 'portfolioSorts' | 'backtestInput' | 'backtestStats'];
              return (
                <TabPanelXd key={e.id} value={e.id}>
                  <div className={'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 flex-wrap'}>
                    {e.id !== 'backtestStats' &&
                      Object.keys(lvl1Dict ?? {}).map((lvl1Key) => {
                        const lvl2Dict = lvl1Dict ? lvl1Dict[lvl1Key] : { key: 'value' };
                        return <RowStatsItem key={lvl1Key} heading={lvl1Key} dict={lvl2Dict} />;
                      })}
                    {e.id === 'backtestStats' && lvl1Dict && (
                      <>
                        <div>
                          <BacktestStatisticsModule lvl1Key={'configuration'} lvl2Dict={lvl1Dict.configuration} />
                        </div>
                        <div className={'flex flex-col gap-8'}>
                          <BacktestStatisticsModule lvl1Key={'universe'} lvl2Dict={lvl1Dict.universe} />
                          <BacktestStatisticsModule lvl1Key={'orders'} lvl2Dict={lvl1Dict.orders} />
                        </div>
                        <div className={'flex flex-col gap-8'}>
                          <BacktestStatisticsModule lvl1Key={'dividend'} lvl2Dict={lvl1Dict.universe} />
                          <BacktestStatisticsModule lvl1Key={'delistings'} lvl2Dict={lvl1Dict.delistings} />
                          <BacktestStatisticsModule lvl1Key={'portfolio'} lvl2Dict={lvl1Dict.portfolio} />
                        </div>
                      </>
                    )}
                  </div>
                </TabPanelXd>
              );
            })}
          </TabContext>
        </PageModule>
      )}
      {backtest && (
        <PageModule background={<div className={'bg-slate-50'} />}>
          <BacktestPerformance
            backtestId={backtestId ?? ''}
            strategy={strategy}
            benchmark={benchmark}
            perfTable={backtest.performanceTable ?? {}}
          />
        </PageModule>
      )}
      {backtest && (
        <PageModule apiRequestState={reqState}>
          <>
            <ReportPlotAccordion
              modalTitle={'Cumulative Performance'}
              data={backtest.cumulativePerformance?.data}
              layout={backtest.cumulativePerformance?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Portfolio Values Over Time'}
              data={backtest.portfolioValues?.data}
              layout={backtest.portfolioValues?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Underwater'}
              data={backtest.underwater?.data}
              layout={backtest.underwater?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Turnover'}
              data={backtest.turnover?.data}
              layout={backtest.turnover?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Annual Returns'}
              data={backtest.returnPerAnnum?.data}
              layout={backtest.returnPerAnnum?.layout}
            />
            <ReportPlotAccordion
              excludeHeader
              modalTitle={'Cash Quote'}
              data={backtest.cashQuote?.data}
              layout={backtest.cashQuote?.layout}
            />
            <ReportPlotAccordion
              excludeHeader
              modalTitle={'Leverage'}
              data={backtest.leverage?.data}
              layout={backtest.leverage?.layout}
            />
            <ReportPlotAccordion
              excludeHeader
              modalTitle={'Market Capitalization'}
              data={backtest.marketCapitalization?.data}
              layout={backtest.marketCapitalization?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Portfolio Weight Distribution'}
              data={backtest.portfolioWeightDistribution?.data}
              layout={backtest.portfolioWeightDistribution?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Number of Portfolio Companies'}
              data={backtest.numberOfPortfolioCompanies?.data}
              layout={backtest.numberOfPortfolioCompanies?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Factor Exposure'}
              data={backtest.factorExposure?.data}
              layout={backtest.factorExposure?.layout}
            />
            <ReportPlotAccordion
              modalTitle={'Sector Exposure'}
              data={backtest.sectorExposure?.data}
              layout={backtest.sectorExposure?.layout}
            />
            <ReportPlotAccordion modalTitle={'Gamma'} data={backtest.gamma?.data} layout={backtest.gamma?.layout} />
            <ReportPlotAccordion modalTitle={'Delta'} data={backtest.delta?.data} layout={backtest.delta?.layout} />
            <ReportPlotAccordion modalTitle={'Vega'} data={backtest.vega?.data} layout={backtest.vega?.layout} />
            <ReportPlotAccordion
              modalTitle={'Return Distribution'}
              data={backtest.returnDistribution?.data}
              layout={backtest.returnDistribution?.layout}
            />
          </>
        </PageModule>
      )}
    </Page>
  );
}
