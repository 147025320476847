import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import type { Layout, PlotData } from 'plotly.js';
import { useAppSelector } from '../hooks/hooks';
import GenericModal from './GenericModal';
import { debounce } from '../utils/utils';
import { Alert } from '@mui/material';

export interface plotWrapperProps {
  data?: Array<Partial<PlotData>>;
  layout?: Partial<Layout>;
  modalTitle?: string;
  dim?: { w: number; h: number; }; // What does this do? Is this a dimension override?
  onUpdateDim?: (dim: { w: number; h: number; }) => void; // What does this do?
}

interface State {
  parentW: number;
  effInnerH: number;
  showModal?: boolean;
}

export default function PlotWrapper({data, layout, modalTitle, dim, onUpdateDim}: plotWrapperProps) {
  const isSidebarExpanded = useAppSelector(
    (state) => state.dashboard.sidebar.isExpanded
  );
  const plotDOM = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<State>({parentW: 0, effInnerH: 0});
  const {parentW, effInnerH, showModal} = state;
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };

  const toggleModal = () => {
    updateState({showModal: !showModal});
  };

  const plotLayout: Partial<Layout> = {
    ...layout,
    autosize: true,
    font: {
      family: 'PT Sans',
    },
    margin: {
      pad: 0,
      t: 25,
      // r: 30,
      l: layout?.yaxis?.title ? 100 : 70,
    },
    paper_bgcolor: 'rgba(255, 255, 255, 0)',
    plot_bgcolor: 'rgba(0, 0, 0, 0)',
    showlegend: true,
    legend: {
      orientation: 'h',
    },
    modebar: {
      orientation: 'h',
    },
    template: {
      layout: {
        hoverlabel: {
          bgcolor: '#ffffff',
          bordercolor: 'rgba(0, 0, 0, 0.7)',
        },
        font: {
          size: 14,
        }
      },
    },
  };

  const recomputeLayout = () => {
    setState((state) => {
      const stateCopy = {...state};
      if (plotDOM.current != null) {
        const newParentW = plotDOM.current.parentElement?.getBoundingClientRect().width;
        if (newParentW) {
          if (Math.abs(window.innerHeight - stateCopy.effInnerH) > 80 || // If the height difference exceeds 80px
            Math.abs(newParentW - stateCopy.parentW) > 0) { // Or if the width changes
            // Then we should re-size the graph by triggering change in parentW value
            stateCopy.effInnerH = window.innerHeight;
            stateCopy.parentW = newParentW;
          }
        }
      }
      return stateCopy;
    });
  };

  useEffect(() => {
    if (dim) return;
    recomputeLayout();
    window.addEventListener('resize', debounce(recomputeLayout, 100));
    return () => {
      window.removeEventListener('resize', debounce(recomputeLayout, 100));
    };
  }, []);

  useEffect(() => {
    if (dim) return;
    recomputeLayout();
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (dim) updateState({parentW: dim.w, effInnerH: dim.h});
  }, [dim]);

  useEffect(() => {
    if (onUpdateDim) onUpdateDim({w: parentW, h: effInnerH});
  }, [parentW, effInnerH]);

  return (
    <>
      <div ref={plotDOM}>
        {(parentW > 0) && (
          <>
            {(!data || data.length === 0 || data?.[0]?.x?.length === 0) && <Alert severity={'info'}>Data is not available</Alert>}
            {data && (data?.[0]?.x?.length ?? 0) > 0 &&
              <>
                <div onClick={toggleModal} className={'cursor-pointer overflow-hidden border rounded-lg'}>
                  <Plot
                    data={data}
                    config={{
                      displaylogo: false,
                      staticPlot: true}}
                    layout={{
                      ...plotLayout,
                      width: parentW,
                      height: Math.max(3 / 5 * parentW, effInnerH /
                        window.innerWidth * 0.7 * parentW)
                    }}/>
              </div>
                  <p className={'text-xs text-center opacity-50 mt-1'}>Click to interact with the graph</p>
                {
                  showModal &&
                  <GenericModal onClose={toggleModal} noPad>
                    <div className={'mt-6'}>
                      <h3 className={'text-center px-12 mb-0'}>{modalTitle}</h3>
                      <div className={'mr-5'}>
                        <Plot
                          data={data}
                          config={{
                            displaylogo: false,
                            displayModeBar: true,
                            modeBarButtonsToRemove: [ 'toImage', 'resetScale2d', 'lasso2d' ]
                          }}
                          layout={{
                            ...plotLayout,
                            width: Math.min(window.innerWidth - 45, 1000),
                            height: effInnerH * 0.69
                          }}/>
                      </div>
                    </div>
                  </GenericModal>
                }
              </>
            }
          </>
        )}
      </div>
    </>
  );
}
