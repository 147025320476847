import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { type CSSProperties } from 'react';

import HGeneric from '../helpers/HGeneric';
import { type ISortingMeta } from '../models/ISortingMeta';
import CLocalizedText from './CLocalizedText';

interface Props {
  data?: Record<string, Record<string, any>>;
  showIndex?: boolean;
  style?: Partial<{
    minWidth: number | 'auto';
    width: '100%';
  }>;
  colSpanArr?: number[];
  sorting?: ISortingMeta;
  onSortChange?: (sortCol: ISortingMeta['sortCol'], ascending: ISortingMeta['ascending']) => void;
  cellHeight?: CSSProperties['height'];
}

export default function CPandasTable(props: Props) {
  if (!props.data) return <></>;

  const entries = Object.values(Object.values(props.data ?? {})[0] ?? {});

  if (entries.length === 0)
    return (
      <p className={'text-center py-4 rounded-lg'}>
        <CLocalizedText dictKey={'errorEmptyList'} />
      </p>
    );

  const minWidth = 970;
  const columnCount = Object.keys(props.data).length + (props.showIndex ? 1 : 0);
  const colRatioArr = [...(props.colSpanArr ?? [])?.slice(0, columnCount)];

  const lenDelta = columnCount - colRatioArr.length;

  for (let x = 0; x < lenDelta; x++) {
    colRatioArr.push(props.colSpanArr?.[0] ?? 1);
  }

  const spanArrSum = colRatioArr.reduce((total, num) => {
    return total + num;
  });

  const colPixelArr = colRatioArr.map((e) => {
    return props.colSpanArr ? `${Math.round((e / spanArrSum) * minWidth)}px` : 'auto';
  });

  return (
    <div style={{ width: props.style?.width }} className={'rounded-lg overflow-hidden'}>
      <Table style={{ minWidth, ...props.style }} size={'small'}>
        <TableHead>
          <TableRow style={{ borderBottom: '2px solid' }}>
            {props.showIndex && <TableCell style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} />}
            {Object.keys(props.data ?? {}).map((column, ix) => (
              <TableCell
                key={column}
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  padding: '0.5rem',
                  width: colPixelArr[ix + (props.showIndex ? 1 : 0)],
                  maxWidth: colPixelArr[ix + (props.showIndex ? 1 : 0)],
                  cursor: props.sorting ? 'pointer' : 'auto',
                  wordBreak: 'break-word',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  hyphens: 'auto'
                }}
                onClick={() => {
                  if (props.onSortChange && props.sorting) {
                    props.onSortChange(
                      ix,
                      props.sorting.sortCol === ix
                        ? props.sorting.ascending === 'ascending'
                          ? 'descending'
                          : 'ascending'
                        : props.sorting.ascending
                    );
                  }
                }}
              >
                <div className={'font-bold text-center'}>
                  {column}{' '}
                  <span className={'text-teal-1 text-base'}>
                    <SortIcon
                      activeColNumber={props.sorting?.sortCol}
                      ascending={props.sorting?.ascending}
                      colNumber={ix}
                    />
                  </span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((row, rowNumber) => (
            <TableRow key={rowNumber}>
              {props.showIndex && (
                <TableCell style={{ borderBottom: 'none', padding: '0.5rem', width: colPixelArr[0] }}>
                  <div>{Object.keys(Object.values(props.data ?? {})[0] ?? {})[rowNumber]}</div>
                </TableCell>
              )}
              {Object.keys(props.data ?? {}).map((column, colNumber) => {
                const cellValue = Object.values(props.data?.[column] ?? {})[rowNumber];
                return (
                  <TableCell
                    key={`${rowNumber}_${colNumber}_${row}`}
                    style={{
                      textAlign: HGeneric.isNumeric(cellValue)
                        ? 'right'
                        : typeof cellValue === 'string'
                          ? 'left'
                          : 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0) !important',
                      borderBottom: 'none',
                      padding: '0.5rem',
                      width: colPixelArr[colNumber + (props.showIndex ? 1 : 0)],
                      height: props.cellHeight ?? 'auto'
                    }}
                  >
                    <span>{HGeneric.formatNumber(cellValue)}</span>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function SortIcon(props: {
  colNumber: number;
  activeColNumber?: ISortingMeta['sortCol'];
  ascending?: ISortingMeta['ascending'];
}) {
  if (props.colNumber !== props.activeColNumber) return <></>;
  if (props.activeColNumber === undefined) return <></>;
  if (props.ascending === 'ascending') return <ArrowUpward />;
  else return <ArrowDownward />;
}
