import React from 'react';

import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { updateLocale } from '../store/appSlice';
import XDButton from './buttons/XDButton';

export default function I18nButton() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const boldPixelShift = '-0px';

  return (
    <div className={'flex justify-end items-center text-sm'}>
      <XDButton
        onClick={() => {
          dispatch(updateLocale({ locale: 'de-DE' }));
        }}
        style={{ fontWeight: locale === 'de-DE' ? 700 : 400, marginBottom: locale === 'de-DE' ? boldPixelShift : 0 }}
      >
        DE
      </XDButton>
      <span>|</span>
      <XDButton
        onClick={() => {
          dispatch(updateLocale({ locale: 'en-DE' }));
        }}
        style={{ fontWeight: locale === 'en-DE' ? 700 : 400, marginBottom: locale === 'en-DE' ? boldPixelShift : 0 }}
      >
        EN
      </XDButton>
    </div>
  );
}
