import React from 'react';

interface Props {
  children: string | JSX.Element;
  rightDecoration?: JSX.Element;
}

export default function CDropdownItemHeader(props: Props) {
  return (
    <div className={'flex gap-2 items-center'}>
      <p className={'p-2 font-bold uppercase text-xs'}>{props.children}</p>
      {props.rightDecoration}
    </div>
  );
}
