import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CSectionNavigator from '../../components/CSectionNavigator';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { type IRequestState } from '../../models/IRequestState';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  deltaDistribution?: Partial<{
    data: PlotlyResponse;
    state: IRequestState;
  }>;
  changeMatrix?: Partial<{
    data: PlotlyResponse;
    state: IRequestState;
  }>;
  deltaPlots?: Partial<{
    data: { industries: PlotlyResponse; factors: PlotlyResponse };
    state: IRequestState;
  }>;
}

export default function PDelta() {
  const { factsheetId } = useParams();
  const [state, setState, getState] = useStateExtended<State>({
    deltaDistribution: {
      state: { isLoading: true, isError: false }
    },
    changeMatrix: {
      state: { isLoading: true, isError: false }
    },
    deltaPlots: {
      state: { isLoading: true, isError: false }
    }
  });

  const sectionNavigator = (
    <CSectionNavigator
      previous={{
        label: <CLocalizedText dictKey={'globalFactsheetSectionSecex'} />,
        url: `../${PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE}`
      }}
      next={{
        label: <CLocalizedText dictKey={'globalFactsheetSectionEsg'} />,
        url: `../${PageRoutes.PUBLIC_FACTSHEET_ESG}`
      }}
    />
  );

  const fetchDistribution = async () => {
    updateState<State>(
      {
        deltaDistribution: {
          ...(await getState()).deltaDistribution,
          state: { isLoading: true, isError: (await getState()).deltaDistribution?.state?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getDeltaDistribution(factsheetId ?? '');
    updateState<State>(
      {
        deltaDistribution: {
          ...(await getState()).deltaDistribution,
          state: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        { deltaDistribution: { ...(await getState()).deltaDistribution, data: res.getData() } },
        state,
        setState
      );
    }
  };

  // const fetchChangeMatrix = async () => {
  //   updateState<State>(
  //     {
  //       changeMatrix: {
  //         ...(await getState()).changeMatrix,
  //         state: { isLoading: true, isError: (await getState()).deltaDistribution?.state?.isError ?? false }
  //       }
  //     },
  //     state,
  //     setState
  //   );
  //   const res = await PublicFactsheetServices.getDeltaChangeMatrix(factsheetId ?? '');
  //   updateState<State>(
  //     {
  //       changeMatrix: {
  //         ...(await getState()).changeMatrix,
  //         state: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
  //       }
  //     },
  //     state,
  //     setState
  //   );
  //   if (res.wasSuccessful()) {
  //     updateState<State>(
  //       { changeMatrix: { ...(await getState()).changeMatrix, data: res.getData() } },
  //       state,
  //       setState
  //     );
  //   }
  // };

  const fetchDeltaPlots = async () => {
    updateState<State>(
      {
        deltaPlots: {
          ...(await getState()).deltaPlots,
          state: { isLoading: true, isError: (await getState()).deltaDistribution?.state?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getDeltaPlots(factsheetId ?? '');
    updateState<State>(
      {
        deltaPlots: {
          ...(await getState()).deltaPlots,
          state: {
            isLoading: false,
            isError: res.hasError(),
            strError: res.getErrorString(),
            objError: res.getErrorObj()
          }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>({ deltaPlots: { ...(await getState()).deltaPlots, data: res.getData() } }, state, setState);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDistribution();
      await fetchDeltaPlots();
      // await fetchChangeMatrix();
    })();
  }, []);

  return (
    <Page dictKey={'globalFactsheetSectionDelta'}>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'fsriskP1H1'} />
        </h2>
        <CLocalizedText dictKey={'fsriskP1Desc'} />
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsriskP2H1'} />
          </h3>
          <CLocalizedText dictKey={'fsriskP2Desc'} />
        </CPageModule>
        <CPageModulePlot
          data={state.deltaDistribution?.data?.data}
          layout={state.deltaDistribution?.data?.layout}
          apiRequestState={state.deltaDistribution?.state}
          showWhen={!!state.deltaDistribution?.data}
        />
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsriskP4H1'} />
          </h3>
          <CLocalizedText dictKey={'fsriskP4P1Desc'} />
        </CPageModule>
        <CPageModulePlot
          data={state.deltaPlots?.data?.industries.data}
          layout={state.deltaPlots?.data?.industries.layout}
          apiRequestState={state.deltaPlots?.state}
          showWhen={!!state.deltaPlots?.data}
        />
        <CPageModule submodule>
          <CLocalizedText dictKey={'fsriskP4P2Desc'} />
        </CPageModule>
        <CPageModulePlot
          data={state.deltaPlots?.data?.factors.data}
          layout={state.deltaPlots?.data?.factors.layout}
          apiRequestState={state.deltaPlots?.state}
          showWhen={!!state.deltaPlots?.data}
        />
      </CPageModule>
      {/* <CPageModule fullWidth> */}
      {/*   <CPageModule submodule> */}
      {/*     <h3> */}
      {/*       <CLocalizedText dictKey={'fsriskP3H1'} /> */}
      {/*     </h3> */}
      {/*     <CLocalizedText dictKey={'fsriskP3Desc'} /> */}
      {/*   </CPageModule> */}
      {/*   <CPageModulePlot */}
      {/*     data={state.changeMatrix?.data?.data} */}
      {/*     layout={state.changeMatrix?.data?.layout} */}
      {/*     apiRequestState={state.changeMatrix?.state} */}
      {/*     showWhen={!!state.changeMatrix?.data} */}
      {/*   /> */}
      {/* </CPageModule> */}
      <CPageModule>{sectionNavigator}</CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}
