import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import AutoBreadcrumbs from '../components/dashboardContent/AutoBreadcrumbs';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { updateDashboardDim } from '../store/appSlice';

export default function DashboardOutlet() {
  const dispatch = useAppDispatch();
  const dashboardRef = useRef<HTMLDivElement>(null);
  const { sidebar, headerDimensions } = useAppSelector((state) => state.dashboard);

  const handleDashboardSizeChange = () => {
    const clientRect = dashboardRef.current?.getBoundingClientRect();
    if (!clientRect) return;
    dispatch(updateDashboardDim({ w: clientRect.width ?? 0, left: clientRect.left }));
  };

  useEffect(() => {
    handleDashboardSizeChange();
  }, [sidebar.dim.w, headerDimensions.w]);

  return (
    <div className={'flex-grow relative overflow-hidden w-full bg-teal-2'} style={{ paddingLeft: sidebar.dim.w }}>
      <AutoBreadcrumbs isContentOverflowing={false} />
      <div className={'bg-white relative'} ref={dashboardRef}>
        <Outlet />
      </div>
    </div>
  );
}
