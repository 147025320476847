import React, { type CSSProperties } from 'react';

interface tagButtonProps {
  children: string | JSX.Element;
  color?: 'blue' | 'green' | 'grey' | 'none';
  size?: 'inherit' | 'xs';
  marginTopInRem?: number;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLStyleElement>) => void;
  style?: CSSProperties;
}

export default function TagButton(props: tagButtonProps) {
  const classNames = ['uppercase tracking-wider'];
  switch (props.color) {
    case 'green':
      classNames.push('bg-green-200 text-green-700');
      break;
    case 'blue':
      classNames.push('bg-blue-200 text-blue-700');
      break;
    case 'none':
      break;
    case 'grey':
    default:
      classNames.push('bg-slate-200 text-slate-700');
      break;
  }

  switch (props.size) {
    case 'xs':
    default:
      classNames.push('text-xs px-2 py-0.5 rounded-md');
      break;
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <strong
      className={classNames.join(' ')}
      style={{ marginTop: `${props.marginTopInRem}rem`, ...props.style }}
      onClick={props.onClick}
    >
      {props.children}
    </strong>
  );
}
