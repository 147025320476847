import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';
import keycloakObject from './keycloakObject';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  typography: {
    fontFamily: [
      'PT Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'inherit'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: '#ffffff',
          borderWidth: '0',
          borderRadius: '0.375rem',
          '&.Mui-disabled': {
            background: '#ffffff'
          },
          '&.Mui-focused': {
            background: '#ffffff'
          },
          '&:hover': {
            background: '#ffffff'
          },
          '& .MuiSelect-select:focus': {
            background: '#ffffff'
          },
          '&::before': {
            border: 'none !important'
          },
          '&::after': {
            borderColor: 'inherit',
            borderWidth: '1px',
            borderRadius: '0.375rem',
            width: '100%',
            height: '100%',
            transform: 'none',
            transition: 'all ease 300ms'
          },
          '&.Mui-focused::after': {
            borderColor: '#229399'
          },
          '&.Mui-error::after': {
            borderColor: '#d32f2f',
            borderWidth: '2px'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            borderColor: 'rgb(229, 231, 235)',
            cursor: 'not-allowed'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        },
        invisible: {
          backgroundColor: 'rgba(255, 255, 255, 0)'
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.04) 0px 6px 10px 0px, rgba(0, 0, 0, 0.03) 0px 1px 18px 0px'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginBottom: '0px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiPaper-elevation0': {
            boxShadow: 'none'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#5fc4ca',
      main: '#229399',
      dark: '#00656b',
      contrastText: '#fff'
    },
    secondary: {
      light: '#929292',
      main: '#777777',
      dark: '#535353',
      contrastText: '#000'
    }
  }
});

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <ReactKeycloakProvider authClient={keycloakObject}>
            <App />
          </ReactKeycloakProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
