import { type BacktestTreeItem } from '../../models/backtest';
import { Checkbox } from '@mui/material';
import { DocumentChartBarIcon, FolderIcon, FolderOpenIcon } from '@heroicons/react/24/outline';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import DataNotAvailable from '../alerts/DataNotAvailable';
import React from 'react';
import { addWbrTagsAfterUnderscore } from '../../utils/utils';
import { PageRoutes } from '../../enums/enums';

interface backtestTreeButtonProps {
  path: string[];
  treeChildren: BacktestTreeItem | string[] | undefined;
  selectedNodes?: string[];
  expandedNodes: string[];
  allBacktests?: string[];
  onClick: (path: string) => void;
  onExpanded: (path: string) => void;
  selectable?: boolean;
}

export default function BacktestTreeButton({
                                             path,
                                             treeChildren,
                                             selectedNodes,
                                             expandedNodes,
                                             allBacktests,
                                             onClick,
                                             onExpanded,
                                             selectable
                                           }: backtestTreeButtonProps) {
  const label = [...path].pop();
  const pathString = path.join('/');
  const isDirectoryParent = treeChildren?.constructor.name === 'Object';
  const isBacktestParent = treeChildren?.constructor.name === 'Array';
  const isBacktest = !treeChildren;
  const isSelected = label ? selectedNodes?.includes(pathString) : false;
  const isExpanded = label ? expandedNodes.includes(pathString) : false;

  const backtestCount = allBacktests?.filter((e) => e.startsWith(`${pathString}/`)).length;
  const selectedDescendantsCount = selectedNodes?.filter((e) => e.startsWith(`${pathString}/`)).length;

  const classNames = ['flex p-2 gap-2 items-start cursor-pointer bg-teal-1 bg-opacity-0 hover:bg-opacity-5 rounded-lg font-normal text-slate-700'];
  const textClassNames = ['-mt-0.5'];

  const checkboxNode = (<Checkbox checked={isSelected} className={'text-xl'} sx={{ padding: 0 }} />);

  const labelNode = (<>
    {label && !treeChildren && <>
      <DocumentChartBarIcon className={'w-5 h-5 flex-shrink-0'} />
      <p className={textClassNames.join(' ')}>{addWbrTagsAfterUnderscore(label)}</p>
    </>}
    {label && treeChildren && <>
      {isExpanded && <ExpandMore />}
      {!isExpanded && <ChevronRight />}
      {isExpanded && <FolderOpenIcon className={'w-5 h-5'} />}
      {!isExpanded && <FolderIcon className={'w-5 h-5'} />}
      <p className={textClassNames.join(' ')}>{label}
        {
          allBacktests && (backtestCount ?? 0) > 0 &&
          <span className={'text-sm'}> ({selectedDescendantsCount}/{backtestCount})</span>
        }
        {
          !allBacktests && (selectedDescendantsCount ?? 0) > 0 &&
          <span className={'text-sm'}> ({selectedDescendantsCount})</span>
        }
      </p>
    </>}
  </>);

  const clickHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (!label) {
      return;
    }
    if (isBacktest) {
      onClick(pathString);
    } else {
      onExpanded(pathString);
    }
  };

  return (<div>
    <a className={classNames.join(' ')} href={`/${PageRoutes.DASHBOARD_OUTLET}/${PageRoutes.BACKTEST_OUTLET}/${path.join('/')}`} onClick={clickHandler}>
      {!treeChildren && selectable && checkboxNode}
      {labelNode}
    </a>
    {isExpanded && <div className={'pl-4 flex flex-col'}>
      {treeChildren?.length === 0 && <div className={'py-2'}>
        <DataNotAvailable />
      </div>}
      {isDirectoryParent && Object.keys(treeChildren).map((e) => <BacktestTreeButton key={e} path={[...path, e]}
                                                                                     allBacktests={allBacktests}
                                                                                     treeChildren={(treeChildren as BacktestTreeItem)[e]}
                                                                                     selectedNodes={selectedNodes}
                                                                                     onClick={onClick}
                                                                                     expandedNodes={expandedNodes}
                                                                                     onExpanded={onExpanded}
                                                                                     selectable={selectable} />)}
      {isBacktestParent && (treeChildren as string[]).map((e) => <BacktestTreeButton key={e} path={[...path, e]}
                                                                                     allBacktests={allBacktests}
                                                                                     treeChildren={undefined}
                                                                                     selectedNodes={selectedNodes}
                                                                                     onClick={onClick}
                                                                                     expandedNodes={expandedNodes}
                                                                                     onExpanded={onExpanded}
                                                                                     selectable={selectable} />)}
    </div>}
  </div>);
}