import { ArrowForward } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';

import PaddedButton from '../general/PaddedButton';
import Page from '../layout/Page';
import PageModule from '../layout/PageModule';

export default function NotFoundModule() {
  return (
    <Page title={'Not Found'}>
      <PageModule className={'flex flex-col justify-center h-[70vh]'}>
        <div className={'flex flex-col gap-4 text-center'}>
          <div>
            <h2>The requested resource could not be found</h2>
          </div>
          <div>
            <Link to={'/'} replace>
              <PaddedButton label={'Go to homepage'} icon={<ArrowForward />} trailingIcon />
            </Link>
          </div>
        </div>
      </PageModule>
    </Page>
  );
}
