import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import UserManagementService from '../../services/userManagementService';
import PaddedButton from '../../components/general/PaddedButton';
import { Delete } from '@mui/icons-material';
import CustomTextField from '../../components/CustomTextField';
import { addBreadcrumbTextReplacement } from '../../store/appSlice';
import { PageRoutes } from '../../enums/enums';
import { updateUserObject } from '../../store/userSlice';
import Page from '../../components/layout/Page';
import PageModule from '../../components/layout/PageModule';
import PageModuleTitle from '../../components/layout/PageModuleTitle';
import PageModuleForm from '../../components/layout/PageModuleForm';

interface State {
  input: string;
}

export default function UserSecurityAndPrivacy() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({input: ''});
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };
  const {input} = state;
  const {email, firstName, lastName} = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(addBreadcrumbTextReplacement([PageRoutes.USERS_DELETE, 'Security & Privacy']));
  }, [dispatch]);
  
  return (
    <Page>
      {
        email &&
          <PageModule>
            <PageModuleTitle>Security & Privacy</PageModuleTitle>
            <PageModuleForm title={'Delete account'}>
              <p>
                Are you sure you want to delete the user account associated
                with <strong>{`${firstName} ${lastName}`}</strong>?
                Confirm your intent by entering the user&rsquo;s email address (<strong>{email}</strong>),
                which will enable the delete button.
              </p>
            </PageModuleForm>
            <PageModuleForm>
              <CustomTextField label={'Email'} value={input} onChange={(e) => {updateState({input: e.target.value});}}/>
              <PaddedButton
                growOnMobile
                disabled={email !== input}
                label={'Delete'}
                icon={<Delete/>}
                color={'warning'}
                onClick={async () => {
                  await UserManagementService.deleteAccount(email);
                  dispatch(updateUserObject({}));
                }}
              />
            </PageModuleForm>
          </PageModule>
      }
    </Page>
  );
}
