import React from 'react';

interface SidebarHeaderProps {
  children: string;
  show: boolean;
}

export default function SidebarHeader({
  children,
  show,
}: SidebarHeaderProps) {
  return (
    <>
      {
        show &&
        <li className={'font-bold mx-3 uppercase overflow-hidden ' +
          'whitespace-no-wrap flex items-center list-none text-sm'}>
            <p className={'m-0'}>{children}</p>
        </li>
      }
    </>
  );
}
