import React, { type CSSProperties, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { updateState } from '../helpers/helper';
import CPageModule from './CPageModule';
import CTabButton from './CTabButton';
import CTabButtonWrapper from './CTabButtonWrapper';
import { type ITabItem } from './CTabItem';

interface Props {
  children: React.ReactNode[];
  searchParamKey?: string;
  submodule?: boolean;
  style?: CSSProperties;
  centerTabButtons?: true;
  onChange?: (selectedTab: string) => void;
}

interface State {
  selectedTab?: string;
}

export default function CTab(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<State>({});

  const childrenArray: Array<ITabItem | undefined> = props.children
    .map((e) => {
      const label = ((e as unknown as any).props as unknown as ITabItem).label;
      const text = ((e as unknown as any).props as unknown as ITabItem).text ?? label;
      if (!label) return undefined;
      return {
        label,
        text,
        children: e
      };
    })
    .filter((e) => e);

  const handleSelectTab = (selectedTab?: string) => {
    if (!selectedTab) return;
    updateState<State>({ selectedTab }, state, setState);
    if (props.onChange) props.onChange(selectedTab);
    if (props.searchParamKey) {
      if (selectedTab === childrenArray[0]?.label) {
        searchParams.delete(props.searchParamKey);
      } else {
        searchParams.set(props.searchParamKey, selectedTab);
      }
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (!childrenArray[0]) return;
    const tabFromParam = searchParams.get(props.searchParamKey ?? '');
    updateState<State>(
      {
        selectedTab: tabFromParam ?? childrenArray[0]?.label
      },
      state,
      setState
    );
  }, []);

  return (
    <div className={'flex flex-col relative'} style={{ ...props.style }}>
      <CPageModule
        style={{
          paddingTop: 0,
          // paddingBottom: 0,
          paddingLeft: props.submodule ? 'auto' : 0,
          paddingRight: props.submodule ? 'auto' : 0
        }}
      >
        <CTabButtonWrapper style={{ justifyContent: props.centerTabButtons ? 'center' : 'start' }}>
          {childrenArray.map((e) => (
            <CTabButton
              key={e?.label}
              label={e?.label}
              text={e?.text}
              onClick={handleSelectTab}
              isSelected={e?.label === state.selectedTab}
            />
          ))}
        </CTabButtonWrapper>
      </CPageModule>
      {childrenArray.filter((e) => e?.label === state.selectedTab).map((e) => e?.children)}
    </div>
  );
}
