import React from 'react';
import { CircularProgress } from '@mui/material';

interface LoadingMaskProps {
  message?: string;
}

export default function LoadWheel({ message }: LoadingMaskProps) {
  return (
    <div className={'flex flex-col gap-4 justify-center items-center my-6'}>
      <CircularProgress color={'primary'} />
      {message ? <p>{message}</p> : null}
    </div>
  );
}
