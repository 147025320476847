import React from 'react';
import { Tabs } from '@mui/material';

interface tabsXdProps {
  children: React.ReactNode;
  value: any;
  onChange: (e: React.SyntheticEvent, value: any) => void;
}

export default function TabsXd({children, value, onChange}: tabsXdProps) {
  return (
    <Tabs
      className={'border-b'}
      variant='scrollable'
      value={value}
      allowScrollButtonsMobile
      onChange={onChange}
    >
      {children}
    </Tabs>
  );
}