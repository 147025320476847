import React from 'react';
import PageModule from './layout/PageModule';

interface disclaimerBoxProps {
  htmlString: string | undefined | null;
}

export default function DisclaimerBox({ htmlString }: disclaimerBoxProps) {
  return (
    <>
      {htmlString && (
        <PageModule
          background={<div className={'bg-teal'} />}
          className={'text-white text-sm'}
        >
          <div
            className={'flex flex-col gap-2'}
            dangerouslySetInnerHTML={{ __html: htmlString ?? '' }}
          />
        </PageModule>
      )}
    </>
  );
}
