import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  type SelectChangeEvent,
} from '@mui/material';

interface SelectDropdownProps {
  label: string;
  value?: string;
  children?: JSX.Element[];
  onChange?: (e: SelectChangeEvent) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  className?: string;
  growOnMobile?: boolean;
  minWidth?: boolean;
}

export default function CustomSelectDropdown({
  label,
  onChange,
  value,
  children,
  className,
  disabled,
  fullWidth,
  error,
  helperText,
  defaultValue,
  growOnMobile,
  minWidth,
}: SelectDropdownProps) {
  return (
    <div className={`${growOnMobile ? 'w-full ' + (minWidth ? 'sm:w-[200px] ' : '') : ''}-mb-0.5`}>
      <FormControl fullWidth={fullWidth ?? growOnMobile} error={error} className={className}>
        <InputLabel variant={'filled'}>{label}</InputLabel>
        <Select
          label={label}
          variant={'filled'}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            onChange?.(e);
          }}
          defaultValue={defaultValue}
        >
          {/* <MenuItem value={undefined} className={'opacity-50 pointer-events-none'}>Please select an option</MenuItem> */}
          {children}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
