import React from 'react';

import { useAppSelector } from '../../hooks/hooks';

interface ActionButtonWrapperProps {
  children?: React.ReactNode;
  className?: string;
  widerBox?: boolean;
  borderlessContent?: boolean;
  type?: 'gallery' | 'list' | 'doubleList';
}

/**
 * @param children
 * @param className
 * @param widerBox
 * @param borderlessContent
 * @param type
 * @param toolbar
 * @constructor
 */
export default function XDResponsiveGridWrapper({
  children,
  className,
  widerBox,
  borderlessContent,
  type
}: ActionButtonWrapperProps) {
  const sidebarIsExpanded = useAppSelector((state) => state.dashboard.sidebar.isExpanded);

  const stdExp = 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
  const stdCol = 'md:grid-cols-3 lg:grid-cols-4';
  const widerExp = 'lg:grid-cols-2 xl:grid-cols-3';
  const widerCol = 'md:grid-cols-2 lg:grid-cols-3';

  const galleryWrapper = (
    <div
      className={
        `overflow-hidden grid grid-cols-1 align-start auto-cols-fr ${className ?? ''} ` +
        `${borderlessContent ? 'gap-2 -mx-2' : 'gap-4'} ` +
        `${!widerBox && sidebarIsExpanded && stdExp} ` +
        `${!widerBox && !sidebarIsExpanded && stdCol} ` +
        `${widerBox && sidebarIsExpanded && widerExp} ` +
        `${widerBox && !sidebarIsExpanded && widerCol} `
      }
    >
      {children}
    </div>
  );

  const listWrapper = <div className={'overflow-hidden flex flex-col gap-4'}>{children}</div>;

  const doubleListWrapper = <div className={'overflow-hidden grid grid-cols-1 sm:grid-cols-2 gap-4'}>{children}</div>;

  return (
    <>
      {!type && galleryWrapper}
      {type === 'gallery' && galleryWrapper}
      {type === 'list' && listWrapper}
      {type === 'doubleList' && doubleListWrapper}
    </>
  );
}
