import React from 'react';

import NotFoundModule from '../components/PageGates/NotFoundModule';
import Header from '../components/dashboardHeader/Header';
import LogoutElement from '../components/dashboardHeader/LogoutElement';
import { useAppSelector } from '../hooks/hooks';

export default function PageNotFound() {
  const { headerDimensions } = useAppSelector((state) => state.dashboard);
  return (
    <>
      <Header headerFloatRightItems={<LogoutElement />} />
      <div style={{ marginTop: headerDimensions.h }}>
        <div className={'h-[calc(2.5rem+1px)]'}></div>
        <NotFoundModule />
      </div>
    </>
  );
}
