import React, { type CSSProperties } from 'react';

import './CPageModule.css';
import CStatefulContainer, { type StatefulContainerProps } from './CStatefulContainer';

export interface IPageModule extends Pick<StatefulContainerProps, 'showContent' | 'apiRequestState' | 'errorMessage'> {
  children?: React.ReactNode;
  background?: React.ReactNode;
  style?: Partial<{
    padding: 0 | 'auto';
    paddingTop: 0 | 'auto';
    paddingBottom: 0 | 'auto';
    paddingRight: 0 | 'auto';
    paddingLeft: 0 | 'auto';
    height: 0 | 'auto';
  }>;
  childrenWrapperStyle?: CSSProperties;
  className?: string;
  divRef?: React.RefObject<HTMLDivElement>;
  fullWidth?: boolean;
  submodule?: boolean;
}

export default function CPageModule(props: IPageModule) {
  const wrapperClass: string[] = [];

  const paddingStyle: CSSProperties = {};

  if (props.fullWidth) {
    paddingStyle.paddingLeft = 0;
    paddingStyle.paddingRight = 0;
    paddingStyle.maxWidth = 'auto';
  }

  if (props.submodule) {
    paddingStyle.paddingBottom = 0;
    paddingStyle.paddingTop = 0;
  }

  const moduleStyle: CSSProperties = { maxWidth: '1100px', margin: 'auto', ...paddingStyle, ...props.style };

  if (props.background) wrapperClass.push('c-page-module');

  return (
    <div className={wrapperClass.join(' ')} style={{ position: 'relative' }} ref={props.divRef}>
      {props.background}
      <div className={'relative p-4 sm:p-8 sm:px-16'} style={moduleStyle}>
        <CStatefulContainer
          apiRequestState={props.apiRequestState}
          showContent={props.showContent}
          containerPosition={'static'}
          errorMessage={
            props.errorMessage
            // props.pad === false ? (
            //   <div className={'p-4 sm:p-8 sm:px-16'}>
            //     <Alert severity={'error'}>{props.apiRequestState?.strError}</Alert>
            //   </div>
            // ) : undefined
          }
        >
          <div className={'flex flex-col z-10'} style={{ gap: '1.5rem', ...props.childrenWrapperStyle }}>
            {props.children}
          </div>
        </CStatefulContainer>
      </div>
    </div>
  );
}
