import React from 'react';
import QRCode from 'react-qr-code';
import GenericModal from '../../components/GenericModal';

interface TwoFaQrCodeModalProps {
  open: boolean;
  setDisplayQrCode: (x: boolean) => void;
  uri?: string;
}

export default function TwoFaQrCodeModal({
  open,
  setDisplayQrCode,
  uri,
}: TwoFaQrCodeModalProps) {
  return (
    <>
      {
        open && uri &&
          <GenericModal
              onClose={() => {
                setDisplayQrCode(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <div className={'flex flex-col gap-8 items-center justify-center'}>
              <QRCode value={uri} className={'w-48 p-0'}/>
              <h3 className={'text-center'}>Scan this QR-Code with your Google Authenticator App</h3>
            </div>
          </GenericModal>
      }
    </>
  );
}
