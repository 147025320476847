export default class HDate {
  static getShortDate(date: Date | string | number) {
    let dateObject: Date;
    switch (typeof date) {
      case 'string':
      case 'number':
        dateObject = new Date(date);
        break;
      case 'object':
      default:
        dateObject = date;
        break;
    }
    return dateObject.toLocaleString(navigator.languages, { day: 'numeric', month: 'short', year: 'numeric' });
  }
}
