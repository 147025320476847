import React from 'react';

interface statefulModuleDisablerProps {
  children: JSX.Element;
  disabled: boolean;
}

export default function StatefulModuleDisabler({ children, disabled }: statefulModuleDisablerProps) {
  return (
    <div className={'relative'} style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}>
      {children}
    </div>
  );
}
