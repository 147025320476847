import { Alert, type AlertColor } from '@mui/material';
import React, { type CSSProperties } from 'react';

import { type IRequestState } from '../models/IRequestState';
import CPageModule from './CPageModule';
import LoadWheel from './general/LoadWheel';

export interface StatefulContainerProps {
  apiRequestState?: IRequestState;
  errorMessage?: React.ReactNode;
  severity?: AlertColor;
  showContent?: boolean;
  containerPosition?: CSSProperties['position'];
  divRef?: React.RefObject<HTMLDivElement>;
  submodule?: true;
}

interface statefulContentContainerProps extends StatefulContainerProps {
  children?: React.ReactNode;
}

/**
 * A placeholder module container to display error message if the API request
 * fails. In the future, should also include a load wheel. The container fills
 * the entire width of the parent container.
 * @param children
 * @param pageState
 * @param errorMessage
 * @constructor
 */
export default function CStatefulContainer({
  children,
  apiRequestState,
  errorMessage,
  severity,
  showContent,
  containerPosition,
  divRef,
  submodule
}: statefulContentContainerProps) {
  const errorWarning = (
    <>
      {typeof errorMessage !== 'object' && (
        <Alert
          severity={severity ?? 'error'}
          className={'transition-opacity ease-in-out duration-1000'}
          style={{ opacity: apiRequestState?.isLoading ? 0.5 : 1 }}
        >
          {errorMessage ?? apiRequestState?.strError ?? 'An unknown error occurred'}
        </Alert>
      )}
      {typeof errorMessage === 'object' && errorMessage}
    </>
  );
  return (
    <div
      className={'w-full'}
      style={{ position: containerPosition ?? 'relative', minHeight: apiRequestState ? '3rem' : 'auto' }}
      ref={divRef}
    >
      {apiRequestState?.isError && !submodule && errorWarning}
      {apiRequestState?.isError && submodule && <CPageModule submodule>{errorWarning}</CPageModule>}
      {!apiRequestState?.isError && (showContent === undefined || showContent) && (
        <div
          style={{ opacity: apiRequestState?.isLoading ? 0.5 : 1 }}
          className={'transition-opacity ease-in-out duration-1000'}
        >
          {children}
        </div>
      )}
      {apiRequestState?.isLoading && (
        <div className={'absolute h-full top-0 left-0 w-full flex flex-col cursor-wait z-10'}>
          <div className={'flex flex-col h-full max-h-[50vh] justify-center'}>
            {/* TODO: Dynamic load wheel positioning based on scroll */}
            <LoadWheel />
          </div>
        </div>
      )}
    </div>
  );
}
