import React from 'react';

interface props {
  children: JSX.Element | Array<JSX.Element | undefined | '' | boolean>;
}

export default function PageModuleFormGroup(props: props) {
  return (
    <div className={'flex flex-col gap-4'}>
      {props.children}
    </div>
  );
}