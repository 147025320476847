import { type SortingLogic } from '../enums/sortingLogic';
import { type PaginatedBackendResponse } from '../models/GhostPostModel';
import { type PlotlyResponse } from '../models/PlotlyModel';
import { type TLocales } from '../models/TLocales';
import { type TPandasTable } from '../models/TPandasTable';
import { type MSingleStockCardSummary } from '../models/singleStock/MSingleStockCardSummary';
import type {
  CompanyInfo,
  CompanyListResponse,
  CompanyPerformanceResponse,
  StockReportModel
} from '../models/singleStock/SingleStockOverview';
import { type MSingleStockSummarizedInfo } from '../models/singleStock/details/MSingleStockSummarizedInfo';
import { type MSingleStockMcapShrout } from '../models/singleStock/mcapShrout/MSingleStockMcapShrout';
import ApiService from './apiService';

export default class SvSingleStockServices extends ApiService {
  static async listSingleStocks(page?: number, search?: string, sortBy?: SortingLogic) {
    return await super.get<PaginatedBackendResponse<MSingleStockCardSummary[]>>('singleStock', 'list', {
      page: page ?? 0,
      search: search ?? '',
      sortBy
    });
  }

  static async getSingleStockReportInfo(identifier?: string) {
    return await super.get<MSingleStockSummarizedInfo>('singleStock', `info/${identifier}`);
  }

  static async getSingleStockReportMarketCap(identifier?: string) {
    return await super.get<MSingleStockMcapShrout>('singleStock', `chart/marketCap/${identifier}`);
  }

  static async getSingleStockReportPerformanceDaily(param?: {
    companyId: string;
    startDate?: string;
    endDate?: string;
    locale: TLocales;
  }) {
    return await super.get<{ table: TPandasTable; chart: PlotlyResponse }>('singleStock', 'performance/daily', param);
  }

  static async getSingleStockReportPerformanceMonthly(param?: { companyId: string; year?: string; locale: TLocales }) {
    return await super.get<TPandasTable>('singleStock', 'performance/monthly', param);
  }

  static async getSingleStockReportPerformanceYearly(param?: { companyId: string; year?: string; locale: TLocales }) {
    return await super.get<TPandasTable>('singleStock', 'performance/yearly', param);
  }

  /**
   * @deprecated
   * @param page
   * @param search
   */
  static async getAllStocks(page: number, search: string) {
    return (await super.get<CompanyListResponse>('stock', 'listAll', { page, search })).getData();
  }

  /**
   * @deprecated
   * @param cid
   * @param tid
   */
  static async getStockReport(cid: number, tid: number) {
    return (await super.get<StockReportModel>('singleStock', undefined, { cid, tid })).getData();
  }

  /**
   * @deprecated
   * @param companyId
   * @param tradingId
   * @param start
   * @param end
   */
  static async getStockPerformance(companyId: string, tradingId: string, start: string, end: string) {
    return (
      await super.get<CompanyPerformanceResponse>('stock', 'performance', {
        companyId,
        tradingId,
        start,
        end
      })
    ).getData();
  }

  /**
   * @deprecated
   * @param identifier
   */
  static async getCompanyInfo(identifier: string) {
    return (await super.get<CompanyInfo>('singleStock', `info/${identifier}`)).getData();
  }
}
