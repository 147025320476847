import React from 'react';

import XDButton from './buttons/XDButton';

interface Props {
  isSelected: boolean | undefined;
  label: string | undefined;
  text?: string | JSX.Element;
  labelKey?: string;
  onClick: (selectedTab: string | undefined) => void;
}

export default function CTabButton(props: Props) {
  switch (props.isSelected) {
    case true:
      return (
        <XDButton
          className={'bg-teal text-white font-bold px-5 normal-case hover:bg-opacity-80 hover:text-white'}
          style={{
            borderRadius: '8rem'
          }}
          onClick={() => {
            props.onClick(props.labelKey ?? props.label);
          }}
        >
          {props.text}
        </XDButton>
      );
    default:
      return (
        <XDButton
          className={'bg-gray-100 text-gray-500 font-bold px-5 normal-case'}
          style={{
            borderRadius: '8rem'
          }}
          onClick={() => {
            props.onClick(props.labelKey ?? props.label);
          }}
        >
          {props.text}
        </XDButton>
      );
  }
}
