import { createSlice } from '@reduxjs/toolkit';
import { type Token } from '../models/LoginResponseModel';
import {parseJwt} from '../helpers/helper';
import type INexdosJWT from '../models/IAuthContext';
import NexdosJWT from '../domains/NexdosJWT';

/**
 * Derived attributes for the authentication context, to ensure only
 * serializable objects are stored in the Store.
 */
interface IAuthContext extends INexdosJWT {
  isAdmin: boolean;
}

interface AuthenticationSliceState {
  jwt: string | undefined;
  user: IAuthContext | undefined,
  password: string;
  twoFaRequired: boolean;
  message: null | string;
}

const initialState: AuthenticationSliceState = {
  jwt: undefined,
  user: undefined,
  password: '',
  twoFaRequired: false,
  message: null,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    initJwt: (state, jwt) => {
      const stringJwt = jwt.payload as string;
      const jwtParsed = parseJwt(stringJwt);
      state.jwt = stringJwt;
      if (jwtParsed) {
        state.user = NexdosJWT.toIAuthContext({
          id: jwtParsed.id,
          email: jwtParsed.email,
          roles: jwtParsed.roles,
          firstName: jwtParsed.firstName,
          lastName: jwtParsed.lastName
        });
        localStorage.setItem('jwt', stringJwt);
      }
    },
    login: (state, action) => {
      const loginResponse = action.payload;
      if (Object.keys(loginResponse).includes('token')) {
        state.jwt = (loginResponse as Token).token;
        state.twoFaRequired = false;
      } else {
        state.twoFaRequired = true;
        state.password = action.payload.password;
      }

      state.user = NexdosJWT.toIAuthContext({
        id: action.payload.id,
        email: action.payload.email,
        roles: action.payload.role,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName
      });

      // Store JWT in Local Storage
      localStorage.setItem('jwt', state.jwt ?? '');
    },
    refreshToken: (state, action) => {
      state.jwt = action.payload;
    },
    send2Fa: (state, action) => {
      const twoFaResponse = action.payload;
      if (Object.keys(twoFaResponse).includes('token')) {
        state.jwt = (twoFaResponse as Token).token;
        state.twoFaRequired = false;
        state.message = null;
      } else {
        state.twoFaRequired = true;
        state.message = twoFaResponse.message;
      }
      state.password = '';
    },
    logout: (state) => {
      state.jwt = '';
      localStorage.removeItem('jwt');
    },
  },
});

export const { initJwt, login, refreshToken, send2Fa, logout } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
